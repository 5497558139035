import React from "react";
import DownloadButton from "./DownloadButton";
import './style.css'

const TopHeader = ({ selectedDate, setSelectedDate, scrollToSection, handleDownloadPDF, jumptoSectionOptions }) => {
    return <div className="py-3 d-flex align-items-center justify-content-between">

        <div className="d-flex align-items-center">
            <div>
                <select
                    placeholder={"Jump to Section"}
                    // value={sectionId}
                    onChange={(v) => scrollToSection(v.target.value)}
                    style={{
                        fontSize: "1rem",
                        borderColor: "#E1E8F4",
                        borderRadius: "1.125rem",
                        color: "#6B7B8E",
                        fontWeight: "600",
                        height: "2.3rem",
                        padding: "0.313rem 0.938rem",
                        // width: "14rem",
                        minWidth: "max-content",
                        backgroundColor: "white",
                        marginRight: "0.938rem",

                    }}
                    value={0}
                >
                    {jumptoSectionOptions?.map((option, index) => <option value={index}>
                        {option}
                    </option>
                    )}
                </select>


            </div>
            <div>
                <input
                    type="date"
                    className="form-control date-picker"
                    value={selectedDate}
                    style={{
                        fontSize: "1rem",
                        borderColor: "#E1E8F4",
                        borderRadius: "1.125rem",
                        color: "#6B7B8E",
                        fontWeight: "600",
                        height: "2.3rem",
                        padding: "0.313rem 0.938rem",
                        width: "14rem",
                        backgroundColor: "white",
                        marginRight: "0.938rem",

                    }}
                    placeholder={"DD/MM/YYYY"}
                    onChange={(e) => setSelectedDate(e.target.value)}
                />
            </div>
        </div>
        <div className="d-flex align-items-center">
            <div className="mr-3" style={{ fontSize: '0.75rem' }}>
                Displaying data for {(selectedDate ? selectedDate : new Date().toLocaleDateString('en-US', { timeZone: 'Pacific/Auckland',day: '2-digit', month: '2-digit', year: 'numeric' })).replace(/-/g, '/')}, 5:45 AM - 4:00 AM
            </div>
            <div className="d-flex align-items-center">
                <DownloadButton handleDownloadPDF={handleDownloadPDF} />
            </div>
        </div>
    </div>
};

export default TopHeader;
