import React, { useEffect, useState } from "react";
import { TitlePopup } from "../../../AssetTracking/Map/TitlePopup";

// url: `./assets/images/trip-icon-start.svg`
// url: `./assets/images/trip-icon.svg`
// url: `./assets/images/trip-icon-end.svg`
import "./dropdowns.css";

const TimelineItem = ({ checkpoint, selected = false, onSelect = () => { } }) => {
  return (
    <div
      style={{
        position: "relative",
      }}
      onClick={() => {
        onSelect(checkpoint)
      }}
      className="d-flex p-0">
      <div className="d-flex" >
        <div className="p-0 text-center d-flex  details-key ">
          <div className="p-0 m-0">
            <img
              style={{ height: "20px", width: "20px" }}
              src={checkpoint.url ?? "./assets/images/trip-icon.svg"}
              alt={checkpoint.checkPoint_name}
            />
          </div>
          <div style={{ position: "absolute", left: 6, top: 20 }} className=" h-50">
            <div style={{ color: "#000" }} className=" border-right">&nbsp;</div>
            <div style={{ color: "#000" }} className="">&nbsp;</div>
          </div>
        </div>
        <div className="details-key" style={{ marginBottom: '1rem', marginLeft: '0.5rem' }} >
          <strong className="details-key" style={{ marginRight: '0.625rem' }} >{checkpoint.checkPoint_time}</strong> - <strong style={{ marginLeft: '0.625rem' }} className="details-value">{checkpoint.checkPoint_name}</strong>
        </div>
      </div>
    </div >
  )
};

const Timeline = ({ data = [], onSelectCheckPoint = () => { } }) => {

  const [selectedCheckpoint, setSelectedCheckpoint] = useState({})
  const [timeline, setTimeline] = useState([])

  useEffect(() => {
    setTimeline(data)
  }, [data])

  useEffect(() => {
    if (selectedCheckpoint?.coordinates?.latitude) {
      onSelectCheckPoint(
        {
          ...selectedCheckpoint,
          title: selectedCheckpoint?.checkPoint_name,
          type: "checkpoint",
          url: "./assets/images/trip-icon-selected.svg",
          coordinates: selectedCheckpoint?.coordinates,
          popup: () => TitlePopup(selectedCheckpoint?.coordinates, { data: { title: selectedCheckpoint?.checkPoint_name } })
        }
      )

    }

  }, [selectedCheckpoint])

  return (
    <div className="details-dropdown">
      {timeline?.map((checkpoint, index) => (
        <TimelineItem
          onSelect={(v) => { setSelectedCheckpoint(v) }}
          key={index}
          checkpoint={checkpoint}
          selected={JSON.stringify(checkpoint) == JSON.stringify(selectedCheckpoint)}
        />
      ))}
    </div>
  );
};

export default Timeline;

