import React, { useState, useEffect, useRef } from 'react';
import { Image } from 'react-bootstrap';
import ArrowDown from './arrowDown.png';

export default function TimeSelect({ uniqueName, onChange, value, disabled, defaultValue }) {
    const [dropdown, setDropdown] = useState(false);
    const nameWithoutWhiteSpace = uniqueName ? uniqueName.split(' ').join('') : '';
    const [digitValue, setDigitValue] = useState({
        hour: defaultValue?.hour,
        minute: defaultValue?.minute,
        second: defaultValue?.second
    });
    const [isAM, setIsAM] = useState(defaultValue?.isAM);

    const hourRef = useRef(null);
    const minuteRef = useRef(null);
    const secondRef = useRef(null);

    function formatTime(hour, minute, second, isAM, options) {
        if (!(hour && second && minute && 0 < hour && hour <= 12 && 0 <= minute && minute < 60 && 0 <= second && second < 60)) {
            return '00:00 am';
        }

        let timeString = `${String(hour).padStart(2, '0')}:${String(minute).padStart(2, '0')}`;
        timeString += isAM ? ' am' : ' pm';
        return timeString;
    }

    useEffect(() => {
        if ((0 < digitValue.hour && digitValue.hour <= 12 && 0 <= digitValue.minute && digitValue.minute < 60 && 0 <= digitValue.second && digitValue.second < 60) || (!digitValue.hour || !digitValue.minute || digitValue.second))
            onChange({ ...digitValue, isAM })
    }, [digitValue, isAM])

    useEffect(() => {
        const clickListener = (e) => {
            if (e.target.id !== `keepDropdownOpen${nameWithoutWhiteSpace}`) {
                setDropdown(false);
            }
        };
        document.addEventListener('mousedown', clickListener);

        return () => {
            document.removeEventListener('mousedown', clickListener);
        };
    }, [nameWithoutWhiteSpace]);

    const renderTimeInput = (label, max, min, ref) => (
        <div className="d-flex flex-column align-items-start">
            <span id={`keepDropdownOpen${nameWithoutWhiteSpace}`} className="py-2" style={{ font: 'normal normal normal 1rem Open Sans' }}>
                {label}
            </span>
            <input
                ref={ref}
                id={`keepDropdownOpen${nameWithoutWhiteSpace}`}
                max={max}
                min={min}
                maxLength={2}
                value={digitValue[label?.toLowerCase()]}
                className='px-2'
                onChange={(e) => {
                    let timeValue = e.target.value;
                    if(timeValue?.length > 2) {
                      timeValue=  timeValue.slice(1)
                    }
                    if (timeValue && (label === 'Hour' && (0 > timeValue || timeValue > 12)) ||
                        (label === 'Minute' && (0 > timeValue || timeValue > 59)) ||
                        (label === 'Second' && (0 > timeValue || timeValue > 59))
                    )
                        return;
                    setDigitValue({ ...digitValue, [label.toLowerCase()]: timeValue });

                    if (timeValue.length === 2) {
                        if (label === 'Hour') minuteRef.current.focus();
                        else if (label === 'Minute') secondRef.current.focus();
                    }
                }}
                type="number"
                style={{
                    background: '#F8FAFD 0% 0% no-repeat padding-box',
                    border: '1px solid #E1E8F4',
                    borderRadius: "1rem",
                    height: '3rem',
                    width: '3rem',
                }}
            />
        </div>
    );

    return (
        <div style={{ position: 'relative', zIndex: 400 }}>
            <button
                id={`keepDropdownOpen${nameWithoutWhiteSpace}`}
                disabled={disabled}
                onClick={() => setDropdown(!dropdown)}
                style={{
                    backgroundColor: 'white',
                    maxWidth: '7rem',
                    height: '2.5rem',
                    border: `1px solid #E1E8F4`,
                    cursor: 'pointer',
                    borderRadius: "1.25rem",
                    outline: 'none'
                }}
                className={`d-flex align-items-center px-2 ${dropdown ? 'primary-color-border' : ''}`}
            >
                <span id={`keepDropdownOpen${nameWithoutWhiteSpace}`} style={{ font: 'normal normal normal 1rem Open Sans' }}>
                    {formatTime(digitValue?.hour, digitValue?.minute, digitValue?.second, isAM)}
                </span>
                <Image src={ArrowDown} className="pl-2 mb-1" style={{ height: '0.4rem', display: 'inline', marginTop: 5 }} alt="down" />
            </button>
            {dropdown && (
                <div id={`keepDropdownOpen${nameWithoutWhiteSpace}`} className="d-flex align-items-end justify-content-between py-4 px-4" style={{ background: '#FFFFFF', boxShadow: '1px 6px 10px #00000029', border: '1px solid #E1E8F4', borderRadius: 4, position: 'absolute' }}>
                    {renderTimeInput('Hour', 12, 0, hourRef)}
                    <div className="px-3 mb-2">:</div>
                    {renderTimeInput('Minute', 59, 0, minuteRef)}
                    <div className="px-3 mb-2">:</div>
                    {renderTimeInput('Second', 59, 0, secondRef)}
                    <div className="ml-3" style={{ background: '#FAFBFD', border: '1px solid #E1E8F4', borderRadius: "1.8rem" }}>
                        <div id={`keepDropdownOpen${nameWithoutWhiteSpace}`} className="py-1 px-3" onClick={() => setIsAM(true)} style={{ borderRadius: '2.5rem', cursor: 'pointer', font: 'normal normal 600 12px/22px Open Sans', backgroundColor: !isAM ? 'transparent' : '#14233C', color: !isAM ? 'black' : 'white' }}>
                            AM
                        </div>
                        <div id={`keepDropdownOpen${nameWithoutWhiteSpace}`} className="py-1 px-3" onClick={() => setIsAM(false)} style={{ borderRadius: '2.5rem', cursor: 'pointer', font: 'normal normal 600 12px/22px Open Sans', backgroundColor: isAM ? 'transparent' : '#14233C', color: isAM ? 'black' : 'white' }}>
                            PM
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}
