import React, { useState } from "react";
import ShiftTotalDelayCompareSectionBarGraph from "../components/ShiftTotalDelayCompareSectionBarGraph";
import { getHourMinuteDifference } from "../../helper";
import PieChartSection from "../components/PieChartSection";
import { constData } from "../../constData";
import { issueTypes } from "../../constData";

export default function ShiftTotalDelayCompareSection({ data }) {
    const [selected, setSelected] = useState(0);

    return (
        <div
            className="row d-flex justify-content-between"
        >
            {/* <div style={{ borderRight: '1px solid #E1E8F4', minWidth: '215px', display: 'flex', justifyContent: 'flex-end', width: 'calc(100% - 300px)', height: '275px' }}> */}
            <div className="col-8">
                {/* <div> */}
                <ShiftTotalDelayCompareSectionBarGraph
                    data={{
                        categories: data?.[selected]?.equipments?.length
                            ? data?.[selected].equipments.map((x) => x.Unit)
                            : [" ", " ", " "],
                        values: data?.[selected].equipments?.length
                            ? data?.[selected].equipments.map((x) => x.delay)
                            : [0, 0, 0],
                    }}
                />
            </div>
            <div className="col-4">
                <PieChartSection
                    listDetails={constData?.map((row, j) => ({
                        ...row,
                        value: j,
                        data: data?.[j]?.total
                            ? getHourMinuteDifference(
                                  0,
                                  1000 * data?.[j]?.total,
                                  "h"
                              )
                            : "00:00 h",
                    }))}
                    data={constData?.map((row, j) => ({
                        ...row,
                        value: data?.[j]?.total ? data?.[j]?.total : 0,
                    }))}
                    selected={selected}
                    selectedIndex={selected}
                    onSelected={(v) => setSelected(v)}
                    title={"Truck Delays (Shift Totals)"}
                />
            </div>
        </div>
    );
}
