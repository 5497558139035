import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import liveIcon from '../../assets/svgs/AssetMonitoring/monitoring.svg';
import historyIcon from '../../assets/svgs/AssetMonitoring/historyAssetTracking.svg';
import AssetTrackingLive from './AssetTrackingLive/AssetTrackingLive';
import TripHistory from './AssetTracking/TripsHistory/TripsHistory';
import AuthLayout from '../../components/AuthLayout';
import NavigationOption from './NavigationOption';

// this component contains header sttructure of navigation links oh trip history and live tracking
export default function AssetMonitoring({ ...props }) {
    const location = useLocation();
    const navigate = useNavigate(); 
    const queryParams = new URLSearchParams(location.search);
    const options = [
        { displayName: 'Live', icon: liveIcon, name: 'live' },
        { displayName: 'Trips History', icon: historyIcon, name: 'history' }
    ];
    // query params in use help to identify which navigation option to load by default
    // if type is present then active option is set else live page is loaded
    const [activeOption, setActiveOption] = useState(queryParams?.get('type') ? queryParams?.get('type') : 'live');

    const handleNavigation = (optionName) => {
        navigate(`?type=${optionName}`); // Navigate to the current path with new query parameter
    };


    return (
        <AuthLayout pageName="Asset Monitoring">
            <div className="right_sidebar_body">
                <nav style={{ padding: '1.5rem' }}>
                    <ul style={{ display: 'flex', alignItems: 'center' }}>
                        {options.map((option, index) => (
                            <div key={index} onClick={() => handleNavigation(option.name)}>
                                <NavigationOption
                                    key={index}
                                    option={option}
                                    isActive={option.name === activeOption}
                                    onClick={() => setActiveOption(option.name)}
                                />
                            </div>
                        
                        ))}
                    </ul>
                </nav>
                {activeOption === 'live' && <AssetTrackingLive />}
                {activeOption === 'history' && <TripHistory />}
            </div>
        </AuthLayout>
    );
}