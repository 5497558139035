import React from "react";
import { IoCloseSharp } from "react-icons/io5";

const DeleteYearSelection = ({
  years,
  setShowDeleteConfirmation,
  setShowDeleteYears,
  selectedYears,
  setSelectedYears,
}) => {
  const onChange = (e) => {
    const { name, checked, value } = e.target;

    if (name === "all") {
      setSelectedYears(checked ? years : []); // Toggle all years
    } else {
      setSelectedYears((prev) =>
        checked ? [...prev, value] : prev.filter((year) => year !== value)
      );
    }
  };

  const isAllSelected = selectedYears.length === years.length && years.length > 0; // To ensure "Select All" works correctly when years are present

  return (
    <div
      className="d-flex flex-column"
      style={{
        background: "white",
        borderRadius: "10px",
        width: 275,
      }}
    >
      <div
        className="d-flex flex-column p-4 px-5"
        style={{
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
        }}
      >
        <div className="d-flex justify-content-between align-items-center w-100 mb-4">
          <div style={{ fontWeight: "bolder" }}>Delete Year</div>
          <div
            style={{ cursor: "pointer" }}
            onClick={() => setShowDeleteYears(false)}
          >
            <IoCloseSharp color="black" fontSize={25} />
          </div>
        </div>

        <div className="d-flex flex-column align-items-start w-100" style={{ gap: "5px" }}>
          <div className="d-flex align-items-center mb-3">
            <input
              type="checkbox"
              id="selectAll"
              onChange={onChange}
              name="all"
              checked={isAllSelected}
            />
            <div htmlFor="selectAll" className="ml-2" style={{ color: "#6A7C9A", font: "normal normal 600 12px/17px Open Sans" }}>
              Select All
            </div>
          </div>

          <div style={{ display: "flex", flexDirection: "column", gap: "1px" }}>
            {years.map((year, index) => (
              <div key={index} className="form-group d-flex align-items-center">
                <input
                  type="checkbox"
                  id={`year-${year}`}
                  name="year"
                  value={year}
                  onChange={onChange}
                  checked={selectedYears.includes(year)}
                />
                <div htmlFor={`year-${year}`} className="ml-2" style={{ font: "normal normal 600 14px/19px Open Sans" }}>
                  {year}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div
        className="d-flex justify-content-end p-4 pr-5"
        style={{
          boxShadow: "0px -2px 4px #0000000A",
          border: "1px solid #E1E8F4",
          borderRadius: "0px 0px 10px 10px",
        }}
      >
        <div
          className="px-4 py-2"
          style={{

            color: "white",
            borderRadius: "20px",
            userSelect: "none",
            cursor: "pointer",
            font: "normal normal 600 14px/19px Open Sans",
            ...(selectedYears?.length > 0 ? { backgroundColor: "#186FD9", } : { backgroundColor: '#E1E4EA', color: '#8190AD' })
          }}
          onClick={() => setShowDeleteConfirmation(true)}
        >
          Delete
        </div>
      </div>
    </div>
  );
};

export default DeleteYearSelection;
