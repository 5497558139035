import moment from 'moment-timezone';
import TextInputField from 'pages/ShiftPlanning/components/GeneralFields/TextInputField';
import React from 'react'
const monthNames = [
    "January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
];

function CRUDComponent({ formData, year, viewOnly, handleFieldChange, originalFormData }) {
    return (
        <div>
            {Object.values(formData)?.map((data, i) => <TextInputField
                label={monthNames[i]}
                required={false}
                viewOnly={viewOnly}
                isDisabled={(() => {
                    const m = moment().tz("Pacific/Auckland");
                    return m.year() > year || (m.year() === year && m.month() > i);
                })()}
                // style={originalFormData[i + 1] != data ? { border: '1px solid #186FD9' } : {}}
                placeholder={"-"}
                value={data}
                containerStyle={viewOnly ? { minHeight: "3rem", marginBottom: 5, marginTop: 5 } : {}}
                textStyle={viewOnly ? { height: '3rem' } : {}}
                onChange={(v) => handleFieldChange({ month: i, year }, v)}
            />)}

        </div>
    )
}

export default CRUDComponent