import React, { useState } from 'react'
import { Form } from "react-bootstrap";
import DownloadButton from "../../components/DownloadButton";
import html2canvas from "html2canvas";
import jsPDF from 'jspdf';
import SpeedingEvents from './speedingEvents/SpeedingEvents';
import { formatDate, formatDateByTimestamp } from '../../../Maintenance/DumpCycleTracker/helper';
import { speedingEventsTypesCostData } from '../constData';
import { getHourMinuteDifference } from '../../Delays/helper';

export default function DetailPage({ onClose, title, data, ...props }) {
    const [choice, setChoice] = useState(0);
    // const [excavator, setExcavator] = useState(0)
    const [selectedDate, setSelectedDate] = useState('')
    const [equipmentType, setEquipmentType] = useState(0);
    // const [detailPage, setDetailPage] = useState(false)
    const handleDownloadPDF = () => {
        // html2canvas(contentRef.current).then((canvas) => {
        //     const imgData = canvas.toDataURL('image/png');
        //     const pdf = new jsPDF('landscape', 'px');

        //     pdf.addImage(imgData, 'PNG', 0, 0, pdf.internal.pageSize.getWidth(), pdf.internal.pageSize.getHeight());
        //     pdf.save('Downloaded-pdf.pdf');
        // })
    }

    const formatDateDDMMYYY = (date) => {
        let yr = Math.floor(date / 10000);
        let mth = Math.floor((date % 10000) / 100);
        let dt = Math.floor(date % 100);
        return (dt <= 9 ? ("0" + dt) : dt) + "/" + (mth <= 9 ? ("0" + mth) : mth) + "/" + yr;
    }

    const getSpeedingColor = (spdLow, spdHigh) => {
        if (spdHigh <= 30) {
            return speedingEventsTypesCostData[0].color;
        } else if (spdHigh <= 50) {
            return speedingEventsTypesCostData[1].color;
        } else {
            return speedingEventsTypesCostData[2].color;
        }
    }

    const getSpeedingLabel = (spdLow, spdHigh) => {
        // let max_spd = 0;
        // if (speedings?.length) {
        //     for (let i = 0; i < speedings.length; i++) {
        //         if (max_spd < speedings[i].speedHigh) {
        //             max_spd = speedings[i].speedHigh;
        //         }
        //     }
        // }
        if (spdHigh <= 30) {
            return spdLow + " - " + spdHigh + " (km/h)";
        } else if (spdHigh <= 50) {
            return spdLow + " - " + spdHigh + " (km/h)";
        } else {
            return " > " + spdLow + " km/h";
        }
    }

    return (
        <div style={{
            position: 'fixed',
            top: 0,
            right: 0,
            width: 'calc(100vw)',
            height: 'calc(100vh)',
            zIndex: 5000000,
            backgroundColor: "#F5F6F8",
            overflow: 'scroll'
        }}>
            <div className='mt-3 mx-5'>
                <div className='py-3 d-flex justify-content-between'>
                    <h6 style={{ fontSize: '1.5rem', fontWeight: '700' }}>{title}</h6>
                    <div style={{ cursor: 'pointer' }} onClick={onClose}>X</div>
                </div>

                <div style={{ paddingLeft: '0.938rem' }} className='mb-3 d-flex align-items-center justify-content-between'>

                    <div className='d-flex align-items-center'>
                        <div>
                            <input
                                type="date"
                                className="form-control date-picker"
                                value={selectedDate}
                                style={{
                                    fontSize: "1rem",
                                    borderColor: "#E1E8F4",
                                    borderRadius: "1.125rem",
                                    color: "#6B7B8E",
                                    fontWeight: "600",
                                    height: "2.3rem",
                                    padding: "0.313rem 0.938rem",
                                    width: "14rem",
                                    backgroundColor: "white",
                                    marginRight: "0.938rem",

                                }}
                                placeholder={"DD/MM/YYYY"}
                                onChange={(e) => setSelectedDate(e.target.value)}
                            />
                        </div>
                        {/* <div className='d-flex justify-content-between align-items-center'>
                            <Form.Group controlId="formBasicSelect">
                                <Form.Control
                                    as="select"
                                    value={equipmentType}
                                    style={{
                                        fontSize: ".8rem",
                                        borderRadius: "5px",
                                        color: "grey",
                                        padding: "1px 7px",
                                        width: "10rem",
                                    }}
                                    onChange={(e) => setEquipmentType(e.target.value)}
                                >
                                    <option value={0}>Trucks</option>
                                    <option value={1}>Excavators</option>
                                </Form.Control>
                            </Form.Group>
                        </div> */}
                        {/* <div style={{ borderRight: '1px solid #E1E8F4', height: '2rem' }} className='d-flex align-items-center mx-4'>
                            <div className='mx-2 d-flex align-items-center' style={{
                                font: 'normal normal bold 12px/14px Open Sans',
                                color: '#17253D'
                            }}>
                                <div onClick={() => setChoice(0)} className='d-flex align-items-center justify-content-center mx-2' style={{
                                    width: '1rem',
                                    height: '1rem',
                                    borderRadius: '50%',

                                    border: choice == 0 ? '1px solid #14233C' : '1px solid #E1E8F4',
                                    backgroundColor: choice != 0 ? '#F8FAFD' : '#F8FAFD'
                                }}>
                                    <div style={{
                                        width: '0.5rem',
                                        height: '0.5rem',
                                        borderRadius: '50%',
                                        backgroundColor: choice == 0 ? '#14233C' : 'transparent'
                                    }}>

                                    </div>
                                </div>
                                <span>MTD</span>
                            </div>
                            <div className='ml-3 mr-3 d-flex align-items-center' style={{
                                font: 'normal normal bold 12px/14px Open Sans',
                                color: '#17253D'
                            }}>
                                <div onClick={() => setChoice(1)} className='d-flex align-items-center justify-content-center mx-2' style={{
                                    width: '1rem',
                                    height: '1rem',
                                    borderRadius: '50%',
                                    border: choice == 1 ? '1px solid #14233C' : '1px solid #E1E8F4',
                                    backgroundColor: choice != 1 ? '#F8FAFD' : '#F8FAFD'
                                }}>
                                    <div style={{
                                        width: '0.5rem',
                                        height: '0.5rem',
                                        borderRadius: '50%',
                                        backgroundColor: choice == 1 ? '#14233C' : 'transparent'
                                    }}>

                                    </div>
                                </div>
                                <span>YTD</span>
                            </div>
                        </div> */}

                    </div>
                    <div className="d-flex align-items-center">
                        <div className="mr-3" style={{ fontSize: '0.75rem' }}>
                            Displaying data for {(selectedDate ? selectedDate : new Date().toLocaleDateString('en-US', { timeZone: 'Pacific/Auckland', day: '2-digit', month: '2-digit', year: 'numeric' })).replace(/-/g, '/')}, 5:45 AM - 4:00 AM
                        </div>
                        <div className="d-flex align-items-center">
                            <DownloadButton handleDownloadPDF={handleDownloadPDF} />
                        </div>
                    </div>
                </div >
            </div>

            <div className='mt-3 mx-5 p-5'
                style={{
                    width: props.width,
                    backgroundColor: "white",
                    height: 'max-content',
                    borderRadius: "0.938rem",
                    boxShadow: "0rem 0.125rem 0.375rem #0000000F",
                }}
            >
                <SpeedingEvents data={data} date={selectedDate} fromDetail={true}/>
            </div>

            <div className='mt-5 mx-5'>
                <main
                    style={{
                        position: "relative",
                    }}
                >
                    <table
                        style={{ backgroundColor: "#fff" }}
                        className="shift-history-table"
                    >
                        <thead>
                            <tr style={{ fontWeight: "600" }}>
                                <th style={{ width: '5%' }}><input type="checkbox" /></th>
                                <th style={{ width: "6%" }}>Asset ID</th>
                                <th style={{ width: "10%" }}>Speed (km/h)</th>
                                <th style={{ width: "6%" }}>Count</th>
                                <th style={{ width: "15%" }}>Duration (h)</th>
                                {/* <th style={{ width: "8%" }}>Operator</th> */}
                                <th style={{ width: "4%" }}>Category</th>
                                {/* <th style={{ width: "8%" }}>Trips</th> */}
                                {/* <th style={{ width: "8%" }}>Shift Ref</th> */}
                            </tr>
                        </thead>
                    </table>
                </main>
                <main
                    style={{
                        position: "relative",
                        maxHeight: "calc(100% - 17.5rem)",
                        overflowY: "scroll",
                    }}
                >
                    {/* {[
                        {
                            eventIntervals: [{ events: [{}, {}, {}] }, { events: [{}, {}, {}] }],
                            formatedsingleData: "DD/MM/YYYY", // Add a sample date here
                        },
                    ]?.filter((singleData) => singleData) */}
                    {data?.map((singleData, i) => (
                        <div key={i}>
                            <table
                                style={{ backgroundColor: "#fff" }}
                                className="shift-history-table"
                            >
                                <tbody>
                                    <tr style={{ borderBottom: "none" }}>
                                        <th colSpan={5} style={{ padding: "1.5rem 0 1.2rem 4rem" }}>
                                            <span
                                                className="px-4 py-2"
                                                style={{
                                                    background: "#707070",
                                                    width: "max-content",
                                                    padding: "2px 3px",
                                                    borderRadius: "1.25rem",
                                                    backgroundColor: "#00214d",
                                                    color: "white",
                                                    font: "normal normal bold 12px/17px Open Sans",
                                                    fontSize: "1rem",
                                                }}
                                            >
                                                {formatDateDDMMYYY(singleData.date)}
                                            </span>
                                            <span
                                                className="px-4 py-2"
                                                style={{
                                                    background: "#707070",
                                                    width: "max-content",
                                                    padding: "2px 3px",
                                                    borderRadius: "1.25rem",
                                                    marginLeft: "15px",
                                                    backgroundColor: "#E1E8F4",
                                                    color: "black",
                                                    font: "normal normal bold 12px/17px Open Sans",
                                                    fontSize: "1rem",
                                                }}
                                            >
                                                {singleData.events?.length || "00"}
                                            </span>
                                        </th>
                                    </tr>
                                    {singleData?.events?.map((event, j) => (
                                        < >
                                            {/* <tr>
                                                    <th colSpan={8} style={{ padding: "1.5rem 0 1.2rem 4rem" }}>
                                                        <em className='ml-4'>{j === 0 ? "Day" : "Night"} - {interval?.events?.length || "00"}</em>
                                                    </th>
                                                </tr> */}
                                            <tr>
                                                <th colSpan={5} style={{ padding: "1.5rem 0 1.2rem 4rem" }}>
                                                    <em className='ml-4'>{event?.asset_no}</em>
                                                </th>
                                            </tr>
                                            {event.speedings.map((speeding, k) => (
                                                <tr key={k} hover={1} style={{ fontWeight: "400", fontSize: "1.1rem" }}>
                                                    <td style={{ width: '5%', paddingLeft: "4rem" }}><input type="checkbox" /></td>
                                                    <td style={{ width: "6%" }}>{event?.asset_no}</td>
                                                    <td style={{ width: "10%" }}>
                                                        <div className='row' style={{ alignItems: "baseline" }}>
                                                            <div style={{
                                                                width: "7.5px",
                                                                height: "7.5px",
                                                                borderRadius: "50%",
                                                                float: "left",
                                                                backgroundColor: getSpeedingColor(speeding.speedLow, speeding.speedHigh),
                                                            }}>
                                                            </div>
                                                            <div style={{
                                                                height: "7.5px",
                                                                float: "left",
                                                                whiteSpace: "nowrap",
                                                                marginLeft: "7px"
                                                            }}>
                                                                {getSpeedingLabel(speeding.speedLow, speeding.speedHigh)}
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td style={{ width: "6%" }}>{speeding.count}</td>
                                                    <td style={{ width: "15%" }}>
                                                        {formatDateByTimestamp(event?.startTime, "00:00")} -{" "}
                                                        {formatDateByTimestamp(event?.endTime, "00:00")} (
                                                        {event?.startTime && event?.endTime
                                                            ? getHourMinuteDifference(
                                                                new Date(event?.startTime * 1000),
                                                                new Date(event?.endTime * 1000)
                                                            )
                                                            : "00:00 hrs"}
                                                        )
                                                    </td>
                                                    <td style={{ width: "4%" }}></td>
                                                    {/* <td></td> */}
                                                    {/* <td></td>
                                                            <td></td>
                                                            <td></td> */}
                                                </tr>
                                            ))}
                                        </>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    ))}
                </main>
            </div>

        </div >
    )
}
