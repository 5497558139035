import React, { useState } from 'react'
import { Form } from 'react-bootstrap';
import '../../style.css'


export default function MonthlyYearlyHeader({ choice, type, setChoice, title, setEquipmentType, equipmentType, onMenuSelect,
    menuOptions }) {
    const [menuVisible, setMenuVisible] = useState(false)
    return (<div style={{ paddingLeft: '0.938rem' }} className='mb-3 d-flex align-items-center justify-content-between'>
        <h6>{title}</h6>
        <div className='d-flex align-items-center'>
            {/* <div style={{ borderRight: '1px solid #E1E8F4', height: '2rem' }} className='d-flex align-items-center mx-4'>
                <div className='mx-2 d-flex align-items-center' style={{
                    font: 'normal normal bold 12px/14px Open Sans',
                    color: '#17253D'
                }}>
                    <div onClick={() => setChoice(0)} className='d-flex align-items-center justify-content-center mx-2' style={{
                        width: '1rem',
                        height: '1rem',
                        borderRadius: '50%',

                        border: choice == 0 ? '1px solid #14233C' : '1px solid #E1E8F4',
                        backgroundColor: choice != 0 ? '#F8FAFD' : '#F8FAFD'
                    }}>
                        <div style={{
                            width: '0.5rem',
                            height: '0.5rem',
                            borderRadius: '50%',
                            backgroundColor: choice == 0 ? '#14233C' : 'transparent'
                        }}>

                        </div>
                    </div>
                    <span>MTD</span>
                </div>
                <div className='ml-3 mr-3 d-flex align-items-center' style={{
                    font: 'normal normal bold 12px/14px Open Sans',
                    color: '#17253D'
                }}>
                    <div onClick={() => setChoice(1)} className='d-flex align-items-center justify-content-center mx-2' style={{
                        width: '1rem',
                        height: '1rem',
                        borderRadius: '50%',
                        border: choice == 1 ? '1px solid #14233C' : '1px solid #E1E8F4',
                        backgroundColor: choice != 1 ? '#F8FAFD' : '#F8FAFD'
                    }}>
                        <div style={{
                            width: '0.5rem',
                            height: '0.5rem',
                            borderRadius: '50%',
                            backgroundColor: choice == 1 ? '#14233C' : 'transparent'
                        }}>

                        </div>
                    </div>
                    <span>YTD</span>
                </div>
            </div> */}
            <div className='d-flex justify-content-between align-items-center'>
                {/* <Form.Group controlId="formBasicSelect">
                    <Form.Control
                        as="select"
                        value={equipmentType}
                        style={{
                            fontSize: ".8rem",
                            borderRadius: "5px",
                            color: "grey",
                            padding: "1px 7px",
                            width: "10rem",
                        }}
                        onChange={(e) => setEquipmentType(e.target.value)}
                    >
                        <option value={0}>Trucks</option>
                        <option value={1}>Excavators</option>
                    </Form.Control>
                </Form.Group> */}
                <div className='ml-3' style={{ position: 'relative' }}>
                    <div
                        className={`px-3 py-1 d-flex align-items-center justify-content-center ${menuVisible ? 'rounded' : ''
                            }`}
                        onClick={() => setMenuVisible(!menuVisible)}
                        style={{ cursor: 'pointer', backgroundColor: menuVisible ? "#E2ECFC" : "transparent" }}
                    >
                        <ul className="icons">
                            <li></li>
                            <li></li>
                            <li></li>
                        </ul>
                    </div>
                    {menuVisible && (
                        <div
                            className="py-2 d-flex flex-column mt-1"
                            style={{
                                right: '0',
                                backgroundColor: 'white',
                                position: 'absolute',
                                minWidth: 'max-content',
                                boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.1)',
                                border: '1px solid #E1E8F4',
                                borderRadius: '0.5rem',
                                cursor: 'pointer',
                                zIndex: "2000"
                            }}
                        >
                            {menuOptions?.map((option, idx) => <div
                                className="hover-blue pl-3 pr-5"
                                style={{
                                    textAlign: 'left',
                                    fontWeight: '600',
                                    fontSize: '1rem',
                                }}
                                onClick={() => {
                                    setMenuVisible(false);
                                    onMenuSelect(idx);
                                }}
                            >
                                {option}
                            </div>)}

                        </div>
                    )}
                </div>
            </div>
        </div>
    </div >
    )
}
