import React, { useEffect } from 'react';
import DownloadIcon from './download.svg';

export default function DownloadMenu({ menuVisible, setMenuVisible, onMenuSelect, menuOptions, uniqueName, color, selectedColor, disabled, icon, selectedIcon }) {
    const nameWithoutWhiteSpace = uniqueName ? uniqueName.split(' ').join('') : '';

    useEffect(() => {
        const clickListener = (e) => {
            if (e.target.id !== `keepDropdownOpen${nameWithoutWhiteSpace}`) {
                setMenuVisible(false);
            }
        };
        document.addEventListener('mousedown', clickListener);

        return () => {
            document.removeEventListener('mousedown', clickListener);
        };
    }, [nameWithoutWhiteSpace]);

    return (
        <div id={`keepDropdownOpen${nameWithoutWhiteSpace}`} style={{ position: 'relative' }}>
            <div
                id={`keepDropdownOpen${nameWithoutWhiteSpace}`}
                className={`px-2 d-flex align-items-center justify-content-center rounded`}
                onClick={(e) => {
                    e.stopPropagation();
                    setMenuVisible(!menuVisible);
                }}
                style={{
                    cursor: 'pointer',
                    height: '2.5rem',
                    width: '2.5rem',
                    backgroundColor: disabled ? 'grey' : (menuVisible ? selectedColor : (color ? color : 'transparent')),
                }}
            >
                <img id={`keepDropdownOpen${nameWithoutWhiteSpace}`} style={{ width: '1.2rem', height: '1.2rem', objectFit: 'contain' }} src={menuVisible ? (selectedIcon ? selectedIcon : icon) : (icon ? icon : DownloadIcon)} alt="" />
            </div>
            {
                menuVisible && !disabled && (
                    <div
                        id={`keepDropdownOpen${nameWithoutWhiteSpace}`}
                        className="py-2 d-flex flex-column mt-1"
                        style={{
                            right: '0',
                            backgroundColor: 'white',
                            position: 'absolute',
                            minWidth: 'max-content',
                            boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.1)',
                            border: '1px solid #E1E8F4',
                            borderRadius: '0.5rem',
                            cursor: 'pointer',
                            zIndex: '2000',
                        }}
                    >
                        {menuOptions?.map((option, idx) => (
                            <div
                                id={`keepDropdownOpen${nameWithoutWhiteSpace}`}
                                key={idx}
                                className="hover-blue pl-4 pr-4 py-3 d-flex align-items-center "
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setMenuVisible(false);
                                    console.log("hey");
                                    onMenuSelect(idx);
                                }}
                            >
                                <img id={`keepDropdownOpen${nameWithoutWhiteSpace}`} style={{ height: '1rem', width: '1rem', objectFit: 'contain' }} src={option.icon} alt="" />
                                <span id={`keepDropdownOpen${nameWithoutWhiteSpace}`} style={{ textAlign: 'left', fontWeight: '600', fontSize: '1rem', paddingLeft: '.6rem' }}>
                                    {option.displayName}
                                </span>
                            </div>
                        ))}
                    </div>
                )
            }
        </div >
    );
}
