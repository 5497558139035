export const constData = [
    { color: "#367ED8", label: "Idle Time Delays (ITD)" },
    { color: "#35B776", label: "Weather Delay (WD, D1)" },
    { color: "#E55C5C", label: "Maintenance Downtime (MD)" },
    { color: "#F2B50E", label: "Planned Operational Delays (POD)" },
    { color: "#4FC5DB", label: "Unplanned Delays (UD)" },
    { color: "#B764D0", label: "Scheduled Non-Operating Time (SNO)" },
];

export const issueTypes = ["ITD", "WD", "MD", "POD", "UD", "SNO"];

export const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
];

export const delaysViewDetailsdata = [
    {
        day: {
            itdData: "00:01",
            wdData: "00:01",
            mdData: "00:01",
            podData: "00:01",
            udData: "00:01",
            snoData: "00:01",
        },
        night: {
            itdData: "00:00",
            wdData: "00:00",
            mdData: "00:00",
            podData: "00:00",
            udData: "00:00",
            snoData: "00:00",
        },
    },
];

export const delaysViewDetailsTableHeader = [
    "Assest Id",
    "ITD (h)",
    "WD, D1 (h)",
    "MD (h)",
    "POD (h)",
    "UD (h)",
    "SNO (h)",
    "Total Delays (h)",
];

export const delaysViewDetailsTableData = [
    ["HT021", "00:00", "00:00", "00:00", "00:00", "00:00", "00:00", "00:00"],
    ["HT022", "00:00", "00:00", "00:00", "00:00", "00:00", "00:00", "00:00"],
    ["HT023", "00:00", "00:00", "00:00", "00:00", "00:00", "00:00", "00:00"],
    ["HT024", "00:00", "00:00", "00:00", "00:00", "00:00", "00:00", "00:00"],
    ["HT025", "00:00", "00:00", "00:00", "00:00", "00:00", "00:00", "00:00"],
    ["HT026", "00:00", "00:00", "00:00", "00:00", "00:00", "00:00", "00:00"],
];

export const wdViewDetailsTableHeader = [
    "AssedId",
    "Actual (h)",
    "Planned (h)",
    "variance (h)",
];

export const wdViewDetailsTableData = [
    ["HT021", "00:00", "00:00", "00:00"],
    ["HT022", "00:00", "00:00", "00:00"],
    ["HT023", "00:00", "00:00", "00:00"],
    ["HT024", "00:00", "00:00", "00:00"],
    ["HT025", "00:00", "00:00", "00:00"],
    ["HT026", "00:00", "00:00", "00:00"],
];
