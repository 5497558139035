import { Modal } from 'react-bootstrap';
import TimelineGraph from './TimelineGraph';

export default function TimelineGraphModal({
  allCoordinates,
  title,
  modalOpen,
  modalToggle,
  selectedSpeed,
  speedUnit = 'km/h',
  selectedTime,
  setSelectedTime,
}) {
  return (
    <div className="" style={{ borderRadius: '4px 0px 0px 0px' }}>
      {modalOpen && (
        <Modal
          show={modalOpen}
          // onHide={modalToggle(false)}
          // backdropClassName={'asm-modal-backgrop-style'}
          keyboard={false}
          size="lg"
          dialogClassName="asm-timeline-graph-modal-position"
          className="modal-transparent"
          backdrop={false}
          style={{ position: 'fixed', bottom: 0, height: 0, zIndex: "9999" }}
        >
          <Modal.Body>
            <div
              className="font-13 d-flex flex-column justify-content-between ms-4 me-2 my-3 font-13 mb-3"
              style={{
                font: 'normal normal 14px/19px Open Sans',
                borderRadius: '10px 0px 0px 0px',
                padding: '0rem 1rem',
              }}
            >
              <div className="d-flex flex-column gap-2">
                <div className="mb-1">
                  <TimelineGraph
                    allCoordinates={allCoordinates}
                    title={title}
                    modalToggle={(v) => modalToggle(v)}
                    selectedSpeed={selectedSpeed}
                    speedUnit={speedUnit}
                    selectedTime={selectedTime}
                    setSelectedTime={setSelectedTime}
                  />
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      )}
    </div>
  );
}
