import SpeedingEventsChart from "./components/speedingEvents/SpeedingEventsChart";

export function getHourMinuteDifference(start, end, hrs) {
  const diffInMs = Math.abs(end - start);
  const hours = Math.floor(diffInMs / (1000 * 60 * 60));
  const minutes = Math.floor((diffInMs / (1000 * 60)) % 60);
  const formattedTime = `${hours.toString().padStart(2, "0")}:${minutes
    .toString()
    .padStart(2, "0")}`;
  if (hrs)
    return formattedTime + ' ' + hrs;
  return formattedTime;
}


export const calculateAverageCycleDurationDumpCycle = (cycledurationArray) => {
  let totalCycleDuration = 0;
  for (const data of cycledurationArray) {
    totalCycleDuration += data
  }
  const averageCycleDuration = totalCycleDuration / cycledurationArray.length;
  return averageCycleDuration
}



export const calculateTimeRange = (startTime, endTime) => {
  const start = new Date(startTime);
  const end = new Date(endTime);

  const formatTime = (time) => {
    const hours = time.getUTCHours() ? time.getUTCHours() : 0;
    const minutes = time.getUTCMinutes() ? time.getUTCMinutes() : 0;
    const period = hours < 12 ? 'AM' : 'PM';
    const formattedHours = hours % 12 || 12; // Convert to 12-hour format

    return `${formattedHours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')} ${period}`;
  };

  const formattedStartTime = formatTime(start);
  const formattedEndTime = formatTime(end);

  return `${formattedStartTime} - ${formattedEndTime}`;
}

export const dumpCycleChartHover = (x, data, targetValue) => {

  {
    const loadNumber = x[0].dataIndex
    const loadLabel = x[0].axisValueLabel;
    const truckName = data?.truckName;
    let averageValue = x[0]?.data

    // const hrmin = getHourMinuteDifference(0,1000*averageValue);
    let cycleDurationRange = '00:00 AM - 00:00AM';
    if (data?.loads?.length > 1) {
      const loadStartTime = data?.loads[loadNumber]?.sourceArivalTime
      const loadEndTime = data?.loads[loadNumber + 1]?.sourceArivalTime
      cycleDurationRange = calculateTimeRange(loadStartTime, loadEndTime)
    }
    // const load = data?.loads[loadNumber];



    const loadingName = data?.loads[loadNumber]?.sourceAtStart;
    const dumpName = data?.loads[loadNumber]?.dumpPoint;

    const loadingTime = getHourMinuteDifference(0, 1000 * data?.breakdownOfAverageDumpCycleDuration.loading)
    const travelingTime = getHourMinuteDifference(0, 1000 * data?.breakdownOfAverageDumpCycleDuration.travelling)
    const dumpingTime = getHourMinuteDifference(0, 1000 * data?.breakdownOfAverageDumpCycleDuration.dumping)
    const trammingEmptyTime = getHourMinuteDifference(0, 1000 * data?.breakdownOfAverageDumpCycleDuration.trammingEmpty)

    averageValue -= (targetValue);
    let value = getHourMinuteDifference(0,1000*Math.abs(averageValue))
    let bgcolor = '#E2FDF1', textColor = '#1A6C29', progressBarColor = '#3AC49F';

    if (averageValue > 0 ) {
      value = '+' + value
      bgcolor = '#FCE5E5'
      textColor = '#AA2727'
      progressBarColor = '#EB5F5F'
    } else value = '-' + value;
    return `
      <div class='p-4' style="font-size:0.85rem; min-width:22rem">
      <div style="font-weight:600">
      ${truckName} - Load ${loadLabel}
      </div>
      <div style="color:#5F6672; font-style:italic; font-weight:600">${cycleDurationRange}</div>
     <div class='row py-2  d-flex align-items-center '>
     <div class='col-3 mr-2' style="color:#17253D;font-size:1rem; font-weight:800; ">${getHourMinuteDifference(0,1000*targetValue) !== 'NaN:NaN' ? getHourMinuteDifference(0,1000*targetValue)  : '00:00'} h</div>
     <div class=" px-3 d-flex align-items-center justify-content-center" style="
          background-color: ${bgcolor};
          color: ${textColor};
          font-size:0.85rem;
          border-radius:10px;
          padding-top:1px;
          line-height:1.5
          ";
        >
          ${value !== '-NaN:NaN'? value : "+00:00"}
        </div>
     </div>

    
      <div class='row pt-2'>
      <div class='col-8' style="color:#5F6672">
      <div>Loading Area (${loadingName})</div>
      <div>Travelling to ${dumpName}</div>
      <div>Dumping (${dumpName})</div>
      <div>Tramming Empty</div>
      </div>
      <div class='col-4 '  style="color:#17253D; font-weight:800">
       <div>${loadingTime} h</div>
       <div>${travelingTime} h</div>
       <div>${dumpingTime} h</div>
        <div>${trammingEmptyTime} h</div>
      </div>
      </div>

     
       <div>

     
    `}

}


// export const clickChartGraph = (type) => {
  
//   if (type == 2) {
    
//     return <SpeedingEventsChart />
//   }
// }