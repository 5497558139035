import DumpCycleDuration from "./components/DumpCycleDuration.js";
import MonthlyYearlyHeader from "../Delays/components/components/MonthlyYearlyHeader.js";
import ExcavatorSelectionHeader from "./components/ExcavatorSelectionHeader";
import CycleActivity from "./components/CycleActivity";
import SpeedingEvents from "./components/speedingEvents/SpeedingEvents";
import TopHeader from "../components/TopHeader";
import html2canvas from "html2canvas";
import jsPDF from 'jspdf';
import { useRef, useContext, useState } from "react";
import ManagementKPIsContext from "../Context/ManagementKPIsContext.js";
import DetailPage from './components/DetailPage'
import AuthLayout from '../../../components/AuthLayout';

const DumpCycleSection = ({ type, title, data, ...props }) => {
  const [choice, setChoice] = useState(0);
  const [excavator, setExcavator] = useState(0)
  const [equipmentType, setEquipmentType] = useState(0);
  const [detailPage, setDetailPage] = useState(false)

  const menuOptionHandler = (code) => {
    if (code == 1) {
      // setDataForDetailPage(data)
      setDetailPage(true)
    }
  }
  return (
    <div className="p-3"
      style={{
        width: props.width,
        backgroundColor: "white",
        height: '100%',
        borderRadius: "0.938rem",
        boxShadow: "0rem 0.125rem 0.375rem #0000000F",
      }}
    >
      {detailPage && <DetailPage data={data} onClose={() => setDetailPage(false)} title={title} />}
      {type == 0 || type == 1 ? (
        <ExcavatorSelectionHeader
          choice={choice}
          onChange={(v) => {
            setChoice(v);
            // setExcavator(0)
          }}
          selectedExcavator={excavator}
          excavatorList={data?.[choice]?.map((ex) => ex.excavatorName)}
          onExcavatorChange={setExcavator}
          title={title}
          menuOptions={["Download", "View Details"]}
          onMenuSelect={menuOptionHandler}
        />
      ) : (
        <MonthlyYearlyHeader
          choice={choice}
          onChange={(v) => {
            setChoice(v);
            // setExcavator(0)
          }}
          setChoice={setChoice}
          title={title}
          type={type}
          onMenuSelect={menuOptionHandler}
          menuOptions={["Download", "View Details"]}
        />
      )}

      {/* Conditionally render either ShiftTotalDelaySection or ShiftTotalDelayCompareSection */}
      {(() => {
        switch (type) {
          case 0:
            return <DumpCycleDuration data={data?.[choice]?.[excavator]} />;

          case 1:
            return (
              <CycleActivity data={data?.[choice]?.[excavator]} />
            );
          case 2:
            return <SpeedingEvents data={data} />;
          // case 3:
          //   return <PreStartCheck data={data?.[choice]} />;
          // case 4:
          //   return (
          //     <IdlingEvents data={data?.[choice]} />
          //   );
          default:
            break;
        }
      })()}
    </div>
  );
};
const AssetAnalytics = () => {
  const { assetAnalyticsData1, assetAnalyticsDate, setAssetAnalyticsDate, speedingData } = useContext(ManagementKPIsContext)
  const myRefs = [useRef(), useRef(), useRef()];
  const contentRef = useRef()

  function scrollToSection(index) { myRefs[index].current.scrollIntoView({ behavior: 'smooth' }); }
  const handleDownloadPDF = () => {
    html2canvas(contentRef.current).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF('landscape', 'px');

      pdf.addImage(imgData, 'PNG', 0, 0, pdf.internal.pageSize.getWidth(), pdf.internal.pageSize.getHeight());
      pdf.save('downloaded-pdf.pdf');
    })
  }

  return (
    <AuthLayout pageName="Asset Analytics">
      <section
        ref={contentRef}
        style={{
          backgroundColor: "#F5F6F8",
          padding: "1.563rem",
          paddingTop: 0,
          minHeight: "calc(100vh - 10rem)",
        }}
      >

        <TopHeader selectedDate={assetAnalyticsDate} setSelectedDate={setAssetAnalyticsDate} handleDownloadPDF={handleDownloadPDF} scrollToSection={scrollToSection} jumptoSectionOptions={['Dump Cycle Duration (Shift Averages)', 'Cycle Activity Comparison (Shift Averages)', 'Speeding Events (MTD/YTD)']} />
        {/* {loading ? <LoadingScreen /> : */}
        <main style={{ backgroundColor: "#F5F6F8" }}>
          <div ref={myRefs[0]} className="my-3 d-flex">
            <DumpCycleSection
              title="Dump Cycle Duration (Shift Averages)"
              width={"100%"}
              type={0}
              data={assetAnalyticsData1}
            />
          </div>
          <div className="d-flex row justify-content-between">
            <div ref={myRefs[1]} className="col-xl-6 mb-3 mb-xl-0 pr-4 pr-xl-0">
              <DumpCycleSection
                title="Cycle Activity Comparison (Shift Averages)"
                type={1}

                data={assetAnalyticsData1}
              />
            </div>
            <div ref={myRefs[2]} className="col-xl-6">
              <DumpCycleSection
                title="Speeding Events (MTD/YTD))"
                type={2}

                data={speedingData}
              />
            </div>
          </div>

        </main>
        {/* } */}
      </section>
    </AuthLayout>
  );

}
export default AssetAnalytics;
