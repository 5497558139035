// ChartHover.js
import { months } from "../../constData";
import { getHourMinuteDifference } from "../../helper";
const ChartHover = ({
    data,
    setIsOpen,
    coordinate,
    setShowDetails,
    fromDetail
}) => {
    // const heightPopup
    //  const maxTop = graphPosition.top + graphPosition.height - /* height of popup */
    // const maxLeft = graphPosition.left + graphPosition.width - /* width of popup */

    // Calculate adjusted positions to ensure the popup stays within the graph
    // const adjustedTop = Math.min(coordinate.y - graphPosition.top, maxTop);
    // const adjustedLeft = Math.min(coordinate.x - graphPosition.left, maxLeft);
    const handleViewDetailsClick = () => {
        setIsOpen(false);
        setShowDetails(true);
    };

    const styleDiv = {
        position: "absolute",
        top: coordinate.y + 20,
        left: fromDetail ? coordinate.x + 70 + 'px' : coordinate.x + 20 + 'px',
        background: "#FFFFFF",
        padding: "1rem",
        border: "0.063rem solid var(--unnamed-color-e1e8f4)",
        border: "0.063rem solid #E1E8F4",
        borderRadius: "0.5rem",
        opacity: "1",
        zIndex: 1000,
        width: "130px"
    };


    return (
        <div style={styleDiv}>
            <div className="mt-3">
                <div
                    className="lh-1"
                    style={{
                        fontSize: "0.9rem",
                        fontWeight: "bold",
                    }}
                >
                    {data.date}
                </div>
                <div
                    className="lh-1"
                    style={{
                        fontSize: "0.9rem",
                        fontFamily: "Open Sans",
                        fontStyle: "italic",
                    }}
                >
                    Trucks
                </div>
            </div>
            <div
                style={{
                    fontSize: "0.9rem",
                    fontFamily: "Open Sans",
                    color: "#5F6672",
                }}
            >
                <div className="col">
                    <div className="row d-flex justify-content-between lh-2">
                        <div>{"> 50 km/h"}</div>{" "}
                        <div style={{ fontWeight: "bold" }}>{data.total_50}</div>
                    </div>
                    <div className="row d-flex justify-content-between lh-2">
                        <div>{"31 - 50 (km/h)"}</div>{" "}
                        <div style={{ fontWeight: "bold" }}>{data.total_30}</div>
                    </div>
                    <div className="row d-flex justify-content-between lh-2">
                        <div>{"20 - 30 (km/h)"}</div>{" "}
                        <div style={{ fontWeight: "bold" }}>{data.total_20}</div>
                    </div>
                </div>
            </div>
            <div style={{ lineHeight: 0.5 }}>
                <a
                    style={{
                        borderBottom: "0.125rem solid #006BED",
                        color: "#006BED",
                        cursor: "pointer",
                        fontSize: "0.9rem",
                    }}
                    onClick={handleViewDetailsClick}
                >
                    view details
                </a>
            </div>
        </div>
    );
};

export default ChartHover;
