export const constData = [
  { color: "#636EBF", label: "Loading" },
  { color: "#4ECE9C", label: "Travelling to " },
  { color: "#EDB141", label: "Dumping " },
  { color: "#D064BA", label: "Tramming Empty" },
];

export const speedingEventsTypesCostData = [
  { color: "#E35656", label: ">50" },
  { color: "#FC8630", label: "30-50" },
  { color: "#FCC50C", label: "20-30" },
]

// export const speedingEventsListData = []
export const issueTypes = ["ITD", "WD", "MD", "POD", "UD", "SNO"];
// export const speedingEventsTypes = ["20-30", "30-50", "50>0"]
export const eventTypes = ["loading", "travelling", "dumping", "trammingEmpty"];

export const dumpCycleData = [
  {
    Unit: 'HT021',
    total: 3600, // Replace with your desired total value in seconds
  },
  {
    Unit: 'HT022',
    total: 4800, // Replace with your desired total value in seconds
  },
  {
    Unit: 'HT023',
    total: 7200, // Replace with your desired total value in seconds
  },
  {
    Unit: 'HT024',
    total: 3000, // Replace with your desired total value in seconds
  },
  {
    Unit: 'HT025',
    total: 5400, // Replace with your desired total value in seconds
  },
  {
    Unit: 'HT026',
    total: 4200, // Replace with your desired total value in seconds
  },
  // Add more objects with Unit and total properties as needed
];

export const cycleActivityLabelData = [
  'Loading',
  'Travelling',
  'Dumping',
  'Tramming Empty'
]

export const speedingEventsVDdetailds = [{
  assetID: 'HT022',
  speed: '>00',
  trips: 'View Trip'
},
{
  assetID: 'HT022',
  speed: '>00',
  trips: 'View Trip'
},
{
  assetID: 'HT022',
  speed: '00 - 00',
  trips: 'View Trip'
},
{
  assetID: 'HT022',
  speed: '00 - 00',
  trips: 'View Trip'
},
{
  assetID: 'HT022',
  speed: '00 - 00',
  trips: 'View Trip'
},
{
  assetID: 'HT022',
  speed: '00 - 00',
  trips: 'View Trip'
},
{
  assetID: 'HT022',
  speed: '00 - 00',
  trips: 'View Trip'
}]