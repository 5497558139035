import React, { useEffect, useState } from 'react';
import ReactEcharts from 'echarts-for-react';
import moment from 'moment';
import leftArrowIcon from './../../assets/svgs/leftArrow.svg';
import rightArrowIcon from './../../assets/svgs/rightArrow.svg';

export default function TimelineGraphChart({
  allCoordinates,
  speedUnit = 'km/h',
  setSelectedTime,
  xAxisGap,
  rows,
  setIsLastPage,
  setIsFirstPage,
}) {
  const [allTimeCoordinates, setAllTimeCoordinates] = useState([]);
  const [option, setOption] = useState({});
  const [page, setPage] = useState(1);

  const getPaginatedCoordinates = (allLocalCoordinates, _page, _rows) => {
    if (!_page) _page = 1;
    if (allLocalCoordinates?.length) {
      const skip = (_page - 1) * _rows;
      const skipTimes = allLocalCoordinates[0].timestamp + skip;
      return allLocalCoordinates.filter((c) => {
        return c.timestamp >= skipTimes && c.timestamp <= _rows + skipTimes;
      });
      // return allLocalCoordinates.slice(skip).slice(0, _rows);
    }
    return [];
  };

  const prepareOption = (allLocalCoordinates, _page, _rows) => {
    allLocalCoordinates = getPaginatedCoordinates(
      allLocalCoordinates,
      _page,
      _rows,
    );

    const localOption = {
      // Configuration for the tooltip
      tooltip: {
        trigger: 'axis',
        backgroundColor: 'white',
        borderColor: '#E1E8F4',
        padding: 0,
        borderRadius: 8,
        formatter: function (value) {
          return setSelectedTime(parseInt(value[0].axisValue)); // Format axis labels to uppercase
        },
      },
      // Configuration for the grid
      grid: {
        left: '4%',
        right: '2%',
        bottom: '20%',
        top: '10%',
        // height: '80%',
        // width: '90%',
        containLabel: true,
        backgroundColor: '#f5f5f5',
      },
      // Configuration for the X-axis
      xAxis: {
        // name: "Time",
        nameLocation: 'end',
        nameGap: 6,
        alignTicks: false,
        axisTick: {
          show: false,
        },
        nameTextStyle: {
          fontFamily: 'Open Sans',
          fontWeight: 'bold',
          fontSize: '0.625rem',
          color: ' #707070',
        },
        offset: 2,
        splitNumber: 18,
        interval: xAxisGap,
        minInterval: 60,
        maxInterval: 300,
        axisLine: {
          show: false,
        },
        axisLabel: {
          color: '#707070',
          margin: 10,
          // onZero: true,
          // interval: xAxisGap,
          // minInterval: 1,
          // maxInterval: 60,
          formatter: function (value, idx) {
            return idx === 0 ||
              allLocalCoordinates?.[allLocalCoordinates?.length - 1].timestamp -
              10 <
              value
              ? ''
              : moment(parseInt(value) * 1000).format('HH:mm'); // Format axis labels to uppercase
          },
        },
        type: 'category',
      },
      // Configuration for the Y-axis
      yAxis: {
        show: true,
        name: 'Speed',
        nameLocation: 'middle',
        boundaryGap: false,
        nameGap: 80,
        axisLabel: {
          show: true,
          formatter: function (value) {
            return value + ' ' + speedUnit; // Format axis labels to uppercase
          },
        },
        type: 'value',
        nameTextStyle: {
          fontFamily: 'Open Sans',
          fontWeight: 'bold',
          fontSize: '0.7rem',
          color: '#707070',
        },
      },
      // Configuration for the series (data)
      series: [
        {
          type: 'line',
          // showBackground: false,
          areaStyle: {
            color: '#8EB9FF',
            opacity: 0.2,
          },
          symbol: 'circle',
          symbolSize: 13,
          selectedMode: 'single',
          selectedOffset: 5,
          selectedSymbol: 'diamond',
          // selectedSymbolSize: 10,
          showSymbol: false,
          label: {
            show: false,
            fontSize: '0.625rem',
            position: 'outside',
            fontWeight: '400',
            color: '#8EB9FF',
            // formatter: (v) => getHourMinuteDifference(v),
          },
          emphasis: {
            focus: 'series',
          },
          itemStyle: {
            color: '#8EB9FF' /* : "transparent" */,
          },
        },
      ],
    };

    const timestamps = [];
    const speeds = [];
    if (allLocalCoordinates?.length) {
      allLocalCoordinates.sort((a, b) => a - b);
      for (let i = 0; i < allLocalCoordinates.length; i++) {
        if (
          allLocalCoordinates[i].timestamp &&
          (i === 0 ||
            !timestamps.includes(allLocalCoordinates[i].timestamp + ''))
        ) {
          timestamps.push(allLocalCoordinates[i].timestamp + '');
          speeds.push({ value: allLocalCoordinates[i].spd, selected: true });
        }
      }
    }

    localOption.xAxis.data = timestamps;
    // if (allTimeCoordinates?.length) {
    //   localOption.xAxis.min = (page - 1) * rows + allTimeCoordinates[0].timestamp;
    //   localOption.xAxis.max = ((page - 1) * rows) + allTimeCoordinates[allTimeCoordinates.length - 1].timestamp;
    // }
    localOption.series[0].data = speeds.map((dataPoint) => ({
      ...dataPoint,
      itemStyle: {
        color: '#447ede',
      },
    }));
    // localOption.series[0].label.formatter = speeds.map((lds, idx) => lds.value + " " + speedUnit);

    setOption(localOption);
  };

  const getTimeDifference = (_allTimeCoordinates) => {
    if (!_allTimeCoordinates?.length) {
      return 0;
    }
    return (
      _allTimeCoordinates[_allTimeCoordinates.length - 1].timestamp -
      _allTimeCoordinates[0].timestamp
    );
  };

  useEffect(() => {
    if (page * rows >= getTimeDifference(allTimeCoordinates)) {
      setIsLastPage(true);
    } else {
      setIsLastPage(false);
    }
    if (page * rows < getTimeDifference(allTimeCoordinates)) {
      setIsFirstPage(true);
    } else {
      setIsFirstPage(false);
    }
  }, [page, allTimeCoordinates]);

  const nextPage = () => {
    if (allTimeCoordinates?.length) {
      if (page * rows >= getTimeDifference(allTimeCoordinates)) {
        setIsLastPage(true);
        return;
      }
      setPage(page + 1);
      prepareOption(allTimeCoordinates, page + 1, rows);
    }
  };

  const prevPage = () => {
    if (allTimeCoordinates?.length) {
      if (page === 1) {
        return;
      }
      setPage(page - 1);
      prepareOption(allTimeCoordinates, page - 1, rows);
    }
  };

  const allTimeCoordinateHook = React.useCallback(() => {
    allCoordinates.sort((a, b) => a - b);
    const allTimeLocalCoordinate = allCoordinates;
    // let prevTime = 0;
    // let prevSpd = 0;
    // for (let i = 0; i < allCoordinates.length; i++) {
    //   const c = allCoordinates[i];
    //   if (i === 0 || c.timestamp > prevTime) {
    //     prevTime = c.timestamp;
    //     prevSpd = c.spd;
    //     if (i === 0) {
    //       allTimeLocalCoordinate.push(c);
    //     } else {
    //       allTimeLocalCoordinate.push({
    //         timestamp: prevTime + 1,
    //         spd: prevSpd
    //       });
    //     }
    //   } else {
    //     allTimeLocalCoordinate.push(c);
    //     prevTime++;
    //   }
    // }

    setAllTimeCoordinates(allTimeLocalCoordinate);
  }, [allCoordinates]);

  useEffect(() => {
    const filterUniqueObjectsByKey = (list, key) => {
      return Object.values(
        list.reduce((acc, obj) => {
          acc[obj[key]] = obj;
          return acc;
        }, {}),
      );
    };
    prepareOption(
      filterUniqueObjectsByKey(allTimeCoordinates, 'timestamp'),
      1,
      rows,
    );

    setPage(1);
    // prepareOption(allCoordinates, 1, rows);
    allTimeCoordinateHook();
  }, [xAxisGap, allTimeCoordinates, allTimeCoordinateHook]);

  useEffect(() => {
    const canvasDocument = document.getElementById('mychart');
    if (canvasDocument) {
      const css_obj = getComputedStyle(canvasDocument.children[0]);
      const canvasWidth = css_obj.width;
      const canvasHeight = css_obj.height;

      const prevPageElement = document.getElementById('prevPageId');
      const nextPageElement = document.getElementById('nextPageId');
      prevPageElement.style.marginLeft = '5..2%';
      nextPageElement.style.marginRight = '0.7%';

      const paginationParentDiv = document.getElementById(
        'paginationParentDivId',
      );
      paginationParentDiv.style.marginTop = '14rem';
    }
  }, [window.innerWidth, window.innerHeight]);

  const handlePointClick = (params) => {
    setSelectedTime(params.name);
  };

  return (
    <div
      className="d-flex flex-column chartclass"
      style={{ height: '100%', width: '100%', position: 'relative', }}
    >
      <div className="" id="mychart">
        <ReactEcharts
          option={option}
        // onEvents={{ mousemove: handlePointClick }}
        />
      </div>

      <div
        className="d-flex justify-content-between"
        id="paginationParentDivId"
        style={{
          bottom: '1',
          // backgroundColor: 'tr',
          borderRadius: '0.3rem',
          position: 'absolute',
          left: 0,
          right: 0,
          top: 58,
          marginLeft: 'auto',
          marginRight: 'auto',
          paddingLeft: '10px',
          paddingRight: '10px',
          marginTop: '12%',
        }}
      >
        <div
          className="align-items-center justify-content-center cursor-pointer"
          onClick={() => prevPage()}
          id="prevPageId"
          style={{

            marginRight: 'auto',
            marginLeft: '5.1%',
            marginBottom: '24px',
          }}
        >
          <img
            src={leftArrowIcon}
            alt="Prev Page"
            style={{
              width: '1.3rem',
              height: '1.3rem',
              cursor: "pointer",
              opacity: page === 1 ? 0.3 : 0.5,
            }}
          />
        </div>
        <div
          className="align-items-center justify-content-center cursor-pointer"
          onClick={() => nextPage()}
          id="nextPageId"
          style={{
            marginLeft: 'auto',
            marginRight: '0.8%',
            marginBottom: '24px',
          }}
        >
          <img
            src={rightArrowIcon}
            alt="Next Page"
            style={{
              width: '1.3rem',
              cursor: "pointer",
              height: '1.3rem',
              opacity:
                page * rows >= getTimeDifference(allTimeCoordinates)
                  ? 0.3
                  : 0.5,
            }}
          />
        </div>
      </div>
    </div>
  );
}
