import React, { useState } from "react";
import DumpCycleDurationSectionGraph from "../components/DumpCycleDurationSectionGraph";
import DumpCycleDurationSection from "../components/DumpCycleDurationSection";
import { dumpCycleChartHover, getHourMinuteDifference } from "../helper";

const DumpCycleDuration = ({ data }) => {
  // const [choice, setChoice] = useState(0);

  const targetValue = '3000000'

  const [selected, setSelected] = useState(0);

  return (
    <div className="row d-flex">
      <div className="col-xl-5 mb-3 mb-xl-0 pr-4" >
        <DumpCycleDurationSection
          data={data?.trucks}
          // targetValue={targetValue}
          // test={test}
          selected={selected}
          setSelected={setSelected}
        />
      </div>
      <div
        className="col-xl-7"
      >
        <div style={{
          fontSize: '0.75rem',
          color: '#1A1A1A',
          paddingLeft: '2.1rem'
        }} >{data?.trucks[selected]?.truckName} (Actual Cycle Duration)</div>
        <DumpCycleDurationSectionGraph
          data={data?.trucks[selected]}
          // graphObject={graphObject}
          mouse='hover'
        />
      </div>
    </div>
  );
};

export default DumpCycleDuration;
