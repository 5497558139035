export default function NavigationOption({ option, isActive, onClick }) {
    return (
        <div
            onClick={onClick}
            style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: isActive ? "#E2ECFC" : "#FFFFFF",
                boxShadow: "none",
                width: "10rem",
                height: "3rem",
                cursor: "pointer",
                font: "normal normal 200 17px/24px Open Sans",
                fontSize: "1rem",
                margin: "0 1rem",
                overflow: "hidden",
                border: '1px solid ' + (isActive ? "#A0B8E3" : "#C4D8F0"),
                borderRadius: 4
            }}
        >
            <img
                className="navigation-option-img"
                src={option.icon}
                alt={`${option.displayName} icon`}
                style={{ fill: (isActive ? "black" : "transparent") }}
            />
            <span className="mx-1" style={{ fontWeight: isActive ? "700" : "600" }}>{option.displayName}</span>
        </div>
    );
};