import axios from 'axios';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import emailIcon from "../../../../../assets/svgs/email.svg";
import LoadingScreen from '../../../../../components/LoadingScreen';
import { getDurationOfCurrentDayInHHMMSS, getDurationOfCurrentDayInSeconds, secondsToHhMm } from '../../../../../utils/helper';
import { downloadReportFromServer } from '../../utils/helper';
import DownloadMenu from '../components/DownloadMenu';
import ThreeDotMenu from '../components/ThreeDotMenu';
import DownloadIcon from '../components/download.svg';
import EyeIcon from '../components/eye.svg';
import Report from './components/Report';
import Timeline from './components/Timeline';
import './index.css';

export const Table = ({ tripHistoryData, loading, categoryType, date, totalLength, filteredDataLength, startTime, endTime, setMinIdleTime, minIdleTime }) => {
    const [data, setData] = useState(tripHistoryData);
    const [isMapModalOpen, setIsMapModalOpen] = useState(false);
    const tableHeaders = ['Activity', 'Timestamp', 'Duration', 'Location (Lat,Long)', 'Distance', 'Idle Time', 'Max Speed',]
    const source = useRef(axios.CancelToken.source());
    const [selectedRowData, setSelectedRowData] = useState(null);
    const tableHeadingRef = useRef(null);
    const [menuIdentifier, setMenuIdentifier] = useState({ asset_ID: '', activeIndex: '' })
    const [downloadMenuIdentifier, setDownloadMenuIdentifier] = useState({ asset_ID: '', activeIndex: '' })
    const [isReportModalOpen, setIsReportModalOpen] = useState(false)
    const [apiDataLoading, setApiDataLoading] = useState(false);
    const [selectedRowForMenu, setSelectedRowForMenu] = useState('')
    const tripRowReportOptions = [
        { displayName: 'View Report', icon: EyeIcon },
        { displayName: 'Download Report (CSV)', icon: DownloadIcon },
        { displayName: 'Email Report (CSV)', icon: emailIcon },
    ];


    useEffect(() => {
        setData(tripHistoryData)
    }, [tripHistoryData])

    const handleRowClick = ({ asset, index }) => {
        setSelectedRowData({ ...asset, activeIndex: index, date });
        setIsMapModalOpen(true)
    };

    useEffect(() => {
        setSelectedRowData(null)
        setIsMapModalOpen(false)
        // setfilteredTimeline([])

    }, [date])

    const menuSelectHandler = async (optionNo, asset_ID, trip_id, tripSubName) => {
        if (optionNo == 0) {
            setIsReportModalOpen(true)
        } else if (optionNo == 1) {
            setApiDataLoading(true)
            source?.current?.cancel()
            source.current = axios.CancelToken.source();
            await downloadReportFromServer(
                date,
                undefined,
                undefined,
                trip_id,
                source.current.token,
                [asset_ID],
                `ASSET ID ${tripSubName} REPORT`,
                "TRIP",
                false
            )
            setApiDataLoading(false)
        } else if (optionNo == 2) {
            setApiDataLoading(true);
            source?.current?.cancel();
            source.current = axios.CancelToken.source();
            await downloadReportFromServer(
                date,
                undefined,
                undefined,
                trip_id,
                source.current.token,
                [asset_ID],
                `ASSET ID ${tripSubName} REPORT`,
                'TRIP',
                true,
            );
            setApiDataLoading(false);
        }
    }

    const downloadMenuSelectHandler = async (optionNo, asset_ID) => {
        if (optionNo == 0) {
            setApiDataLoading(true)
            source?.current?.cancel()
            source.current = axios.CancelToken.source();
            await downloadReportFromServer(date, startTime, endTime, undefined, source.current.token, [asset_ID], `ASSET ID TRIPS REPORT`, "ASSET", false);
            setApiDataLoading(false)
        } else if (optionNo == 1) {
            setApiDataLoading(true)
            source?.current?.cancel()
            source.current = axios.CancelToken.source();
            await downloadReportFromServer(date, startTime, endTime, undefined, source.current.token, [asset_ID], `ASSET ID TRIPS REPORT`, "ASSET", true);
            setApiDataLoading(false)
        }
    }

    const assetRowReportOptions = () => {
        return [
            { displayName: 'Download Report (CSV)', icon: DownloadIcon },
            { displayName: 'Email Report (CSV)', icon: emailIcon },
        ];
    }

    useEffect(() => {
        console.log(selectedRowForMenu);
    }, [selectedRowForMenu])

    const calculateAssetInformation = (asset, index) => {
        let totalTrips = 0;
        let totalParks = 0;
        let totalTripTime = 0;
        let totalParkingTime = 0;
        let totalIdleTime = 0;
        let events = 0;
        let speeding_events = 0;
        let idle_events = 0;
        let maxSpeed = 0
        asset?.activity?.forEach((activity) => {
            { activity.activity_type === "Trip" ? totalTrips += 1 : totalParks += 1; }
            { activity.activity_type === "Trip" ? totalTripTime += getDurationOfCurrentDayInSeconds(activity.endTime, activity.startTime, date) : totalParkingTime += getDurationOfCurrentDayInSeconds(activity.endTime, activity.startTime, date); }
            if (activity.activity_idle_time > 0 && activity.activity_type === "Trip") {
                idle_events += 1;
                totalIdleTime += activity?.activity_idle_time;
            }
            if (activity?.maxSpeed > maxSpeed) {
                maxSpeed = activity?.maxSpeed;
            }
            if (activity?.maxSpeed >= 30 && activity.activity_type === "Trip") {
                speeding_events += 1;
            }
            events = idle_events + speeding_events;
        });
        return {
            totalTrips,
            totalParks,
            totalTripTime,
            totalParkingTime,
            totalIdleTime,
            events,
            speeding_events,
            idle_events,
            maxSpeed,
        }
    }

    const asssetRowComponent = ({
        asset, totalTrips,
        totalParks,
        totalTripTime,
        totalParkingTime,
        totalIdleTime,
        events,
        speeding_events,
        idle_events,
        maxSpeed, }) => {
        return <div>
            {asset?.asset_ID}
            <span style={{ marginRight: "1.75rem", marginLeft: "0.625rem" }}></span>Trips:{' '}{totalTrips}
            <span style={{ color: "#707070", fontWeight: 400 }}>  ({secondsToHhMm(totalTripTime)})</span>
            <span style={{ marginRight: "1.75rem", marginLeft: "1.75rem" }}>|</span>
            Parked:
            <span style={{ color: "#707070", }}>{' '}{' '}</span>
            {totalParks} {' '}
            <span style={{ color: "#707070", fontWeight: 400 }}>({secondsToHhMm(totalParkingTime)}){' '}</span>
            <span style={{ color: "#707070", marginRight: "1.75rem", marginLeft: "1.75rem" }}>|</span>
            Alerts: <span style={{ color: "#000", fontWeight: 400 }}>Idle times</span> : {idle_events} <span style={{ color: "#707070", fontWeight: 400 }}>({secondsToHhMm(totalIdleTime)})</span>
            <span style={{ color: "#707070", marginRight: "1.75rem", marginLeft: "1.75rem" }}>-</span>
            <span style={{ color: "#000", fontWeight: 400 }}>Speeding</span> : {speeding_events} <span style={{ color: "#707070", fontWeight: 400 }}>(Max:{maxSpeed}km/h)</span>
        </div>
    }

    const parkRowComponent = ({ asset, trip, tripIndex }) => {
        return <tr
            style={{
                height: "3.8rem",
                font: "normal normal normal 1.075rem/1.125rem Open Sans",
                backgroundColor: tripIndex === selectedRowData?.activeIndex && asset?.asset_ID === selectedRowData?.asset_ID ? '#EAF2FE' : 'transparent',
                borderTop: tripIndex === selectedRowData?.activeIndex && asset?.asset_ID === selectedRowData?.asset_ID ? "1px solid #3183F5" : " 1px solid #EBEBEB ",
                borderBottom: tripIndex === selectedRowData?.activeIndex && asset?.asset_ID === selectedRowData?.asset_ID ? "1px solid #3183F5" : " 0px solid #EBEBEB ",
            }}
            onClick={(e) => {
                handleRowClick({ asset: asset, index: tripIndex });
            }}
        >
            <td></td>
            <td></td>
            <td><b>Parked</b></td>
            <td>
                <div className=''>
                    {(moment(trip.startTime * 1000).isSame(moment(trip.endTime * 1000), 'day')
                        && moment(trip.startTime * 1000).format('YYYY-MM-DD') == date
                        && moment(trip.endTime * 1000).format('YYYY-MM-DD') == date) ? (
                        ` ${moment(trip.startTime * 1000).format('hh:mm a')}- ${moment(trip.endTime * 1000).format('hh:mm a')} `
                    ) : (
                        <div>   {moment(trip.startTime * 1000).format('DD/MM/YY hh:mm a')} -<br /> {moment(trip.endTime * 1000).format('DD/MM/YY hh:mm a')}</div>
                    )}
                </div>
            </td>
            <td> <div className=''>{getDurationOfCurrentDayInHHMMSS(trip.endTime, trip.startTime, date)} hrs</div></td>
            <td style={{ color: "#707070", }}>
                <div className='d-flex'>
                    <div className='mr-1'>
                        <span style={{ fontWeight: 600, color: "#000" }}>
                            At:
                        </span>
                    </div>
                    <div style={{ marginLeft: "2rem" }}>
                        {trip?.lastLocation?.latitude}, {trip.lastLocation?.longitude}
                    </div>
                </div>
            </td>
            <td><div className=''> - </div></td>
            <td><div className=''> - </div></td>
            <td><div className=''> - </div></td>
        </tr>
    }

    const tripRowComponent = ({ tripIndex, asset, trip, events, index }) => {
        return <tr
            style={{
                height: "3.8rem",
                font: "normal normal normal 1.075rem/1.125rem Open Sans",
                backgroundColor: (tripIndex === menuIdentifier.activeIndex && asset?.asset_ID === menuIdentifier.asset_ID) || (tripIndex === selectedRowData?.activeIndex && asset?.asset_ID === selectedRowData?.asset_ID) ? '#EAF2FE' : 'transparent',
                borderTop: (tripIndex === menuIdentifier.activeIndex && asset?.asset_ID === menuIdentifier.asset_ID) || (tripIndex === selectedRowData?.activeIndex && asset?.asset_ID === selectedRowData?.asset_ID) ? "1px solid #3183F5" : "1px solid #EBEBEB",
                borderBottom: (tripIndex === menuIdentifier.activeIndex && asset?.asset_ID === menuIdentifier.asset_ID) || (tripIndex === selectedRowData?.activeIndex && asset?.asset_ID === selectedRowData?.asset_ID) ? "1px solid #3183F5" : "0px solid #EBEBEB",
            }}
            onClick={() => {
                handleRowClick({ asset: asset, index: tripIndex, events: events })
            }}
        >
            <div>
                {trip.activity_idle_time > 0 && (
                    <div className="legend-box1" style={{ left: 0, position: "absolute", background: "#ea9336", width: '0.84rem' }} />
                )}
                {trip?.maxSpeed >= 30 && trip?.maxSpeed < 40 ? (
                    <div className=" legend-box1" style={{ position: "absolute", background: "#73bf63", width: '0.84rem', left: trip.activity_idle_time > 0 ? '1.2rem' : 0 }} />
                ) : trip?.maxSpeed >= 40 && trip?.maxSpeed < 55 ? (
                    <div className="legend-box1" style={{ position: "absolute", background: "#181cf5", width: '0.84rem', left: trip.activity_idle_time > 0 ? '1.2rem' : 0 }} />
                ) : (
                    trip?.maxSpeed > 55 && <div className="legend-box1" style={{ position: "absolute", background: "#e23123", width: '0.84rem', left: trip.activity_idle_time > 0 ? '1.2rem' : 0 }} />
                )}
            </div>
            <td></td>
            <td
                style={{
                    font: "normal normal bold 1.075rem/1.125rem Open Sans",
                }}>{trip.activity_id} </td>

            <td>
                <div className=''>
                    {(moment(trip.startTime * 1000).isSame(moment(trip.endTime * 1000), 'day') && moment(trip.startTime * 1000).format('YYYY-MM-DD') == date && moment(trip.endTime * 1000).format('YYYY-MM-DD') == date) ? (` ${moment(trip.startTime * 1000).format('hh:mm a')}- ${moment(trip.endTime * 1000).format('hh:mm a')} `
                    ) : (
                        <div>   {moment(trip.startTime * 1000).format('DD/MM/YY hh:mm a')} -<br /> {moment(trip.endTime * 1000).format('DD/MM/YY hh:mm a')}</div>
                    )}
                </div>
            </td>

            <td> <div className=''> {getDurationOfCurrentDayInHHMMSS(trip.endTime, trip.startTime, date)} hrs</div></td>

            <td style={{ color: "#707070", }}>
                <div className='my-2'>
                    <div className='d-flex'>
                        <div className='mr-1'>
                            <span style={{ fontWeight: 600, color: "#000" }}>
                                From:
                                <br></br>
                                To:
                            </span>
                        </div>
                        <div style={{ marginLeft: "0.5rem" }}>
                            {trip?.firstLocation?.latitude}, {trip.firstLocation?.longitude}
                            <br></br>
                            {trip?.lastLocation?.latitude}, {trip?.lastLocation?.longitude}
                        </div>
                    </div>
                </div>
            </td>
            <td> <div className=''>{(trip?.lastLocation?.odo - trip?.firstLocation?.odo)?.toFixed(2)} km</div></td>
            <td> <div className=''>{secondsToHhMm(trip.activity_idle_time)} </div></td>
            <td> <div className=''>  {trip.maxSpeed} km/h </div></td>
            <td><ThreeDotMenu
                uniqueName={menuIdentifier.asset_ID + 'menu' + menuIdentifier.activeIndex}
                menuOptions={tripRowReportOptions}
                menuVisible={asset.asset_ID == menuIdentifier.asset_ID && tripIndex == menuIdentifier.activeIndex}
                setMenuVisible={(value) => value ? setMenuIdentifier({ asset_ID: asset.asset_ID, activeIndex: tripIndex }) : setMenuIdentifier('')}
                onMenuSelect={(optionNo) => { menuSelectHandler(optionNo, asset.asset_ID, trip._id, trip?.activity_id); setSelectedRowForMenu({ ...asset, trip, activeIndex: index, trip_id: trip._id, tripIndex, tripNo: trip.activity_id }) }} /></td>
        </tr>
    }
    return (
        <>
            {(loading || apiDataLoading) && <LoadingScreen />}
            <div>
                <table className='trips-custom-table'>
                    <thead>
                        <tr style={{ borderLeft: "1.375", }}>
                            <td></td>
                            <th ref={tableHeadingRef}>Asset</th>
                            {tableHeaders.map((header) => <th>{header}</th>)}
                            <th></th>
                        </tr>
                    </thead>
                </table>
                <div
                    className='scroll-div'
                    style={{
                        position: "relative",
                        maxHeight: "calc(100vh - 18.5rem)",
                        overflowY: "scroll",
                    }}
                >

                    <table className='trips-custom-table mt-0'>
                        <tbody >
                            {Object.entries(data).map(([key, value], index) => {
                                if (true) {
                                    return (
                                        <React.Fragment key={key + index}>
                                            {/* <tr align="left" valign="top">
                                                <td colSpan="10">
                                                    <strong style={{ fontSize: "1.075rem" }}>{key}</strong>
                                                </td>
                                            </tr> */}
                                            {Object.entries(value).map(([asset, activities, index]) => (
                                                <React.Fragment key={asset + index}>
                                                    <tr align="left" valign="top">
                                                        <td></td>
                                                        <td colSpan="9">
                                                            <i style={{ fontSize: "1.075rem", color: "#707070" }}>{asset}</i>
                                                        </td>
                                                    </tr>
                                                    {activities?.map((asset, index) => {
                                                        const { totalTrips, totalParks, totalTripTime, totalParkingTime, totalIdleTime, events, speeding_events, idle_events, maxSpeed, } = calculateAssetInformation(asset, index)
                                                        return (
                                                            <React.Fragment key={index}>
                                                                {!categoryType?.length === 1 && categoryType?.includes('Truck') ? (<tr style={{ font: "italic normal normal 0.875rem / 1.5rem Open Sans", color: "#707070", }}>
                                                                    <td></td>
                                                                    <td>
                                                                        <div> {categoryType}</div>
                                                                    </td>
                                                                </tr>) : null}
                                                                <tr
                                                                    style={{ backgroundColor: "#FAFAFA" }}
                                                                    className='w-100'
                                                                >
                                                                    {date == moment().format('YYYY-MM-DD') &&
                                                                        <td
                                                                            style={{
                                                                                backgroundColor:
                                                                                    asset?.activity_status === "Active" || asset?.activity_status === "Idling"
                                                                                        ? "#CFFD69"
                                                                                        : asset?.activity_status === "Disconnected"
                                                                                            ? "#ED5151"
                                                                                            : asset?.activity_status === "Parked"
                                                                                                ? "#BEB6B6"
                                                                                                : "",
                                                                            }}>
                                                                            <div className="w-50">
                                                                            </div>
                                                                        </td>
                                                                    }
                                                                    <td colSpan="10" className='pl-2 py-1 w-100' style={{ font: "normal normal 600 1.075rem/1.5rem Open Sans", }}>
                                                                        <div className='d-flex align-items-center justify-content-between mr-5 pr-4'>
                                                                            {asssetRowComponent({
                                                                                asset,
                                                                                totalTrips,
                                                                                totalParks,
                                                                                totalTripTime,
                                                                                totalParkingTime,
                                                                                totalIdleTime,
                                                                                events,
                                                                                speeding_events,
                                                                                idle_events,
                                                                                maxSpeed,
                                                                            })}
                                                                            <div className='mr-2'>
                                                                                <DownloadMenu
                                                                                    uniqueName={downloadMenuIdentifier.asset_ID + 'download' + downloadMenuIdentifier.activeIndex}
                                                                                    menuOptions={assetRowReportOptions()}
                                                                                    menuVisible={asset.asset_ID == downloadMenuIdentifier.asset_ID && index == downloadMenuIdentifier.activeIndex}
                                                                                    setMenuVisible={(value) => value ? setDownloadMenuIdentifier({ asset_ID: asset.asset_ID, activeIndex: index }) : setDownloadMenuIdentifier('')}
                                                                                    onMenuSelect={(optionNo) => {
                                                                                        setSelectedRowData({ ...asset })
                                                                                        downloadMenuSelectHandler(optionNo, asset.asset_ID);
                                                                                    }} />
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                                {asset?.activity?.map((trip, tripIndex) => {
                                                                    return <React.Fragment key={`${index}-${tripIndex}`} >
                                                                        {trip.activity_type === "Trip" && tripRowComponent({ tripIndex, asset, trip, events, index })}
                                                                        {trip.activity_type === "Park" && parkRowComponent({ asset, trip, tripIndex })}
                                                                    </React.Fragment>
                                                                })}
                                                            </React.Fragment>
                                                        );
                                                    })}
                                                </React.Fragment>
                                            ))}
                                        </React.Fragment>
                                    );
                                }
                            })}
                            <tr style={{ height: "40px" }}></tr>
                        </tbody>
                    </table>
                </div >
                {isMapModalOpen &&
                    <Timeline
                        minIdleTime={minIdleTime}
                        setMinIdleTime={setMinIdleTime}
                        date={date}
                        selectedRowData={selectedRowData}
                        setSelectedRowData={setSelectedRowData}
                        tableHeadingRef={tableHeadingRef}
                        isMapModalOpen={isMapModalOpen}
                        setIsMapModalOpen={setIsMapModalOpen}
                    />}

                {isReportModalOpen && <Report setSelectedRowForMenu={setSelectedRowForMenu} date={date} setIsReportModalOpen={setIsReportModalOpen} asset_ID={selectedRowForMenu.asset_ID} tripStartTime={selectedRowForMenu.trip.startTime} tripEndTime={selectedRowForMenu.trip.endTime} trip_id={selectedRowForMenu.trip._id} index={selectedRowForMenu.index} />}
                {tripHistoryData?.length == 0 && <div className='text-center text-muted '>No Record Found</div>}
                <div style={{ position: 'relative', bottom: 0, left: 0 }}>
                    <div className="table-footer pt-2">
                        Displaying ({filteredDataLength}/{totalLength ?? "0"}) assets
                    </div>
                </div>
            </div >

        </>
    );
}
