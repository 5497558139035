// ChartHover.js
import { months } from "../../constData";
import { getHourMinuteDifference } from "../../helper";
const ChartHover = ({
    month,
    year,
    data,
    setIsOpen,
    coordinate,
    setShowDetails,
}) => {
    // const heightPopup
    //  const maxTop = graphPosition.top + graphPosition.height - /* height of popup */
    // const maxLeft = graphPosition.left + graphPosition.width - /* width of popup */

    // Calculate adjusted positions to ensure the popup stays within the graph
    // const adjustedTop = Math.min(coordinate.y - graphPosition.top, maxTop);
    // const adjustedLeft = Math.min(coordinate.x - graphPosition.left, maxLeft);
    const handleViewDetailsClick = () => {
        setIsOpen(false);
        setShowDetails(true);
    };

    const styleDiv = {
        position: "absolute",
        marginTop: '-16rem',
        left: coordinate.x / 2 + 'px',
        background: "#FFFFFF",
        padding: "1rem",
        border: "0.063rem solid var(--unnamed-color-e1e8f4)",
        border: "0.063rem solid #E1E8F4",
        borderRadius: "0.5rem",
        opacity: "1",
        zIndex: 1000,
    };


    return (
        <div style={styleDiv}>
            <div className="mt-3">
                <div
                    className="lh-1"
                    style={{
                        fontSize: "0.75rem",
                        fontWeight: "bold",
                    }}
                >
                    {data.date} {data.month}
                </div>
                <div
                    className="lh-1"
                    style={{
                        fontSize: "0.625rem",
                        fontFamily: "Open Sans",
                        fontStyle: "italic",
                    }}
                >
                    Trucks
                </div>
            </div>
            <div style={{ fontWeight: "0.8125em", fontSize: "0.75rem" }}>
                Delays -{" "}
                <span style={{ fontWeight: "bold" }}>{getHourMinuteDifference(0, data.total * 1000)} h</span>{" "}
            </div>
            <div
                style={{
                    fontSize: "0.625rem",
                    fontFamily: "Open Sans",
                    color: "#5F6672",
                }}
            >
                <div className="col">
                    <div className="row d-flex justify-content-between lh-2">
                        <div>Idle Time Delays (ITD)</div>{" "}
                        <div style={{ fontWeight: "bold" }}>{getHourMinuteDifference(0, data.delays.ITD * 1000)} h</div>
                    </div>
                    <div className="row d-flex justify-content-between lh-2">
                        <div>Weather Delay (WD, D1) </div>{" "}
                        <div style={{ fontWeight: "bold" }}>{getHourMinuteDifference(0, data.delays.WD * 1000)} h</div>
                    </div>
                    <div className="row d-flex justify-content-between lh-2">
                        <div>Maintenance Downtime (MD)</div>{" "}
                        <div style={{ fontWeight: "bold" }}>{getHourMinuteDifference(0, data.delays.MD * 1000)} h</div>
                    </div>
                    <div className="row d-flex justify-content-between lh-2">
                        <div>Planned Operational Delays (POD)</div>{" "}
                        <div style={{ fontWeight: "bold" }}>{getHourMinuteDifference(0, data.delays.POD * 1000)} h</div>
                    </div>
                    <div className="row d-flex justify-content-between lh-2">
                        <div>Unplanned Delays (UD)</div>{" "}
                        <div style={{ fontWeight: "bold" }}>{getHourMinuteDifference(0, data.delays.UD * 1000)} h</div>
                    </div>
                    <div className="row d-flex justify-content-between lh-2">
                        <div className="mr-4">
                            {" "}
                            Scheduled Non-Operating Time (SNO)
                        </div>{" "}
                        <div style={{ fontWeight: "bold" }}>{getHourMinuteDifference(0, data.delays.SNO * 1000)} h</div>
                    </div>
                </div>
            </div>
            <div style={{ lineHeight: 0.5 }}>
                <a
                    style={{
                        borderBottom: "0.125rem solid #006BED",
                        color: "#006BED",
                        cursor: "pointer",
                        fontSize: "0.625rem",
                    }}
                    onClick={handleViewDetailsClick}
                >
                    view details
                </a>
            </div>
        </div>
    );
};

export default ChartHover;
