import { useState, useEffect } from "react";
import ReactEcharts from "echarts-for-react";
import { calculateAverageCycleDurationDumpCycle, getHourMinuteDifference } from "../helper";
import { cycleActivityLabelData, eventTypes } from "../constData";
export default function ShiftTotalDelayCompareSectionBarGraph({ data, selected }) {
  let hoverLabel = cycleActivityLabelData[selected]

  const option = {
    // Configuration for the tooltip
    tooltip: {
      backgroundColor: "white",
      borderColor: "#E1E8F4",
      borderRadius: 8,
      formatter: (x) => `
      <div class="p-3" style="line-height:1.6; font-size: 0.7rem;">
        <div style="font-weight: 600;">
          ${x?.name}
        </div>
        <div style=" font-size: 0.8rem;font-style: italic;
          font-weight: 600; color: #5F6672;">
          ${hoverLabel}
        </div>
        <div class="mt-2" style="font-size: 12px; font-weight: 900; color: black;">
          ${getHourMinuteDifference(0, 1000 * x?.data) != 'NaN:NaN' ? getHourMinuteDifference(0, 1000 * x?.data) : '00:00'}
        </div>
      </div>
    `,
    },
    // Configuration for the grid
    grid: {
      left: "35px",
      right: "0%",
      // bottom: "40px",
      height: "80%",
      // width: '90%',
      // paddinTop: 0,
      containLabel: true,
      backgroundColor: "#f5f5f5", // Background color for the grid
    },
    // Configuration for the X-axis
    xAxis: {
      axisLabel: {
        color: "#576B8D",
        margin: 20,
        fontSize: '0.75rem'
      },
      axisTick: {
        show: false,
      },
      data: data?.trucks?.length ? data?.trucks?.map(truck => truck.truckName) : ['', '', '', '']
    },
    // Configuration for the Y-axis
    yAxis: {
      name: "Duration (h)",
      nameLocation: "middle",
      nameGap: 55,
      axisLabel: {
        show: true,
        formatter: (v) => getHourMinuteDifference(0, v * 1000), // Add the label for the Y-axis here
      },
      nameTextStyle: {
        fontFamily: "Open Sans",
        fontWeight: "bold",
        fontSize: "12px",
        marginBottom: "40px",
        color: "#728BB5",
      },
    },
    // Configuration for the series (data)
    series: [
      {
        type: "bar",
        data: data?.trucks?.length ? data?.trucks?.map(truck => truck.breakdownOfAverageDumpCycleDuration[eventTypes[selected]]) : [0, 0, 0, 0,],
        // showBackground: true,
        barMaxWidth: "35rem",
        label: {
          show: true,
          fontSize: "10px",
          position: "outside",
          fontWeight: "600",
          color: "#576B8D",
          formatter: (v) => getHourMinuteDifference(0, 1000 * v?.data)
        },
        itemStyle: {
          color: "#626DBD",
        },
      },
    ],
  };

  return (
    <div style={{ height: "100%", width: "100%" }}>
      <ReactEcharts option={option} />
    </div>
  );
}
