import React, { useState } from "react";
import { Form } from "react-bootstrap";
import DayNightToggleButton from "../../../components/DayNightToggleButton";
import HeaderFormSelect from "../../../components/HeaderFormSelect";
import { color } from "echarts";
import '../../style.css'

export default function ShiftTotalHeader({
    choice,
    equipmentType,
    setChoice,
    title,
    setEquipmentType,
    onMenuSelect,
    menuOptions
}) {
    const [menuVisible, setMenuVisible] = useState(false)
    return (
        <div className="row">
            <div
                className="col-6"
                style={{ fontSize: "0.75rem", color: "#1A1A1A" }}
            >
                {title}
            </div>
            <div className="col-6 d-flex align-items-center justify-content-end ">
                <div className='d-flex align-items-center justify-content-center' style={{
                    borderRight: '1px solid #E1E8F4',
                    height: '2rem'
                }}>
                    <DayNightToggleButton
                        choice={!choice ? "Day" : "Night"}
                        onToggle={(v) => setChoice(v == "Day" ? 0 : 1)}
                    />
                </div>
                <div className="ml-2">
                    <HeaderFormSelect equipmentType={equipmentType} setEquipmentType={setEquipmentType} />
                </div>
                <div style={{ position: 'relative' }}>
                    <div
                        className={`px-3 py-1 d-flex align-items-center justify-content-center ${menuVisible ? 'rounded' : ''
                            }`}
                        onClick={() => setMenuVisible(!menuVisible)}
                        style={{ cursor: 'pointer', backgroundColor: menuVisible ? "#E2ECFC" : "transparent" }}
                    >
                        <ul className="icons">
                            <li></li>
                            <li></li>
                            <li></li>
                        </ul>
                    </div>
                    {menuVisible && (
                        <div
                            className="py-2 d-flex flex-column mt-1"
                            style={{
                                right: '0',
                                backgroundColor: 'white',
                                position: 'absolute',
                                minWidth: 'max-content',
                                boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.1)',
                                border: '1px solid #E1E8F4',
                                borderRadius: '0.5rem',
                                cursor: 'pointer',
                                zIndex: "2000"
                            }}
                        >
                            {menuOptions?.map((option, idx) => <div
                                className="hover-blue pl-3 pr-5"
                                style={{
                                    textAlign: 'left',
                                    fontWeight: '600',
                                    fontSize: '1rem',
                                }}
                                onClick={() => {
                                    setMenuVisible(false);
                                    onMenuSelect(idx);
                                }}
                            >
                                {option}
                            </div>)}
                        </div>
                    )}
                </div>

            </div>
        </div >
    );
}
