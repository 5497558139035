import ReactDOM from 'react-dom';


export const TitlePopup = (coordinate, { data }) => {
    const div = document.createElement('div');
    const popup = (
        <>
            <div
                key={`popup-${coordinate.lat}-${coordinate.lng}`}
            >
                <div  className='flyout-asset'>
                    {JSON.stringify(data?.title)}
                </div>
            </div>
            <div className="popup-tip-container">

            </div>
        </>
    );

    ReactDOM.render(popup, div);
    return div;
};