import React, { useState, useEffect } from "react";
import ShiftTotalDelaysRow from "../components/ShiftTotalDelaysRow";
import { getHourMinuteDifference } from "../../helper";
import PieChartSection from "../components/PieChartSection";
import { constData } from "../../constData";
import { issueTypes } from "../../constData";

export default function ShiftTotalDelaySection({ data }) {
    const [selected, setSelected] = useState(0);

    return (
        <div className="d-flex justify-content-between pl-0" >
            <div
                className="d-flex flex-column col-lg-6 p-0"
                style={{
                    borderRight: "1px solid #E1E8F4",
                }}
            >
                <div className="d-flex justify-content-between pl-0">
                    <div
                        className="col-7 p-0"
                        style={{
                            color: "#6A7C9A",
                            fontSize: "0.8rem",
                        }}
                    >
                        Asset ID
                    </div>
                    <div
                        className="col-5 pl-4"
                        style={{
                            color: "#6A7C9A",

                            fontSize: "0.8rem",
                        }}
                    >
                        Total Delays (h)
                    </div>
                </div>

                {/* <div> */}
                    {data?.map((truck, i) => (
                        <ShiftTotalDelaysRow
                            data={truck}
                            color={"#797FCC"}
                            key={i}
                            targetValue={7200}
                            onSelected={() => setSelected(i)}
                            selected={selected === i}
                        />
                    ))}
                {/* </div> */}
            </div>
            <div className="col-lg-6 p-0 ml-4">
                <PieChartSection
                    listDetails={constData?.map((row, j) => ({
                        ...row,
                        data: data?.[selected]?.delays?.[issueTypes[j]]
                            ? getHourMinuteDifference(
                                  0,
                                  1000 *
                                      data?.[selected]?.delays?.[issueTypes[j]],
                                  "h"
                              )
                            : "00:00 h",
                    }))}
                    data={constData?.map((row, j) => ({
                        ...row,
                        value: data?.[selected]?.delays?.[issueTypes[j]]
                            ? data?.[selected]?.delays?.[issueTypes[j]]
                            : 0,
                    }))}
                    title={data?.[selected]?.Unit + " (Shift Total)"}
                />
            </div>
        </div>
    );
}
