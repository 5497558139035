import React from 'react';
import moment from 'moment-timezone';
import '../../style.css'
import SpecificDelayMontlyYearlyGraph from '../components/SpecificDelayMontlyYearlyGraph';
import PieChartSection from '../components/PieChartSection';
import { getHourMinuteDifference } from '../../helper';
import { constData, issueTypes } from '../../constData';
import WDBox from '../components/WDBox';

const WeatherDelaysMontlyYearlySection = ({ data, choice }) => {
    const daysInMonth = moment().tz('Pacific/Auckland').daysInMonth();
    const categories = data?.data
        ? data?.data?.map((_, i) => (i > 8 ? (i + 1).toString() : `0${i + 1}`))
        : Array.from({ length: 12 }, (_, i) => (i > 8 ? (i + 1).toString() : `0${i + 1}`));

    return (
        <div className='d-flex flex-column '>
            <WDBox data={data} type={"WD"} type2={"POD"} />
            <SpecificDelayMontlyYearlyGraph choice={choice} type2={"POD"} categories={categories} data={data?.data} type={"WD"} />
        </div>

    );
};

export default WeatherDelaysMontlyYearlySection;
