
import React from 'react';
import './style.css'

export default function DayNightToggleButton({ choice, onToggle }) {
    return (
        <div className="button-container mr-3 d-flex">
            <div
                className={`button day-button ${choice === 'Day' ? 'active' : ''} d-flex justify-content-center align-items-center`}
                onClick={() => onToggle('Day')}
            >
                Day
            </div>
            <div
                className={`button night-button ${choice === 'Night' ? 'active' : ''} d-flex justify-content-center align-items-center`}
                onClick={() => onToggle('Night')}
            >
                Night
            </div>
        </div>
    );
}