import ReactDOM from 'react-dom';
import IdleIcon from './assets/idle.svg';
import moment from 'moment';

export const IdlePopup = ({ data }) => {
    const div = document.createElement('div');
    const calculateEndTime = (timestamp, activityDuration) => {
        // Ensure that both timestamp and activityDuration are provided
        if (!timestamp || !activityDuration) {
            return 'N/A'; // Return 'N/A' or some default value if inputs are missing
        }

        // Parse the duration (assuming activityDuration is in "hh:mm:ss" format)
        const [hours, minutes, seconds] = activityDuration.split(':').map(Number);
        const duration = moment.duration({ hours, minutes, seconds });

        // Calculate the end timestamp by adding the duration to the initial timestamp
        const endTime = moment.unix(timestamp).add(duration);
        // return endTime.format('hh:mm A, DD/MM/yyyy');
        // Format the end time for display
        return endTime.isSame(moment(), 'day')
            ? endTime.format('hh:mm:ss A')
            : endTime.format('hh:mm A, DD/MM/yyyy');
    }
    const popup = (
        <>
            <div
            >
                <div className='flyout-asset-gray'>
                    <div className="d-flex  flex-column align-items-start">
                        <div
                            className="mb-2"
                            style={{
                                borderBottom: '1px solid #beb6b6',
                                color: '#000000',
                                width: '100%',
                            }}
                        >
                            Idle Time
                        </div>
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            <img
                                width={18}
                                height={14}
                                style={{ objectFit: 'contain' }}
                                src={IdleIcon}
                                alt="Idle"
                                className="me-2"
                            />
                            <div className="" style={{ fontSize: '1rem', marginLeft: "0.63rem" }}> {data?.event_duration ?? 0}</div>
                        </div>

                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            <div
                                className="mt-1"
                                style={{ opacity: 0.7, fontSize: '1rem' }}
                            >
                                {moment(data?.timestamp * 1000).format(
                                    'hh:mm:ss A',
                                )}
                                -
                                {calculateEndTime(data?.timestamp, data?.event_duration)}
                                {/* {moment(data?.event_end_time_timestamp * 1000).format(
                                    'hh:mm:ss A',
                                )} */}
                                {/* {moment(data.timestamp * 1000).isSame(moment(), 'day')
                  ? moment(data?.timestamp * 1000).format('hh:mm:ss A')
                  : moment(data?.timestamp * 1000).format(
                      'hh:mm A, DD/MM/yyyy',
                    )} */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="popup-tip-container-gray">

            </div>
        </>
    );

    ReactDOM.render(popup, div);
    return div;
};