import React, { useEffect, useRef, useState, useContext } from "react";
import ShiftTotalHeader from "./components/components/ShiftTotalHeader";
import ShiftTotalDelayCompareSection from "./components/DelaySection/ShiftTotalDelayCompareSection";
import ShiftTotalDelaySection from "./components/DelaySection/ShiftTotalDelaySection";
import ManagementKPIsContext from "../Context/ManagementKPIsContext";
import DelaysMontlyYearlySection from "./components/DelaySection/DelaysMonthlyYearlySection";
import MaintenanceDelaysMontlyYearlySection from "./components/DelaySection/MaintenanceDelaysMonthlyYearlySection";
import WeatherDelaysMontlyYearlySection from "./components/DelaySection/WeatherDelaysMonthlyYearlySection";
import MonthlyYearlyHeader from "./components/components/MonthlyYearlyHeader";
import html2canvas from "html2canvas";
import jsPDF from 'jspdf';
import "./style.css";
import TopHeader from "../components/TopHeader";
import AuthLayout from '../../../components/AuthLayout';
const DelaySection = ({ type, title, data, ...props }) => {
    const [choice, setChoice] = useState(0);
    const [equipmentType, setEquipmentType] = useState(0);
    const menuOptionHandler = (optionCode) => {
        console.log('====================================');
        console.log(optionCode);
        console.log('====================================');
    }
    return (
        <div
            className="p-3"
            style={{
                backgroundColor: "white",
                // height: props.height,
                height: "100%",
                borderRadius: "0.938rem",
                // padding: "1.25rem 0rem",
                width: props?.width,
                boxShadow: "0rem 0.125rem 0.375rem #0000000F",
            }}
        >
            {/* ShiftTotalHeader component */}
            {type == 0 || type == 1 ? (
                <ShiftTotalHeader
                    equipmentType={equipmentType}
                    setEquipmentType={setEquipmentType}
                    choice={choice}
                    setChoice={setChoice}
                    title={title}
                    onMenuSelect={menuOptionHandler}
                    menuOptions={["Download", "View Deatils"]}
                />
            ) : (
                <MonthlyYearlyHeader
                    choice={choice}
                    setChoice={setChoice}
                    setEquipmentType={setEquipmentType}
                    equipmentType={equipmentType}
                    title={title}
                    type={type}
                    onMenuSelect={menuOptionHandler}
                    menuOptions={["Download", "View Deatils"]}
                />
            )}

            {/* Conditionally render either ShiftTotalDelaySection or ShiftTotalDelayCompareSection */}
            {(() => {
                switch (type) {
                    case 0:
                        return (
                            <ShiftTotalDelaySection
                                data={
                                    data?.[choice]?.delaysShiftTotals?.[
                                    equipmentType == 1
                                        ? "excavatorData"
                                        : "truckData"
                                    ]
                                }
                            />
                        );
                    case 1:
                        return (
                            <ShiftTotalDelayCompareSection
                                data={
                                    data?.[choice]
                                        ?.delaysComparisionShiftTotals?.[
                                    equipmentType == 1
                                        ? "excavatorData"
                                        : "truckData"
                                    ]
                                }
                            />
                        );
                    case 2:
                        return (
                            <DelaysMontlyYearlySection choice={choice} data={data?.[choice]?.[equipmentType == 1
                                ? "excavatorData"
                                : "truckData"]} />
                        );
                    case 3:
                        return (
                            <WeatherDelaysMontlyYearlySection
                                choice={choice}
                                data={data?.[choice]?.[equipmentType == 1
                                    ? "excavatorData"
                                    : "truckData"]}
                            />
                        );
                    case 4:
                        return (
                            <MaintenanceDelaysMontlyYearlySection
                                choice={choice}
                                data={data?.[choice]?.[equipmentType == 1
                                    ? "excavatorData"
                                    : "truckData"]}
                            />
                        );
                    default:
                        break;
                }
            })()}
        </div>
    );
};

// Delays component
const Delays = (props) => {
    const { delaysData1, delaysData2, delaysDate, setDelaysDate } = useContext(ManagementKPIsContext)
    const myRefs = [useRef(), useRef(), useRef(), useRef(), useRef()];
    const contentRef = useRef()
    function scrollToSection(index) { myRefs[index].current.scrollIntoView({ behavior: 'smooth' }); }
    const handleDownloadPDF = () => {
        html2canvas(contentRef.current).then((canvas) => {
            const imgData = canvas.toDataURL('image/png');
            const pdf = new jsPDF('landscape', 'px');

            pdf.addImage(imgData, 'PNG', 0, 0, pdf.internal.pageSize.getWidth(), pdf.internal.pageSize.getHeight());
            pdf.save('downloaded-pdf.pdf');
        })
    };
    return (
        <AuthLayout pageName="Delays">
            <section
                ref={contentRef}
                style={{
                    backgroundColor: "#F5F6F8",
                    padding: "1.563rem",
                    paddingTop: 0,
                    minHeight: "calc(100vh - 10rem)",
                }}
            >
                <TopHeader handleDownloadPDF={handleDownloadPDF} scrollToSection={scrollToSection} selectedDate={delaysDate} setSelectedDate={setDelaysDate} jumptoSectionOptions={['Delays (Shift Totals)', 'Delay Comparison (Shift Totals)', 'Delays (MTD/YTD)', 'Weather Delays (MTD/YTD)', 'Maintenance Delays (MTD/YTD)']} />

                <main style={{ backgroundColor: "#F5F6F8" }}>
                    <div className="d-flex row justify-content-center my-3">
                        <div className="col-xl-5 mb-3 mb-xl-0 pr-4 pr-xl-0" ref={myRefs[0]}>
                            <DelaySection
                                title="Delays (Shift Totals)"
                                // height={360}

                                type={0}
                                data={delaysData1}
                            />
                        </div>
                        <div className="col-xl-7 pb-0" ref={myRefs[1]}>
                            <DelaySection
                                title="Delay Comparison (Shift Totals)"
                                // height={360}

                                type={1}
                                data={delaysData1}
                            />
                        </div>
                    </div>
                    <div className="my-3 d-flex" ref={myRefs[2]}>
                        <DelaySection
                            title="Delays (MTD/YTD)"
                            // height={360}
                            width={"100%"}
                            type={2}

                            data={delaysData2}
                        />
                    </div>
                    <div className="my-3 row d-flex justify-content-between">
                        <div className="col-xl-6 mb-3 mb-xl-0 pr-4 pr-xl-0" ref={myRefs[3]}>
                            <DelaySection
                                title="Weather Delays (MTD/YTD)"
                                // height='28.125rem'
                                type={3}

                                data={delaysData2}
                            />
                        </div>
                        <div className="col-xl-6 pb-0" ref={myRefs[4]}>
                            <DelaySection
                                title="Maintenance Delays (MTD/YTD)"
                                height={400}
                                type={4}

                                data={delaysData2}
                            />
                        </div>
                    </div>
                </main>
            </section>
        </AuthLayout>
    );
};
export default Delays;
