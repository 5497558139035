import moment from "moment";
import React from 'react';
import emailIcon from "../../../../../assets/svgs/email.svg";
import searchIcon from "../../../../../assets/images/search-input.svg";
import LegendDropDown from "../../Components/LegendDropDown";
import DropdownField from './FormDropdown';
import TimeSelect from './TimeSelect';
import DownloadMenu from './DownloadMenu';
import DownloadIcon from './download.svg'
import { legendDropDownData } from "../../constData";
import { TripHistoryDropdowns } from '../DropdownData';
import SettingsFilter from './FilterSettings/SettingsFilter';
import { useLocation, useNavigate } from "react-router-dom";

export default function FilterBar({
    setFilteredValue,
    filteredValue,
    dropdownData,
    loading,
    downloadMenu,
    dropdownStyle,
    setDownloadMenu,
    mainDownloadHandler,
    getSettings,
}) {

    const location = useLocation();
    const navigation = useNavigate();

    const addQueryParam = (assetNo) => {
        const { pathname } = location;
        const queryParams = new URLSearchParams(location?.search);
        if (assetNo !== "") {
            queryParams.set('asset', assetNo);
        } else {
            queryParams.delete('asset');
        }

        navigation({
            pathname,
            search: `?${queryParams.toString()}`,
            replace: true,
        });

    };


    return (
        <div >
            <div className="d-flex">
                <div style={{ marginLeft: "auto", position: "absolute", top: 75, right: 25, cursor: "pointer" }}>
                    <SettingsFilter toShowFilter getSettings={getSettings} />
                </div>
            </div>
            <div className='d-flex justify-content-between'>
                <div className="d-flex align-items-center justify-content-center">
                    <div className="mr-3">
                        <input
                            type="date"
                            value={filteredValue?.date}
                            style={{ height: "2.5rem", width: "10.563rem", border: "1px solid #E1E8F4", borderRadius: "1.5rem" }}
                            className="form-control"
                            onChange={(e) => {
                                setFilteredValue(prev => ({ ...prev, date: e?.target?.value }))
                            }
                            }
                        />
                    </div>

                    <div className="mr-3 d-flex align-items-center">
                        <span style={{ font: 'normal normal normal 1rem Open Sans', color: 'black' }} className='mr-2'>From</span>
                        <TimeSelect disabled={loading} uniqueName={'from'} defaultValue={{ hour: '00', minute: '00', second: '00', isAM: true }} onChange={(value) => { setFilteredValue(prev => ({ ...prev, from: value })) }} />
                    </div>
                    <div className="mr-3 d-flex align-items-center">

                        <span style={{ font: 'normal normal normal 1rem Open Sans', color: 'black' }} className='mr-2'>To</span>
                        <TimeSelect disabled={loading} uniqueName={'to'} defaultValue={{ hour: '11', minute: '59', second: '59', isAM: false }} onChange={(value) => { setFilteredValue(prev => ({ ...prev, to: value })) }} />
                    </div>
                    <div className='d-flex'>
                        {/* dropdowns start*/}
                        {TripHistoryDropdowns.map((dropdown, index) => {
                            if (dropdown.title != "Status" && filteredValue?.date != moment().format('YYYY-MM-DD')) {
                                return (<div className="mr-3">
                                    <div style={dropdownStyle}>
                                        <div className="d-flex  justify-content-between" style={{ width: '100%', height: "100%" }} >
                                            {/* <div className='filter-dropdown'>{dropdown.title} :</div> */}
                                            <DropdownField
                                                title={dropdown?.title}
                                                selectedOptions={filteredValue[dropdown.type]}
                                                allOptions={dropdownData[dropdown.type].options}
                                                lowerLevelClassName="p-0"
                                                multiselect
                                                name={dropdown.title}
                                                handleChange={(value) => {
                                                    setFilteredValue({
                                                        ...filteredValue,
                                                        [dropdown.type]: value
                                                    })

                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>)
                            }
                            else if (filteredValue?.date === moment().format('YYYY-MM-DD')) {
                                return (<div className="mr-3">
                                    <div style={dropdownStyle}>
                                        <div className="d-flex  justify-content-between" style={{ width: '100%', height: "100%" }}>
                                            {/* <div className='filter-dropdown'>{dropdown.title} :</div> */}
                                            <DropdownField
                                                title={dropdown?.title}
                                                selectedOptions={filteredValue[dropdown.type]}
                                                allOptions={dropdownData[dropdown.type].options}
                                                lowerLevelClassName="p-0"
                                                multiselect
                                                name={dropdown.title}
                                                handleChange={(value) => {
                                                    setFilteredValue({
                                                        ...filteredValue,
                                                        [dropdown.type]: value
                                                    })

                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>)
                            }
                        }
                        )}
                        {/* dropdowns end*/}
                    </div>
                </div>
                <div className="d-flex justify-content-center align-items-center mx-3">
                    {/* <div className="d-flex mr-4">
                            <FiDownload
                                className="mt-2"
                                onClick={
                                    radioValue == 2
                                        ? generateCanvasPdf
                                        : generateSimplePDFByCanvas
                                }
                                style={{
                                    color: "black",
                                    cursor: "pointer",
                                    width: "1.3rem",
                                }}
                            />
                            <div style={{ marginLeft: "0.4375rem", marginTop: "0.8rem", font: " normal normal 600 0.75rem/0.875 Open Sans", }} onClick={
                                radioValue == 2
                                    ? generateCanvasPdf
                                    : generateSimplePDFByCanvas
                            }>Download</div>
                        </div> */}
                    <div className='d-flex align-items-center'>
                        <div className="mr-3">
                            <div className="search-asset">
                                <input
                                    type="search"
                                    className="shadow-none"
                                    value={filteredValue.searchQuery}
                                    placeholder="Search"
                                    style={{
                                        border: "none",
                                        font: "normal normal normal 600 1.075rem Open Sans",
                                        background: "#FFFFFF 0% 0 % no - repeat padding- box",
                                        color: "#6F7070",
                                        height: "1.8rem",
                                    }}
                                    onChange={(e) => {
                                        addQueryParam(e?.target?.value)
                                        setFilteredValue(prev => ({ ...prev, searchQuery: e?.target?.value }))
                                    }}
                                />

                                <img style={{ height: "1.5rem", width: "1.5rem" }} src={searchIcon} />
                            </div>
                        </div>
                        <LegendDropDown data={legendDropDownData} />
                        <div className='ml-3 d-flex align-items-center'>
                            <DownloadMenu
                                uniqueName={'reportDownloadAll'}
                                menuOptions={[
                                    {
                                        displayName: 'Email Report (CSV)',
                                        icon: emailIcon,
                                    }
                                ]}

                                color={'#E2ECFC'}
                                menuVisible={downloadMenu}
                                setMenuVisible={(value) => { setDownloadMenu(value) }}
                                onMenuSelect={(optionNo) => mainDownloadHandler(optionNo)}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
