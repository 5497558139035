import { useEffect, useState } from "react";
import { Image } from "react-bootstrap";
import ArrowDown from "./arrowDown.png";
import SearchIcon from "./Search.svg";
import "./styles.css"




const DropdownField = ({
  title,
  name,
  fieldKey,
  selectedOptions,
  allOptions,
  multiselect,
  handleChange,
  upperLevelClassName = "d-flex flex-row justify-content-between field-container filter-dropdown-options",
  lowerLevelClassName = "",
  errorMessage,
}) => {
  const [isDropdownVisible, setDropdown] = useState(false);
  const [dropdownSearch, setDropdownSearch] = useState("");

  const nameWithoutWhiteSpace = name ? name.split(" ").join("") : "";
  const allChosenOptions = multiselect
    ? allOptions.filter(({ value }) => selectedOptions.includes(value))
    : [];

  const convertCodeListToNames = (entireList, selected) => {
    if (!selected) return "None";
    if (selected.length === 0) return "None";

    const filteredList = entireList
      .filter(({ value, name }) => selected.includes(value ?? name))
      .map(({ name }) => name);
    if (filteredList.length === 0) return "None";
    if (filteredList.length === entireList.length) return "All " + title;
    else {
      return filteredList.join(", ");
    }
  };

  useEffect(() => {
    const clickListener = (e) => {
      if (e.target.id !== `keepDropdownOpen${nameWithoutWhiteSpace}`) {
        setDropdown(false);
      }
    };
    document.addEventListener("mousedown", clickListener);

    return () => {
      document.removeEventListener("mousedown", clickListener);
    };
  }, [nameWithoutWhiteSpace]);

  const selectAll = () => {
    if (allChosenOptions.length === allOptions.length) {
      // deselect all
      handleChange([])
    } else {
      // select any that are not currently selected
      handleChange(allOptions.map(option => {
        return option?.value
      }))
    }
  };

  return (
    <div className={upperLevelClassName}>
      {/* {name ? <label className="flex-one " style={{ marginLeft: "0.5rem" }}>{name}</label> : null} */}

      <div className="d-flex flex-column add-user-input">
        <div className="position-relative">
          {/* open dropdown field */}
          <div className="d-flex justify-content-center align-items-center">
            <button
              className={`rounded-borders-and-padding pr-2 inner-input-select  py-1 ${lowerLevelClassName} d-flex align-items-center justify-content-between ${isDropdownVisible ? 'primary-color-outline' : ''}`}
              id={`keepDropdownOpen${nameWithoutWhiteSpace}`}
              type="button"
              style={{ backgroundColor: isDropdownVisible ? "rgb(10 128 47 / 6%)" : null, outline: isDropdownVisible ? " 0.063rem solid #547d54" : "0rem solid #547d54", height: '2.5rem' }}
              onClick={() => {
                setDropdown(!isDropdownVisible);
              }}
            >
              <div
                className="d-flex justify-content-center px-3 position-relative"
                id={`keepDropdownOpen${nameWithoutWhiteSpace}`}
                style={{ alignItems: "center" }}
              >
                {multiselect
                  ? convertCodeListToNames(allOptions, selectedOptions)
                  : allOptions.find(({ value }) => value === selectedOptions)
                    ?.name}
                <div id={`keepDropdownOpen${nameWithoutWhiteSpace}`}>

                </div>
              </div>
              <div >
                <Image
                  id={`keepDropdownOpen${nameWithoutWhiteSpace}`}
                  src={ArrowDown}
                  className="pl-2"
                  style={{ height: "0.513rem", }}
                  alt="down"
                />
              </div>
            </button>
          </div>


          {isDropdownVisible && (
            <div
              className="hover-card dropdown-position"
              id={`keepDropdownOpen${nameWithoutWhiteSpace}`}
            >
              {/* Search field */}
              <div className="border-bottom px-2 pt-1 pb-1">
                <img
                  src={SearchIcon}
                  style={{ marginBottom : "0.2rem",  width: "1.5rem", height: "1.5rem" }}
                  alt="search"
                  id={`keepDropdownOpen${nameWithoutWhiteSpace}`}
                />
                <input
                  style={{ marginLeft: "0.3rem", marginTop: "0.3rem" }}
                  placeholder="Search"
                  className="no-focus-border border border-0 ps-1 w-75"
                  onChange={({ target }) => setDropdownSearch(target.value)}
                  id={`keepDropdownOpen${nameWithoutWhiteSpace}`}
                  value={dropdownSearch}
                />
              </div>

              {/* select all checkbox */}
              {multiselect && !dropdownSearch && (
                <div
                  style={{ marginTop: "1rem", }}
                  className="option-row"
                  onClick={selectAll}
                  id={`keepDropdownOpen${nameWithoutWhiteSpace}`}
                >
                  {multiselect && (
                    <input
                      type="checkbox"
                      checked={allChosenOptions.length === allOptions.length}
                      readOnly
                      id={`keepDropdownOpen${nameWithoutWhiteSpace}`}
                      className="styled-checkbox"
                    />
                  )}
                  <label style={{ color: "#2B2D2F", }} id={`keepDropdownOpen${nameWithoutWhiteSpace}`}>
                    Select All
                  </label>
                </div>
              )}

              {/* dropdown options */}
              {allOptions
                .filter(({ name: n }) =>
                  n.toLowerCase().includes(dropdownSearch.toLowerCase())
                )
                .map(({ name: optionName, value: optionValue }) => (
                  <div
                    className="option-row"
                    key={optionValue}
                    onClick={() => {
                      let finalRes = []
                      const found = selectedOptions?.filter(option => option !== optionValue);
                      if (selectedOptions.length === 0) {
                        finalRes = [optionValue]
                      }

                      else if ((!found?.length && (selectedOptions.length > 1))) {
                        finalRes = [...selectedOptions, optionValue];
                      } else {
                        if (!selectedOptions?.includes(optionValue)) {
                          finalRes = [...selectedOptions, optionValue];
                        } else {
                          finalRes = [...found]
                        }
                      }
                      handleChange(finalRes)

                      // handleChange(
                      //   [...selectedOptions, ...(!selectedOptions?.includes(optionValue) ? [optionValue] : [])]
                      // );
                      if (!multiselect) setDropdown(false);
                    }}
                    id={`keepDropdownOpen${nameWithoutWhiteSpace}`}
                  >
                    {multiselect && (
                      <input
                        type="checkbox"
                        checked={selectedOptions.includes(optionValue)}
                        readOnly
                        id={`keepDropdownOpen${nameWithoutWhiteSpace}`}
                        className="styled-checkbox"
                      />
                    )}
                    <label id={`keepDropdownOpen${nameWithoutWhiteSpace}`}>
                      {optionName}
                    </label>
                  </div>
                ))}
            </div>
          )}
        </div>
        {errorMessage && (
          <p className="error-msg-manage-operator">{errorMessage}</p>
        )}
      </div>
    </div >
  );
};

export default DropdownField;
