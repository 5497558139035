import PieChartComponent from "./PieChartComponent";
import DataPoint from "./DataPoint";
import { getHourMinuteDifference } from "../../helper";

export default function PieChartSection(props) {
    const { data } = props;
    return (
        <div className="d-flex flex-column">
            <div>
                <PieChartComponent
                    time={
                        data
                            ? getHourMinuteDifference(
                                0,
                                data?.reduce(
                                    (prev, curr) => prev + curr?.value,
                                    0
                                ) * 1000
                            )
                            : "00:00"
                    }
                    data={data}
                    selectedIndex={props.selectedIndex}
                />
            </div>
            <div
                className="d-flex flex-column"
            >
                <div>
                    <h5
                        className="my-2"
                        style={{
                            // font: "normal normal 600 12px/8px Open Sans",
                            fontSize: '0.75rem'
                        }}
                    >
                        {props?.title ? props?.title : ""}
                    </h5>
                </div>

                <div className="flex-column pr-4">
                    {props?.listDetails?.map((row, index) => (
                        <div key={index}>
                            <DataPoint
                                color={row?.color}
                                label={row?.label}
                                value={row?.value}
                                data={row?.data}
                                selected={
                                    row.value != undefined &&
                                    props.selected == row.value
                                }
                                onSelected={
                                    props.onSelected
                                        ? () => props?.onSelected(row.value)
                                        : undefined
                                }
                            />
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}
