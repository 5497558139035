import ReactDOM from 'react-dom';
import MarkerFlyout from './FLyouts/MatkerFlyout';


export const AssetPopup = (coordinate, { data }) => {
    const div = document.createElement('div');
    div.style.position = 'fixed'; // Use fixed or absolute depending on your layout
    div.style.top = '0px'; // Adjust this value as needed
    div.style.left = '50%'; // Center horizontally
    div.style.transform = 'translateX(-50%)'; // Adjust for centering
    
    const popupData = [ data ]
    const popup = (
        <>
            <div
                key={`popup-${coordinate.lat}-${coordinate.lng}`}
            >
                <MarkerFlyout data={popupData} />
            </div>
            <div className="popup-tip-container"></div>
        </>
    );

    ReactDOM.render(popup, div);
    return div;
};