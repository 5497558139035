import React, { useState } from "react";
import CycleActivityBarGraph from "./CycleActivityBarGraph";
import CycleActivityList from "./CycleActivityList";

const CycleActivity = ({ data }) => {
  const [selected, setSelected] = useState(0);
  return (
    <div className="row d-flex align-items-end">
      <div className="col-9">
        <CycleActivityBarGraph
          selected={selected}
          data={data}
        />
      </div>
      <div className="col-3" style={{
        marginBottom: '29px'
      }}>
        <CycleActivityList
          // data={data}
          // labels={labels}
          selected={selected}
          setSelected={setSelected}

        />
      </div>
    </div>
  );
};

export default CycleActivity;
