export function getHourMinuteDifference(start, end, hrs) {
    const diffInMs = Math.abs(end - start);
    const hours = Math.floor(diffInMs / (1000 * 60 * 60));
    const minutes = Math.floor((diffInMs / (1000 * 60)) % 60);
    const formattedTime = `${hours.toString().padStart(2, "0")}:${minutes
        .toString()
        .padStart(2, "0")}`;
    if (hrs)
        return formattedTime + ' ' + hrs;
    return formattedTime;
}

export function convertSecondsToHoursMinutes(seconds) {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);

    const hoursDisplay = hours > 0 ? hours + "h" : "";
    const minutesDisplay = minutes > 0 ? minutes + "m" : "";

    return hoursDisplay + minutesDisplay;
}
