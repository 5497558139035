import { CgClose } from 'react-icons/cg';

export default function TimelineGraphHeader({ title, modalToggle }) {
  return (
    <div
      className="d-flex flex-row justify-content-between"
      style={{ height: '2.5rem' }}
    >
      <div className="asm-timeline-graph-header">
        <b>{title}</b>
      </div>
      <div>
        <CgClose
          style={{
            justifyContent: 'flex-end',
            cursor: 'pointer',
            margin: '-15px -5px 0px 0px',
          }}
          onClick={() => modalToggle(false)}
          size={'1.063rem'}
          className="px-0"
        />
      </div>
    </div>
  );
}
