import React from 'react'
import "./index.css"
import { Link } from 'react-router-dom';
import SignalBars from '../Signal/Signal';



const MarkerFlyout = ({ data }) => {
    console.log({data})
    return (
        <div style={{}} className='flyout-asset p-2 '>
            {data.map((item, index) => (
                <div>
                    <div key={index} className='d-flex' style={{ marginTop: "0rem", }}>
                        <div
                            className="flyout-card"
                            style={{
                                backgroundColor:
                                    (item["Status"] === "Active" || item["Status"] === "Idling")
                                        ? "#CFFD69 "
                                        : (item["Status"] === "Disconnected")
                                            ? "#ED5151 "
                                            : (item["Status"] === "Parked") ? "#BEB6B6 " : "white",
                            }}>

                        </div>
                        <div >{item["Asset ID"]}</div>
                        <div style={{ marginLeft: 'auto', marginRight: "1rem", marginTop: "0.53rem", }}>
                            <SignalBars Signal={ item?.notFound ? 0 :  item["Signal"]} />
                        </div>

                    </div>
                    <div className='container'>
                        <div style={{
                            borderTop: '2px solid #eee',
                            padding: 0,
                        }}></div>
                        {/* <div className='row '>
                            <div className='col-5 p-0 pl-3 popup-field' >Operator:</div>
                            <div className='col-7 p-0 pl-3 popup-field-value'>{item["Operator"]}</div>
                        </div> */}
                        <div className='row'>
                            <div className='col-5 p-0 pl-3 popup-field'>Status:</div>
                            <div className='col-7 p-0 pl-3 popup-field-value' >
                                {item["Status"]}
                                {item["Status"] == "Disconnected" && <br/>}
                                <span style={{ color: "#707070" }}>({item["Status Time"]})</span>
                            </div>
                        </div>

                        <div className='row'>
                            <div className='col-5 p-0 pl-3' style={{ marginTop: "1rem", marginBottom: "0.5rem", font: "normal normal 600 1rem/1.25rem Open Sans", textDecoration: "underline", color: "#707070", }}>Today:</div>
                        </div>
                        <div className='row'>
                            <div className='col-5 p-0 pl-3 popup-field'>Distance:</div>
                            <div className='col-7 p-0 pl-3 popup-field-value' >{item["KM Today"]} KM</div>
                        </div>
                        <div className='row'>
                            <div className='col-5 p-0 pl-3 popup-field' >Engine hours:</div>
                            <div className='col-7 p-0 pl-3 popup-field-value' >{item.Today["Engine hours"]}</div>
                        </div>
                        <div className='row'>
                            <div className='col-5 p-0 pl-3 popup-field' >Max Speed:</div>
                            <div className='col-7 p-0 pl-3 popup-field-value'>{item.Today["Max Speed"]}</div>
                        </div>
                        <div className='row'>
                            <div className='col-5 p-0 pl-3 popup-field' >Alerts:</div>
                            <div className='col-7 p-0 pl-3 popup-field-value'>Idle time ({item.Today?.Alerts?.["Idle time"]})</div>
                        </div>
                        <div className='row'>
                            <div className='col-5 p-0 pl-3 popup-field'></div>
                            <div className='col-7 p-0 pl-3 popup-field-value' >
                                Speeding ({item.Today?.Alerts?.["Speeding"]})
                            </div>
                        </div>

                    </div>
                </div>
            ))}


            <div className='col-5 p-0 pl-3' style={{ marginTop: "1rem", marginBottom: "0.5rem", font: "normal normal 600 1rem/1.25rem Open Sans", textDecoration: "underline", color: "#387FF3", }}>
                <a style={{cursor : "pointer"}} href={`/asset-monitoring?type=history&asset=${data[0]["Asset ID"]}`}>
                    See Trips
                </a>


            </div>




        </div>
    )
}

export default MarkerFlyout;