import { CallWithAuth } from '../../../../action/apiActions';
import { REPORT_EVENTS } from '../../../../action/apiPath';
import { toast } from "react-toastify";
import { calculateDistance, secondsToHhMm } from '../../../../utils/helper';
import { EndPopup } from '../Map/EndPopup';
import { IdlePopup } from '../Map/IdlePopup';
import { ParkPopup } from '../Map/ParkPopup';
import { SpeedingPopup } from '../Map/SpeedingPopup';
import { StartPopup } from '../Map/StartPopup';
import * as moment from 'moment-timezone';

export const getSignalValue = (css) => {
  css = !css && css !== 0 ? 0 : css;
  return 5 - Math.round(css / 25);
};

export const getSignalStatus = (css) => {
  const v = getSignalValue(css);
  if (v === 5 || v === 4) {
    return 'Low';
  } else if (v === 3 || v === 2) {
    return 'Medium';
  } else if (v === 1 || v === 0) {
    return 'High';
  }
  return '';
};

export const convertCodeListToNames = (entireList, selected) => {
  if (!selected || selected.length === 0) {
    return 'None';
  }

  const filteredList = entireList
    .filter(({ value, code }) => selected.includes(value ?? code))
    .map(({ name }) => name);

  return filteredList.length === 0
    ? 'None'
    : filteredList.length === entireList.length
      ? 'All'
      : filteredList.join(', ');
};

export const getUnixTimestamp = (hour, minute, second, isAM, dateString) => {
  if (!hour || !minute || !second || !dateString) {
    return;
  }

  const timeStr = `${hour.toString().padStart(2, '0')}:${minute
    .toString()
    .padStart(2, '0')}:${second.toString().padStart(2, '0')}`;
  const amPmStr = isAM ? 'AM' : 'PM';
  const nzDateTimeString = `${dateString}T${timeStr} ${amPmStr}`;
  const nzMoment = moment.tz(
    nzDateTimeString,
    'YYYY-MM-DDTHH:mm:ss A',
    'Pacific/Auckland',
  );

  return nzMoment.utc().unix();
};

export const getFilteredTimestamp = (filter, date) =>
  getUnixTimestamp(
    filter?.hour,
    filter?.minute,
    filter?.second,
    filter?.isAM,
    date,
  );

export const generateCsv = (data, headers) => {
  const headersRow = headers.join(',');
  const rows = data.map((row) => Object.values(row).join(','));

  return `${headersRow}\n${rows.join('\n')}`;
};

export const formatTime = (hour, minute) =>
  `${hour.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')}`;

const eventAdd = (data, filteredData, K) => {
  if (
    K === -1 ||
    data.timestamp - filteredData[K].timestamp >= 5 ||
    (data.eid === 4 && data.ign) ||
    (data.eid === 5 && !data.ign) ||
    (data.eid === 24 && data.eng) ||
    (data.eid === 25 && !data.eng) ||
    data.eid === 14 ||
    data.eid === 15
  ) {
    return true;
  }
  return false;
};

export const formatTimelineReport = (data) => {
  try {
    const filteredData = [];
    for (let index = 0, K = -1; index < data.length; index++) {
      if (eventAdd(data[index], filteredData, K)) {
        filteredData.push(data[index]);
        K++;
      }
    }

    return filteredData.map((row) => {
      const eventType = () => {
        if (row.eid === 4 && row.ign) return 'Ignition ON';
        if (row.eid === 5 && !row.ign) return 'Ignition OFF';
        if (row.eid === 24 && row.eng) return 'Engine ON';
        if (row.eid === 25 && !row.eng) return 'Engine OFF';
        if (row.eid === 14) return 'Dump Switch HIGH';
        if (row.eid === 15) return 'Dump Switch LOW';
        return '-';
      };

      const isIdling = row.ign && row.spd === 0;
      const isSpeeding = row.ign && row.spd > 30;
      const signalValue = getSignalValue(row.css);
      return {
        time: moment(row.timestamp * 1000)
          .tz('Pacific/Auckland')
          .format('hh:mm:ss A'),
        messageNo: row.message_id,
        event: eventType(),
        location: { latitude: row.latitude, longitude: row.longitude },
        speed: row.spd + ' km/h',
        alert: isIdling ? 'Idling' : isSpeeding ? 'Speeding' : '-',
        alertType: isIdling
          ? 0
          : isSpeeding
            ? row.spd > 55
              ? 3
              : row.spd > 40
                ? 2
                : row.spd > 30
                  ? 1
                  : -1
            : -1,
        heading: row.head,
        altitude: row.alt,
        satellites: row.satu,
        signal: signalValue + ' bar' + (signalValue < 2 ? '' : 's'),
        timestamp: row.timestamp,
      };
    });
  } catch (error) {
    console.error(error);
  }
  return [];
};

export const handleDownloadCSV = (csvContent, filename) => {
  const blob = new Blob([csvContent], { type: 'text/csv' });
  const link = document.createElement('a');
  link.href = URL.createObjectURL(blob);
  const modifiedFileName = filename.replace(/\//g, '-') + '.csv';
  link.download = modifiedFileName;
  link.click();
};

const prepareCSV = (reData, reportType, heading, headers) => {
  let data = heading;
  for (let i = 0; i < reData.length; i++) {
    for (let j = 0; j < reData[i].trips.length; j++) {
      const trips_data = formatTimelineReport(reData[i].trips[j].data);
      data +=
        reData[i].asset_ID +
        ' - Trip ' +
        reData[i].trips[j].tripNo +
        '/' +
        reData[i].totalTrips +
        '\n' +
        generateCsv(
          trips_data.map((row) => {
            delete row.alertType;
            delete row.timestamp;
            return {
              ...row,
              location: `"${row.location.latitude},${row.location.longitude}"`,
              messageNo: row.messageNo.toString(),
            };
          }),
          headers,
        ) +
        '\n\n';
    }
    data += '\n\n';
  }
  return data;
};

export const downloadCSVofReportTimeline = (
  reData,
  assets,
  date,
  startTime,
  endTime,
  fileName,
  reportType,
) => {
  let headers = [
    'Time',
    'Message Number',
    'Event',
    'Location',
    'Speed',
    'Alert',
    'Heading',
    'Altitude',
    'Satellites',
    'Signal',
  ];

  const startDate = new Date(date);
  const formattedStartDate = startDate.toLocaleDateString('en-NZ', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    timeZone: 'Pacific/Auckland',
  });

  const fromTime = startTime
    ? new Date(startTime * 1000).toLocaleTimeString('en-NZ', {
      hour: '2-digit',
      minute: '2-digit',
      hour12: true,
      timeZone: 'Pacific/Auckland',
    })
    : '00:00 AM';

  const toTime = endTime
    ? new Date(endTime * 1000).toLocaleTimeString('en-NZ', {
      hour: '2-digit',
      minute: '2-digit',
      hour12: true,
      timeZone: 'Pacific/Auckland',
    })
    : '11:59 PM';

  let data =
    `Trip report - ${formattedStartDate} - ${fromTime}/${toTime}\n\n` +
    'Truck' +
    '\n';
  // if (reportType !== 'ASSET') {
  //   data += `\n` + 'Truck' + '\n';
  // }

  data = prepareCSV(reData, reportType, data, headers);

  if (reportType === 'GENERAL') {
    fileName = `General Trip Report - ${formattedStartDate}`;
  } else if (reportType === 'ASSET') {
    fileName = `${assets[0]} Trips Report - ${formattedStartDate}`;
  } else if (reportType === 'TRIP') {
    fileName = `${assets[0]} Trip ${reData[0].trips[0].tripNo} # Report - ${formattedStartDate}`;
  } else if (reportType === 'TRIP_TIMELINE') {
    fileName = `${assets[0]} Trip ${reData[0].trips[0].tripNo} # Report - ${formattedStartDate}`;
  }

  handleDownloadCSV(
    data,
    fileName
      ? fileName
      : `Trip report - ${formattedStartDate} - ${fromTime} ${toTime}`,
  );
};

export const downloadReportFromServer = async (
  date,
  startTime,
  endTime,
  trip_id,
  cancelToken,
  assets,
  fileName,
  reportType,
  isEmail,
) => {
  try {
    const payload = {
      date,
      startTime,
      endTime,
      trip_id,
      ...(assets?.length && { assets }),
    };
    let reportUrl = REPORT_EVENTS + '?';
    if (isEmail) {
      reportUrl = reportUrl + '&isEmail=true';
    }
    if (reportType) {
      reportUrl = reportUrl + '&reportType=' + reportType;
    }
    const response = await CallWithAuth(
      'POST',
      reportUrl,
      payload,
      cancelToken,
    );

    if (response?.res?.status === 200) {
      if (!isEmail) {
        const reData = response.res.data.data;
        downloadCSVofReportTimeline(
          reData,
          assets,
          date,
          startTime,
          endTime,
          fileName,
          reportType,
        );
      } else {
        toast.info(response.res.data?.message);
      }
    } else if (response.res.data?.message) {
      toast.error(response.res.data?.message);
    } else {
      toast.error('Error occured while processing request, please try again');
    }
  } catch (error) {
    toast.error(error);
  }
};

export const formatMapData = (data, speed, minIdleTime) => {
  try {
    const mapData = {
      polyline: [], //coordinate: { latitude: asset?.latitude, longitude: asset?.longitude },
      idle_markers: [],
      speeding_markers: [],
      endPoints_markers: [],
      events: {
        speeding: [],
        idle: [],
      },
    };
    let isPark = true;
    const sortedData = data.sort((a, b) => a.timestamp - b.timestamp);
    let prevtimestamp, prevIdleObj, currentIdleObj;
    let i = 0;
    const parkData = data;
    let ignCount = 0;
    parkData?.map((d) => {
      if (d?.ign) {
        ignCount += 1;
      }
    });
    if (ignCount > 2) {
      isPark = false;
    }

    let mySpeeding = null;
    for (let speedIdx = 0; speedIdx < sortedData.length; speedIdx++) {
      const singleEvent = sortedData[speedIdx];
      if (singleEvent?.spd > 30) {
        if (!mySpeeding || mySpeeding.spd < singleEvent.spd) {
          mySpeeding = singleEvent;
        }
      }
    }
    if (mySpeeding) {
      mapData.speeding_markers = [
        {
          coordinates: {
            latitude: mySpeeding.latitude,
            longitude: mySpeeding.longitude,
          },
          speed: mySpeeding.spd ?? 0 + ' km/h',
          maxSpeed: mySpeeding.spd || 0,
          onlyMarker: true,
          url:
            mySpeeding.spd <= 40
              ? './assets/images/trip-icon-speeding-30.svg'
              : mySpeeding.spd > 40 && mySpeeding.spd < 55
                ? './assets/images/trip-icon-speeding-40.svg'
                : `./assets/images/trip-icon-speeding-55.svg`,
          type: 'speed',
          showPopupInitially: false,
          popup: () =>
            SpeedingPopup({ data: { ...mySpeeding, spd: mySpeeding.spd } }),
        },
      ];
    }

    while (i < sortedData.length) {
      let item = sortedData[i];

      //handling polyline data;
      if (item) {
        if (!prevtimestamp || item.timestamp - prevtimestamp > 1) {
          if (i === 0 && !isPark) {
            mapData.polyline.push({
              coordinates: {
                latitude: item.latitude,
                longitude: item.longitude,
              },
              timestamp: item.timestamp,
              head: item.head,
              css: item.css,
              spd: item.spd,
              type: 'start',
              showPopupInitially: true,
              url: './assets/images/trip-icon-start.svg',
              popup: () => StartPopup({ data: item }),
              // onlyMarker: true,
              // duration: sortedData[sortedData?.length - 1]?.timestamp - sortedData[0]?.timestamp,
            });
          } else if (i === sortedData?.length - 1 && !isPark) {
            const activity_duration =
              secondsToHhMm(item?.timestamp - sortedData[0]?.timestamp) + ' ';
            const activity_distance =
              (item?.odo - sortedData[0]?.odo)?.toFixed(2) + ' km';
            mapData.polyline.push({
              coordinates: {
                latitude: item.latitude,
                longitude: item.longitude,
              },
              timestamp: item.timestamp,
              head: item.head,
              css: item.css,
              spd: item.spd,
              type: 'end',
              url: './assets/images/trip-icon-end.svg',
              showPopupInitially: true,
              popup: () =>
                EndPopup({
                  data: { ...item, activity_duration, activity_distance },
                }),
              // onlyMarker: true,
              // duration: sortedData[sortedData?.length - 1]?.timestamp - sortedData[0]?.timestamp,
            });
          }
          prevtimestamp = item.timestamp;
        }
      }

      //handling speed data
      if (item?.spd > 30) {
        //to push speed data
        let j = i,
          firstSpeedingEvent,
          lastSpeedingEvent,
          maxSpeedEvent = null;
        while (j < sortedData?.length && sortedData[j]?.spd > 30) {
          if (!firstSpeedingEvent) firstSpeedingEvent = sortedData[j];
          lastSpeedingEvent = sortedData[j];
          if ((lastSpeedingEvent?.spd ?? 0) > (maxSpeedEvent?.spd ?? 0)) {
            maxSpeedEvent = lastSpeedingEvent;
          }
          j++;
        }
        i = j;

        //push in events array also
        let speeding_event = mapData.events.speeding?.[0];
        if (!speeding_event || speeding_event?.maxSpeed < maxSpeedEvent?.spd) {
          speeding_event = {
            event_start_time: moment(
              firstSpeedingEvent?.timestamp * 1000,
            ).format('hh:mm:ss A'),
            event_end_time: moment(lastSpeedingEvent?.timestamp * 1000).format(
              'hh:mm:ss A',
            ),
            timestamp: maxSpeedEvent?.timestamp,
            event_duration:
              secondsToHhMm(
                lastSpeedingEvent?.timestamp - firstSpeedingEvent?.timestamp,
              ) + ' ',
            event_distance: calculateDistance(
              lastSpeedingEvent.latitude,
              lastSpeedingEvent.longitude,
              firstSpeedingEvent.latitude,
              firstSpeedingEvent.longitude,
            ),
            from: `lat ${firstSpeedingEvent?.latitude} lon ${firstSpeedingEvent.longitude}`,
            to: `lat ${lastSpeedingEvent?.latitude} lon ${lastSpeedingEvent.longitude}`,
            event_name: 'Speeding',
            maxSpeed: maxSpeedEvent?.spd || 0,
            event_speed: maxSpeedEvent?.spd + ' km/h',
            coordinatesFrom: {
              latitude: firstSpeedingEvent.latitude,
              longitude: firstSpeedingEvent.longitude,
            },
            coordinatesTo: {
              latitude: lastSpeedingEvent.latitude,
              longitude: lastSpeedingEvent.longitude,
            },
            coordinates: {
              latitude: maxSpeedEvent.latitude,
              longitude: maxSpeedEvent.longitude,
            },
          };
        }
        mapData.events.speeding = [speeding_event];
      }
      //handle idle events markers

      if (
        i < sortedData.length &&
        sortedData[i]?.ign == true &&
        sortedData[i]?.spd == 0
      ) {
        if (!prevIdleObj) {
          prevIdleObj = sortedData[i];
        }
      }
      let flag = 0;
      while (
        i < sortedData.length &&
        sortedData[i]?.ign == true &&
        sortedData[i]?.spd == 0 &&
        prevIdleObj?.odo - sortedData[i]?.odo < 0.01
      ) {
        currentIdleObj = sortedData[i];
        i++;
        flag = 1;
      }

      if (
        currentIdleObj &&
          currentIdleObj?.timestamp - prevIdleObj?.timestamp > 0 &&
          !minIdleTime ? true : currentIdleObj?.timestamp - prevIdleObj?.timestamp > minIdleTime
      ) {
        const event_duration =
          secondsToHhMm(currentIdleObj?.timestamp - prevIdleObj?.timestamp) +
          '  ';
        const event_start_time_timestamp = prevIdleObj?.timestamp;
        const event_end_time_timestamp = currentIdleObj?.timestamp;
        mapData.idle_markers.push({
          coordinates: {
            latitude: currentIdleObj.latitude,
            longitude: currentIdleObj.longitude,
          },
          event_start_time_timestamp: prevIdleObj?.timestamp,
          event_end_time_timestamp: currentIdleObj?.timestamp,
          timestamp: prevIdleObj?.timestamp,
          duration: currentIdleObj?.timestamp - prevIdleObj?.timestamp,
          onlyMarker: true,
          url: `./assets/images/idle.svg`,
          type: 'idle',
          showPopupInitially: false,
          popup: () =>
            IdlePopup({
              data: {
                ...item,
                event_duration,
                event_start_time_timestamp,
                event_end_time_timestamp,
              },
            }),
        });
        //push in events array also
        mapData.events.idle.push({
          event_start_time: moment(prevIdleObj?.timestamp * 1000).format(
            'hh:mm:ss A',
          ),
          event_end_time: moment(currentIdleObj?.timestamp * 1000).format(
            'hh:mm:ss A',
          ),
          event_start_time_timestamp: prevIdleObj?.timestamp,
          event_end_time_timestamp: currentIdleObj?.timestamp,
          timestamp: prevIdleObj?.timestamp,
          event_duration,
          location: `lat ${prevIdleObj?.latitude} lon ${prevIdleObj.longitude}`,
          event_name: 'Idle',
          coordinates: {
            latitude: currentIdleObj.latitude,
            longitude: currentIdleObj.longitude,
          },
        });
        currentIdleObj = null;
      } else {
        if (flag == 0) i++;
      }
      prevIdleObj = null;
    }

    if (currentIdleObj) {
      //if last item is in idle state
      let event_duration =
        secondsToHhMm(currentIdleObj?.timestamp - prevIdleObj?.timestamp) +
        '  ';
      mapData.idle_markers.push({
        coordinates: {
          latitude: currentIdleObj.latitude,
          longitude: currentIdleObj.longitude,
        },
        event_start_time_timestamp: prevIdleObj?.timestamp,
        event_end_time_timestamp: currentIdleObj?.timestamp,
        timestamp: prevIdleObj?.timestamp,
        duration: currentIdleObj?.timestamp - prevIdleObj?.timestamp,
        onlyMarker: true,
        url: `./assets/images/idle.svg`,
        type: 'idle',
        showPopupInitially: false,
        popup: () =>
          IdlePopup({
            data: {
              ...currentIdleObj,
              curr: currentIdleObj,
              event_duration,
              event_start_time_timestamp: prevIdleObj?.timestamp,
              event_end_time_timestamp: currentIdleObj?.timestamp,
            },
          }),
      });
    }

    //for start and endpoint
    if (sortedData?.length) {
      if (!isPark) {
        mapData.endPoints_markers.push({
          coordinates: {
            latitude: sortedData[0].latitude,
            longitude: sortedData[0].longitude,
          },
          timestamp: sortedData[0].timestamp,
          type: 'start',
          url: './assets/images/trip-icon-start.svg',
          showPopupInitially: true,
          popup: () => StartPopup({ data: { ...sortedData[0] } }),
          onlyMarker: true,
          // duration: sortedData[sortedData?.length - 1]?.timestamp - sortedData[0]?.timestamp,
        });
      }

      const activity_duration =
        secondsToHhMm(
          sortedData[sortedData?.length - 1]?.timestamp -
          sortedData[0]?.timestamp,
        ) + '  ';
      const activity_distance =
        (sortedData[sortedData?.length - 1]?.odo - sortedData[0].odo).toFixed(
          2,
        ) + ' km';
      mapData.endPoints_markers.push({
        coordinates: {
          latitude: sortedData[sortedData?.length - 1].latitude,
          longitude: sortedData[sortedData?.length - 1].longitude,
        },
        timestamp: sortedData[sortedData?.length - 1].timestamp,
        type: 'end',
        showPopupInitially: true,
        url: isPark
          ? './assets/images/trip-icon-park.svg'
          : './assets/images/trip-icon-end.svg',
        popup: !isPark
          ? () =>
            EndPopup({
              data: {
                ...sortedData[sortedData?.length - 1],
                activity_duration,
                activity_distance,
              },
            })
          : () =>
            ParkPopup({
              data: {
                ...sortedData[sortedData?.length - 1],
                activity_duration,
                activity_distance,
              },
            }),
        onlyMarker: true,
        // duration: sortedData[sortedData?.length - 1]?.timestamp - sortedData[0]?.timestamp,
      });
    }

    return {
      markers: [
        ...mapData.idle_markers,
        ...mapData.speeding_markers,
        ...mapData.polyline,
        ...mapData.endPoints_markers,
      ],
      events: mapData.events,
      endPoints_markers: mapData.endPoints_markers,
    };
  } catch (error) {
    return {
      markers: [],
      events: {},
    };
  }
};

export const timestampToHoursMinsSec = (timestamp) => {
  return {
    isAM: moment.unix(timestamp).format('a') == 'am',
    hour: moment.unix(timestamp).format('hh').toString(),
    minute: moment.unix(timestamp).format('mm').toString(),
    second: moment.unix(timestamp).format('ss').toString(),
  };
};
