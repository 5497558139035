import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { CallWithAuth } from '../../../action/apiActions';
import { TRIP_HISTORY_DATA } from '../../../action/apiPath';
import minusIcon from '../../../assets/svgs/minus.svg';
import plusIcon from '../../../assets/svgs/plus.svg';
import AuthLayout from '../../../components/AuthLayout';
import LoadingScreen from '../../../components/LoadingScreen';
import { secondsToHhMm } from '../../../utils/helper';
import AssetTrackingButtons from "./Buttons/AssetTrackingButtons";
import "./index.css";
import { Map } from './map';
import axios from 'axios';



const getTotalTrips = (activity) => {
  try {
    return activity.filter((activity) => activity?.activity_type === 'Trip')?.length;
  } catch (error) {
    return 0;
  }
}

const AssetTrackingLive = () => {
  const [assetsData, setAssetsData] = useState([])
  const [markers, setMarkers] = useState([])
  const [selectedAsset, setSelectedAsset] = useState({})
  const [selectedRow, setSelectedRow] = useState({})
  const [loading, setLoading] = useState(false)
  const source = useRef(axios.CancelToken.source());
  const mapRef = useRef(null); 
  const [zoomLevel, setZoomLevel] = useState(14)

  const handleZoom = (delta) => {
    let counter = 0;
    const interval = setInterval(() => {
      mapRef.current._impl.zoomLevel += delta;
      counter += 0.05;
      if (counter >= 1) {
        clearInterval(interval);
      }
    }, 20);
   setZoomLevel( mapRef?.current?._impl?.zoomLevel )
  };

  const handleMarkerClick = ({ marker, select }) => {
    setSelectedRow(marker)
    if (Object.keys(marker).length == 0){
      setSelectedAsset({})
    }
  };

  const getImageName = (asset) => {
    switch (true) {
      case asset?.activity_status == "Parked":
        return "marker-gray.svg";
      case asset?.activity_status == "Idling":
        return "marker-green.svg";
      case asset?.activity_status == "Active":
        return "marker-green-i.svg";
      default:
        return "marker-red.svg";
    }
  };

  function findTripIdleTime(idleMoments) {
    let idleSeconds = 0;
    if (!idleMoments.length) {
      return idleSeconds;
    }
    for (let i = 0; i < idleMoments.length; i++) {
      const element = idleMoments[i];
      if (element?.firstLocation?.timestamp && element?.lastLocation.timestamp) {
        idleSeconds = idleSeconds + (element?.lastLocation.timestamp - element?.firstLocation.timestamp);
      }
    }
    return idleSeconds;
  }

  const compare = (a, b) => {
    if (a.Status < b.Status)
      return -1;
    if (a.Status > b.Status)
      return 1;
    return 0;
  }

  const fetchTrucksLiveLocation = async ({ withoutLoading, cancelToken = "" }) => {
    withoutLoading ? console.log(null) : setLoading(true)
    try {
      const response = await CallWithAuth("POST", TRIP_HISTORY_DATA + "?live=true", {
        date: moment().format('YYYY-MM-DD')
      }, cancelToken);
      if (response?.res?.data.status === 200) {
        if (response?.res?.data?.data?.length > 0) {
          const assets = response?.res?.data?.data?.map((asset, index) => {

            let maxSpeed = 0
            let speedingMaxSpeed = 0
            let totalDistance = 0
            let totalIdleTime = 0
            let totalEngineHours = 0

            asset?.activity?.forEach((activity) => {
              if (activity?.maxSpeed > maxSpeed) { maxSpeed = activity?.maxSpeed; }
              if (activity?.maxSpeed > speedingMaxSpeed && activity?.maxSpeed >= 30) { speedingMaxSpeed = activity?.maxSpeed }
              totalDistance += (activity?.lastLocation?.odo - activity?.firstLocation?.odo)
              const activity_idleTime = findTripIdleTime(activity?.idleMoments)
              if ((activity_idleTime > 0) && activity.activity_type === "Trip") { totalIdleTime += activity_idleTime }
              if (activity.activity_type === "Trip") { totalEngineHours += activity?.lastLocation?.timestamp - activity?.firstLocation?.timestamp }
            });

            return {
              "Asset ID": asset?.asset_ID,
              coordinate: { latitude: asset?.latitude, longitude: asset?.longitude },
              "Category": asset?.category || 'Truck',
              title: asset?.asset_ID,
              notFound: asset?.notFound ?? false,
              subtitle: asset?.subtitle,
              url: `./assets/images/${getImageName(asset)}`,
              "Trips Today": getTotalTrips(asset.activity ?? []),
              "KM Today": totalDistance ? totalDistance?.toFixed(2) : 0,
              "Signal": asset?.activity[0]?.lastLocation?.css,
              // "Operator": asset?.operator,
              "Status": asset?.activity_status ?? "Disconnected",
              "Status Time": asset?.notFound ? moment((asset?.status_time ?? 0) * 1000).format('DD/MM/YY HH:mm:ss') : moment((asset?.status_time ?? 0) * 1000).format('HH:mm') + ' hrs',
              "Today": {
                "Distance": asset?.activity[0]?.lastLocation?.odo - asset?.activity[asset?.activity?.length - 1]?.firstLocation?.odo,
                "Engine hours": secondsToHhMm(totalEngineHours ?? 0) + ' ',
                "Max Speed": maxSpeed + ' km/h',
                "Alerts": {
                  "Idle time": secondsToHhMm(totalIdleTime) + ' ',
                  "Speeding": (speedingMaxSpeed) + ' km/h',
                },
              },
            };
          });
          if (JSON.stringify(assetsData) != JSON.stringify(assets)) {
            if (assets?.length)
              assets.sort(compare);
            setAssetsData(assets);
          }
        }
      } else {
        setLoading(true);
      }
    } catch (error) {
      setLoading(true);
      console.log("error", error);
      toast.error(error)
    }
    withoutLoading ? console.log(null) : setLoading(false)
  }

  useEffect(() => {
    setMarkers(assetsData);
  }, [assetsData])

  useEffect(() => {
    fetchTrucksLiveLocation({ withoutLoading: false, cancelToken: source.current.token });
    const fetchData = () => {
      fetchTrucksLiveLocation({ withoutLoading: true, cancelToken: source.current.token });
    };
    fetchData();
    const intervalId = setInterval(fetchData, 120000);
    return () => {
      source.current.cancel('Request canceled by cleanup');
      clearInterval(intervalId);
    };
  }, []);


  return (
    <>
      {loading && <LoadingScreen />}
      <div className="d-flex flex-column position-relative" style={{ height: 'calc(100% - 6rem)', backgroundColor: '#DDDDDD', overflowY: 'hidden', objectFit: 'cover' }}>

        <Map
          selectedMarker={selectedAsset}
          markers={markers}
          zoomLevel={zoomLevel}
          onMarkerClick={handleMarkerClick}
          mapRef={mapRef}
          getImageName={getImageName}
        />

        <AssetTrackingButtons
          selectedAsset={selectedRow}
          onSelectAsset={(asset) => {
            setSelectedAsset(asset)
            setSelectedRow(asset)
          }}
          data={assetsData}
          onStatusChange={(markers) => {
            setMarkers(markers)
          }}
        />

        <div style={{ bottom: "-1.5rem", marginBottom: '2rem', position: 'absolute', zIndex: 1, right: "1rem", backgroundColor: 'white' }}>
          <div className="d-flex align-items-center justify-content-center cursor-pointer p-2" onClick={() => handleZoom(0.05)}>
            <img src={plusIcon} alt="Zoom In" style={{ width: "2rem", height: "2rem" }} />
          </div>
          <div className="d-flex align-items-center justify-content-center cursor-pointer p-2" onClick={() => handleZoom(-0.05)}>
            <img src={minusIcon} alt="Zoom Out" style={{ width: "1.4rem", height: "1.5rem" }} />
          </div>
        </div>

        <div
          style={{
            bottom: "-1.5rem",
            marginBottom: "2rem",
            position: "absolute",
            zIndex: 1,
            right: "4.4rem",
            backgroundColor: "white",
            borderRadius: "4px ",
          }}
        >
          <div
            style={{
              cursor: "pointer",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "3rem",
            }}
            onClick={(e) => {
              e.preventDefault();
              mapRef.current._impl.zoomLevel = 1;
            }}
          >
            <div style={{ fontSize: "1rem" }} className="p-2 px-2">
              Fit Screen
            </div>
          </div>
        </div>

      </div>
    </>
  );
};

export default AssetTrackingLive;
