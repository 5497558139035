import React from "react";

export default function DataPoint(props) {
    const { color, label, data, selected, onSelected } = props;
    return (
        // <div
        //     onClick={() => {
        //         if (onSelected) {
        //             onSelected();
        //         }
        //     }}
        //     className="d-flex align-items-center px-3"
        //     style={{
        //         width: "100%",
        //         height: "1.25rem",
        //         backgroundColor: selected ? "#F2F7FF" : "white",
        //         cursor: onSelected ? "pointer" : "initial",
        //         padding: "0.719rem 0",
        //         paddingRight: "1.563rem",
        //     }}
        // >
        //     <div
        //         className="mr-2"
        //         style={{
        //             backgroundColor: "transparent",
        //             borderRadius: "50%",
        //             width: "1rem",
        //             height: "1rem",
        //             borderWidth: 1,
        //             display: "flex",
        //             borderStyle: "solid",
        //             alignItems: "center",
        //             justifyContent: "center",
        //             borderColor: selected ? color : "transparent",
        //         }}
        //     >
        //         <span
        //             style={{
        //                 backgroundColor: color,
        //                 borderRadius: "50%",
        //                 width: "0.625rem",
        //                 height: "0.625rem",
        //                 margin: 0,
        //                 padding: 0,
        //             }}
        //         ></span>
        //     </div>
        //     <span
        //         style={{
        //             color: selected ? "black" : "#5F6672",
        //             fontWeight: selected ? "700" : "600",
        //             fontSize: "0.625rem",
        //             width: "18rem",
        //         }}
        //     >
        //         {label}
        //     </span>
        //     <span
        //         style={{
        //             fontSize: "0.625rem",
        //             color: !onSelected || selected ? "black" : "#5F6672",
        //             fontWeight: !onSelected || selected ? "700" : "600",
        //         }}
        //     >
        //         {data ? data : "00:00 h"}
        //     </span>
        // </div>

        <div
            onClick={() => {
                if (onSelected) {
                    onSelected();
                }
            }}
            className="row d-flex"
            style={{
                lineHeight:2,
                // backgroundColor: selected ? "#F2F7FF" : "white",
                cursor: onSelected ? "pointer" : "initial",
            }}
        >
            <div className="col-8 d-flex align-items-center">
                <div className="mr-2"
                    style={{
                        borderRadius: "50%",
                        backgroundColor: color,
                        width: '0.5rem',
                        height: '0.5rem',
                    }}
                />

                <div
                    style={{
                        color: selected ? "black" : "#5F6672",
                        fontWeight: selected ? "700" : "600",
                        fontSize: "0.725rem",
                    }}
                >
                    {label}
                </div>
            </div>
            <div className="col-4"
                style={{
                    fontSize: "0.78rem",
                    color: !onSelected || selected ? "black" : "#5F6672",
                    fontWeight: !onSelected || selected ? "700" : "600",
                }}
            >
                {data ? data : "00:00 h"}
            </div>
        </div>
    );
}
