import React, { useEffect, useState } from 'react';
import AssetsModal from './AssetsModal';
import Button from "./Button";
import "./index.css";


const AssetTrackingButtons = ({ onStatusChange, selectedAsset, data = [], onSelectAsset = () => { } }) => {
  const [activeType, setActiveType] = useState(0); // ["All", "Active", "Disconnected", "Parked"]
  const [assets, setAssets] = useState(data);

  const getStatusData = (status) => data.filter(item => item.Status === status);

  const DataLength = data.length;
  const ActiveDataLength = getStatusData('Active').concat(getStatusData('Idling'))?.length;
  const DisconnectedDataLength = getStatusData('Disconnected').length;
  const PARKEDDataLength = getStatusData('Parked')?.length;

  const handleCloseModal = () => {
    setActiveType(0);
  };

  useEffect(() => {

    if (activeType != 0) {
      onSelectAsset({})
      const type = ["All", "Active", "Disconnected", "Parked"][activeType - 1]
      if (type === "All") {
        onStatusChange(data)
        setAssets(data)
      }
      else if (type === "Active") {
        const assetsData = data.filter(item => item.Status === "Active" || item.Status === "Idling")
        setAssets(assetsData);
        onStatusChange(assetsData)
      } else {
        const assetsData = data.filter(item => item.Status === type)
        setAssets(assetsData);
        onStatusChange(assetsData)
      }
    }
  }, [data, activeType])


  return (
    <div className="mm-body">
      <div className="mm-top_heading">
        <div className="d-flex" style={{ gap: "0.938rem", position: "fixed", top: "10.375rem", }}>
          {[
            { title: "View all assets", length: DataLength },
            { title: "Active", length: ActiveDataLength, color: "#CFFD69" },
            { title: "Disconnected", length: DisconnectedDataLength, color: "#ED5151" },
            { title: "Parked", length: PARKEDDataLength, color: "#BEB6B6" },
          ].map((button, index) => (
            <Button
              key={index}
              DataLength={button.length}
              onClick={() => { setActiveType(index + 1) }}
              title={button.title}
              active={activeType == index + 1}
              style={index !== 0 ? { borderLeftColor: button.color, borderLeft: "0.625rem solid " + button.color, borderWidth: "0.0rem 0.0rem 0.0rem 0.625rem"} : {borderWidth: "0.0rem 0.0rem 0.0rem 0.0rem"}}
            />
          ))}
        </div>

        <div>
          {[1, 2, 3, 4].map((type) => (
            activeType === type && (
              <AssetsModal
                onSelectRow={(asset) => { onSelectAsset(asset) }}
                selectedRow={selectedAsset}
                key={type}
                data={assets}
                onClose={handleCloseModal}
                type={["All", "Active", "Disconnected", "Parked"][type - 1]}
              />
            )
          ))}
        </div>
      </div>
    </div>
  )
}

export default AssetTrackingButtons;