import { useState, useEffect } from "react";
import ReactEcharts from "echarts-for-react";
import { getHourMinuteDifference } from "../helper";
import ChartHover from "./speedingEvents/ChartHover";
import DelaysChartHoverDetails from "./speedingEvents/ChartHover";
import { speedingEventsTypesCostData } from "../constData";

export default function DelaysMontlyYearlyGraph({ data, categories, choice, linesSelected, fromDetail }) {
  const [isOpen, setIsOpen] = useState(false);
  const [cardContent, setCardContent] = useState({});
  const [coordinates, setCoordinates] = useState({});
  const [graphPosition, setGraphPosition] = useState({});
  const [showDetails, setShowDetails] = useState(false);

  const handlePointClick = (params) => {
    const index = params.dataIndex;

    const selectedData = data[index];
    const cardObject = {
      date: selectedData.date,
      total: selectedData.total,
      total_20: selectedData.total_20,
      total_30: selectedData.total_30,
      total_50: selectedData.total_50,
    };
    const coordinate = {
      x: params.event.offsetX,
      y: params.event.offsetY
    };

    setCoordinates(coordinate);
    setCardContent(cardObject);
    setIsOpen(true);
  };

  const getLineVariable = (variable) => {
    if (variable == '>50') {
      return 'total_50';
    } else if (variable == '30-50') {
      return 'total_30';
    } else if (variable == '20-30') {
      return 'total_20';
    }
  }

  const getLineColor = (variable) => {
    for (let i = 0; i < speedingEventsTypesCostData.length; i++) {
      if (speedingEventsTypesCostData[i].label == variable) {
        return speedingEventsTypesCostData[i].color;
      }
    }
  }

  const prepareLineForSelectedSpeedings = () => {
    let seriesLines = [];
    const defaultLines = linesSelected?.length ? linesSelected : speedingEventsTypesCostData.map(e => e.label);
    if (defaultLines?.length) {
      for (let i = 0; i < defaultLines.length; i++) {
        const lineSelected = defaultLines[i];
        seriesLines.push({
          type: "line",
          name: "Line " + (i + 1),
          data: data?.map(d => d[getLineVariable(lineSelected)]),
          showBackground: true,
          showSymbol: false,
          label: {
            show: false,
            fontSize: "10px",
            position: "outside",
            fontWeight: "600",
            color: getLineColor(lineSelected),
          },
          emphasis: {
            focus: "series",
          },
          itemStyle: {
            color: getLineColor(lineSelected),
          },
        });
      }
    }
    return seriesLines;
  }

  useEffect(() => {
    const graphElement = document.querySelector(".graphContainer");
    const rect = graphElement.getBoundingClientRect();
    setGraphPosition({
      top: rect.top + window.scrollY,
      left: rect.left + window.scrollX,
      width: rect.width,
      height: rect.height,
    });

    const handleClickOutside = (event) => {
      const chartHoverElement = document.getElementById("popup");
      if (
        chartHoverElement &&
        !chartHoverElement.contains(event.target) &&
        !document.getElementById("popup").contains(event.target)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const onEvents = {
    click: handlePointClick,
  };

  const option = {
    tooltip: {
      trigger: "axis",
      formatter: (params) => {
        return "";
      },
    },
    // Configuration for the grid
    grid: {
      left: "60px",
      right: "4.5%",
      bottom: "5px",
      height: "85%",
      // width: '90%',
      paddinTop: 0,
      containLabel: true,
      backgroundColor: "#f5f5f5", // Background color for the grid
    },
    // Configuration for the X-axis
    xAxis: {
      name: "Date",
      nameLocation: "end",
      nameGap: 6,
      // alignTicks: false,
      axisTick: {
        show: false,
      },
      nameTextStyle: {
        fontFamily: "Open Sans",
        fontWeight: "bold",
        fontSize: "12px",
        color: "#728BB5",
      },
      axisPointer: {
        type: "shadow",
      },
      axisLabel: {
        color: "#576B8D",
        // lineHeight: 150
        margin: 20,
        clickable: true,
      },
      data: categories,
    },
    // Configuration for the Y-axis
    yAxis: {
      show: true,
      name: "Event Count",
      nameLocation: "middle",
      nameGap: 55,
      axisLabel: {
        show: true,
        // Add the label for the Y-axis here
      },
      nameTextStyle: {
        fontFamily: "Open Sans",
        fontWeight: "bold",
        fontSize: "12px",
        color: "#728BB5",
      },
    },
    // Configuration for the series (data)
    series: prepareLineForSelectedSpeedings(),
  };

  return (
    <div
      className="graphContainer"
      style={{ height: "100%", width: "100%" }}
    >
      <ReactEcharts option={option} style={{ height: 310 }} onEvents={onEvents} />
      {isOpen && (
        <div id="popup">
          <ChartHover
            setIsOpen={setIsOpen}
            data={cardContent}
            coordinate={coordinates}
            setShowDetails={setShowDetails}
            fromDetail={fromDetail}
          />
        </div>
      )}
      {showDetails && (
        <DelaysChartHoverDetails
          month={data.month}
          data={cardContent}
          closeviewDetails={setShowDetails}
          coordinate={coordinates}
        />
      )}
    </div>
  );
}
