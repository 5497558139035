import moment from 'moment';
import ReactDOM from 'react-dom';


export const ParkPopup = ({ data }) => {
    const div = document.createElement('div');
    const popup = (
        <div>

            <div className='park_popup ' style={{
                border: "2px solid #000", position: 'relative'
            }}>
                <img style={{ position: 'absolute', right: -18, top: -12, cursor: "pointer" }} src='./assets/images/cross.svg' />
                <div style={{ lineHeight: 1.5 }}>
                    <div className='row d-flex'>
                        <div className='col-5'>Parked Time:</div>
                        <div className='col-7' style={{ fontWeight: 600 }}>{moment(data?.timestamp * 1000).format('hh:mm A DD/MM/yyyy')}</div>
                    </div>
                    <div className='row d-flex'>
                        <div className='col-5'>Duration:</div>
                        <div className='col-7' style={{ fontWeight: 600 }}>{data?.activity_duration}</div>
                    </div>
                </div>

                <div className='popup-tip-container_park_popup_border' />
                <div className="popup-tip-container_start_popup" />
            </div>
        </div>
    );

    ReactDOM.render(popup, div);
    return div;
};