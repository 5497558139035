import React from 'react';
// import TrendArrow from './TrendArrow';

const Progress_bar = ({
  progress,
  color,
  height,
  width,
  showTarget
}) => {
  const Parentdiv = {
    width: width || '100%',
    backgroundColor: 'white',
    borderRadius: 50,
    display: 'flex',
    height: height || '0.9rem',
    backgroundColor: '#DBDBDB',
    zIndex: 10,
    position: 'relative',
    alignItems: 'center',
    position: 'relative',
  };

  const Childdiv = {
    height: '100%',
    width: `${showTarget ? Math.min(4 * progress / 5, 100) : Math.min(progress, 100)}%`,
    backgroundColor: color,
    borderRadius: 50,
    textAlign: 'right',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    alignSelf: 'center',
    position: 'absolute',
    left: 0,
  };

  return (
    <div style={Parentdiv} className="border">
      <div style={Childdiv} />

      {showTarget && <div
        style={{
          height: height - 2,
          backgroundColor: 'black',
          width: '3px',
          position: 'absolute',
          left: "calc(100% * 4 / 5)"
        }}
      ></div>}
    </div >
  );
};

export default Progress_bar;
