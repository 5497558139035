import { useState, useRef, useEffect } from 'react';
import AuthLayout from '../../components/AuthLayout';
import CRUDModal from './subcomponents/CRUDModal/CRUDModal';
import Header from './subcomponents/Header/Header';
import Table from './subcomponents/Table/Table';
import { CallWithAuth } from 'action/apiActions';
import { toast } from 'react-toastify';
import moment from 'moment-timezone';
import axios from 'axios';
const MODULE_START_YEAR = 2024

const DigRates = () => {
    const [mainTableData, setMainTableData] = useState([])
    const [year, setYear] = useState(moment().tz("Pacific/Auckland").year().toString());
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedAsset, setSelectedAsset] = useState(null);
    const [viewOnly, setViewOnly] = useState(true)
    const [isCRUDModalOpen, setisCRUDModalOpen] = useState(false);
    const [savedChanges, setSavedChanges] = useState(null)
    const [loading, setLoading] = useState(true);
    const [yearRange, setYearRange] = useState(null);

    const handleYearChange = (value) => {
        setYear(value);
    };

    const handleSearchChange = (value) => {
        setSearchTerm(value);
        setisCRUDModalOpen(false)
    };

    const handleRowClick = (asset, editMode) => {
        setSelectedAsset(asset);
        setisCRUDModalOpen(true);
        setSavedChanges(false)
        if (editMode)
            setViewOnly(false)
    };

    // Fetch data based on year and yearRange
    useEffect(() => {
        const cancelTokenSource = axios.CancelToken.source();

        const fetchData = async () => {
            try {
                setLoading(true);
                const response = await CallWithAuth("GET", "api/bcmHr" + "?year=" + year, cancelTokenSource.token);
                setMainTableData(response?.res?.data);
                setisCRUDModalOpen(false);
                setLoading(false);
            } catch (error) {
                if (axios.isCancel(error)) {
                    console.log('Request canceled:', error.message);
                } else {
                    console.log(error);
                    toast(error.message);
                }
            }
        };

        if (yearRange) {
            fetchData();
        }

        // Cleanup to cancel the request if component unmounts or yearRange changes
        return () => {
            cancelTokenSource.cancel("Component unmounted or yearRange changed");
        };
    }, [year, yearRange]);

    // Fetch data based on saved changes
    useEffect(() => {
        const cancelTokenSource = axios.CancelToken.source();

        const fetchData = async () => {
            try {
                const response = await CallWithAuth("GET", "api/bcmHr" + "?year=" + year, cancelTokenSource.token);
                setMainTableData(response?.res?.data);
            } catch (error) {
                if (axios.isCancel(error)) {
                    console.log('Request canceled:', error.message);
                } else {
                    console.log(error);
                    toast(error.message);
                }
            }
        };

        if (savedChanges !== null) {
            fetchData();
        }

        // Cleanup to cancel the request if component unmounts or savedChanges changes
        return () => {
            cancelTokenSource.cancel("Component unmounted or savedChanges changed");
        };
    }, [savedChanges]);

    // Fetch year range data when the component mounts
    useEffect(() => {
        const cancelTokenSource = axios.CancelToken.source();

        const fetchData = async () => {
            try {
                const response = await CallWithAuth("GET", "api/bcmHr/year", cancelTokenSource.token);
                setYearRange(response?.res?.data);
            } catch (error) {
                if (axios.isCancel(error)) {
                    console.log('Request canceled:', error.message);
                } else {
                    console.log(error);
                    toast(error.message);
                }
            }
        };

        fetchData();

        // Cleanup to cancel the request if component unmounts
        return () => {
            cancelTokenSource.cancel("Component unmounted");
        };
    }, []);

    return (
        <AuthLayout pageName="Dig Rates">
            <div className="right_sidebar_body" id="main">
                <div className='pt-2 px-4' style={{ height: "100%", overflow: "hidden", backgroundColor: 'white' }}>
                    <Header
                        yearRange={yearRange}
                        searchTerm={searchTerm}
                        handleSearchChange={handleSearchChange}
                        year={year}
                        handleYearChange={handleYearChange}
                    />
                    <Table
                        loading={loading}
                        totalAssets={mainTableData?.length}
                        filteredAssets={mainTableData?.filter((d) =>
                            d?.equipment?.Unit.toLowerCase().includes(searchTerm.toLowerCase()) || d?.equipment?.model?.toLowerCase().includes(searchTerm.toLowerCase())
                        )}
                        onClose={!isCRUDModalOpen}
                        handleRowClick={handleRowClick}
                    />
                    {isCRUDModalOpen && (
                        <CRUDModal
                            setSavedChanges={setSavedChanges}
                            isOpen={isCRUDModalOpen}
                            onCancel={() => setisCRUDModalOpen(false)}
                            editMode={viewOnly}
                            selectedAsset={selectedAsset}
                            year={year}
                            MODULE_START_YEAR={MODULE_START_YEAR}
                        />
                    )}

                </div>

            </div>
        </AuthLayout>
    );
};

export default DigRates;
