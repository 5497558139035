import React, { useState } from "react";
import { months } from "../../constData";
import {
    delaysViewDetailsTableHeader,
} from "../../constData";
import "../../style.css";
import { getHourMinuteDifference } from "../../helper";

const DelaysChartHoverDetails = ({ month, data, closeviewDetails, coordinate }) => {
    const [toggle, setToggle] = useState("dayData");
    const handleCloseViewDetails = () => {
        closeviewDetails(false);
    };
    const handleToggle = (toggle) => {
        setToggle(toggle);
    };
    return (
        <div
            style={{
                minWidth: "40.625rem",
                position: "absolute",
                top: `${coordinate.y + 100}px`,
                left: `${coordinate.x + 30}px`,
                background: "#FFFFFF",
                border: "0.063rem solid #E1E8F4",
                borderRadius: "0.5rem",
                zIndex: 2000,
            }}
            className="p-4 pt-1"
        >
            <div className=" p-0 d-flex justify-content-between">
                <div>
                    {data.date} {data.month}
                </div>
                <div
                    style={{
                        color: "#092344",
                        fontSize: "1.5rem",
                        cursor: "pointer",
                    }}
                    onClick={handleCloseViewDetails}
                >
                    ×
                </div>
            </div>
            <div className="p-0 mt-2 d-flex flex-column">
                <div
                    style={{
                        fontSize: "0.875rem",
                        color: "#1A1A1A",
                    }}
                >
                    <span
                        style={{
                            fontWeight: "600",
                        }}
                    >
                        Delays -
                    </span>{" "}
                    <span
                        style={{
                            fontWeight: "bold",
                        }}
                    >
                        {getHourMinuteDifference(0, data.total * 1000)}
                    </span>
                </div>
                <div
                    style={{
                        fontSize: "0.75rem",
                        color: "#576B8D",
                        fontStyle: "italic",
                        lineHeight: "0.5",
                    }}
                >
                    Trucks
                </div>
            </div>
            <div
                className="d-flex p-0 delaysViewDetailstoogleButton jusitfy-content-start"
                style={{
                    fontSize: "0.75rem",
                }}
            >
                <div>
                    <button
                        className={`delaysToggleButton ${toggle == "dayData" ? "active" : ""
                            } mr-2`}
                        onClick={() => handleToggle("dayData")}
                    >
                        <div className=" d-flex justify-content-center align-items-center lh-2">
                            Day - {getHourMinuteDifference(0, data.dayData.total * 1000)} h
                        </div>
                    </button>
                </div>
                <div>
                    <button
                        className={`delaysToggleButton ${toggle == "nightData" ? "active" : ""
                            }`}
                        onClick={() => handleToggle("nightData")}
                    >
                        <div className=" d-flex justify-content-center align-items-center lh-2">
                            Night - {getHourMinuteDifference(0, data.nightData.total * 1000)} h
                        </div>
                    </button>
                </div>
            </div>
            <div
                className="p-0 col-11 d-flex my-3"
                style={{
                    fontSize: "0.625rem",
                }}
            >
                <div
                    className="col-6"
                    style={{
                        borderRight: "0.063rem solid #0000001A",
                    }}
                >
                    <div className="row delaysViewDetailsrow">
                        <div className="col-9 p-0 delaysViewDetailsLabel">
                            Idle Time Delays (ITD)
                        </div>
                        <div className="col-3 p-0 dealysValue">
                            {getHourMinuteDifference(0, data?.[toggle].delays.ITD * 1000)} h
                        </div>
                    </div>
                    <div className="row delaysViewDetailsrow">
                        <div className="col-9 p-0 delaysViewDetailsLabel">
                            Weather Delay (WD, D1)
                        </div>
                        <div className="col-3 p-0 dealysValue">
                            {getHourMinuteDifference(0, data?.[toggle].delays.WD * 1000)} h
                        </div>
                    </div>
                    <div className="row delaysViewDetailsrow">
                        <div className="col-9 p-0 delaysViewDetailsLabel">
                            Maintenance Downtime (MD)
                        </div>
                        <div className="col-3 p-0 dealysValue">
                            {getHourMinuteDifference(0, data?.[toggle].delays.MD * 1000)} h
                        </div>
                    </div>
                </div>
                <div className="col-6 ml-5">
                    <div className="row delaysViewDetailsrow">
                        <div className="col-9 p-0 delaysViewDetailsLabel">
                            Planned Operational Delays (POD)
                        </div>
                        <div className="col-3 p-0 dealysValue">
                            {getHourMinuteDifference(0, data?.[toggle].delays.POD * 1000)} h
                        </div>
                    </div>
                    <div className="row delaysViewDetailsrow">
                        <div className="col-9 p-0 delaysViewDetailsLabel">
                            Unplanned Delays (UD)
                        </div>
                        <div className="col-3 p-0 dealysValue">
                            {getHourMinuteDifference(0, data?.[toggle].delays.UD * 1000)} h
                        </div>
                    </div>
                    <div className="row delaysViewDetailsrow">
                        <div className="col-9 p-0 delaysViewDetailsLabel">
                            Scheduled Non-Operating Time(SNO)
                        </div>
                        <div className="col-3 p-0 dealysValue">
                            {getHourMinuteDifference(0, data?.[toggle].delays.SNO * 1000)} h
                        </div>
                    </div>
                </div>
            </div>
            <div
                style={{
                    borderTop: "0.063rem solid #0000001A",
                }}
            >
                <div className="table pl-3  mt-3">
                    <div
                        className="table-header row"
                        style={{
                            fontSize: "0.625rem",
                        }}
                    >
                        {delaysViewDetailsTableHeader.map((header, index) => (
                            <div
                                key={index}
                                style={{
                                    color: "#576B8D",
                                    width: "12.5%",
                                }}
                            >
                                {header}
                            </div>
                        ))}
                    </div>
                    <div className="table-body">
                        {data[toggle].equipmentData?.map((rowData, rowIndex) => (
                            <div className="table-row row" key={rowIndex}>
                                <div
                                    style={{
                                        width: "12.5%",
                                        fontSize: "0.75rem",
                                        color: "#1A1A1A",
                                    }}
                                >
                                    {rowData?.Unit}
                                </div>
                                <div

                                    style={{
                                        width: "12.5%",
                                        fontSize: "0.75rem",
                                        color: "#1A1A1A",
                                    }}
                                >
                                    {getHourMinuteDifference(0, rowData.delays.ITD * 1000)}
                                </div>
                                <div

                                    style={{
                                        width: "12.5%",
                                        fontSize: "0.75rem",
                                        color: "#1A1A1A",
                                    }}
                                >
                                    {getHourMinuteDifference(0, rowData.delays.WD * 1000)}
                                </div>
                                <div

                                    style={{
                                        width: "12.5%",
                                        fontSize: "0.75rem",
                                        color: "#1A1A1A",
                                    }}
                                >
                                    {getHourMinuteDifference(0, rowData.delays.MD * 1000)}
                                </div>
                                <div

                                    style={{
                                        width: "12.5%",
                                        fontSize: "0.75rem",
                                        color: "#1A1A1A",
                                    }}
                                >
                                    {getHourMinuteDifference(0, rowData.delays.POD * 1000)}
                                </div>
                                <div

                                    style={{
                                        width: "12.5%",
                                        fontSize: "0.75rem",
                                        color: "#1A1A1A",
                                    }}
                                >
                                    {getHourMinuteDifference(0, rowData.delays.UD * 1000)}
                                </div>
                                <div

                                    style={{
                                        width: "12.5%",
                                        fontSize: "0.75rem",
                                        color: "#1A1A1A",
                                    }}
                                >
                                    {getHourMinuteDifference(0, rowData.delays.SNO * 1000)}
                                </div>
                                <div

                                    style={{
                                        width: "12.5%",
                                        fontSize: "0.75rem",
                                        color: "#1A1A1A",
                                    }}
                                >
                                    {getHourMinuteDifference(0, rowData.total * 1000)}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DelaysChartHoverDetails;
