import React from "react";
import { CSVLink } from "react-csv";
import { FiDownload } from "react-icons/fi";

const DownloadButton = ({ handleDownloadPDF }) => {
    return <FiDownload
        onClick={handleDownloadPDF}
        style={{
            color: 'white',
            cursor: 'pointer',
            backgroundColor: 'black',
            borderRadius: '1.25rem',
            padding: '.4rem',
            width: '2.1rem',
            height: '2.1rem',
            border: '0.094rem solid rgb(225,232,244)',
        }}
    />
}

export default DownloadButton;
