import { useEffect, useState } from "react";
import styles from "./Table.module.css";
import ActionMenu from "pages/ShiftPlanning/components/ActionMenu/ActionMenu";
import { Riple } from "react-loading-indicators";

const Table = ({ totalAssets, filteredAssets, handleRowClick, onClose, loading }) => {
    const [selectedActionRow, setSelectedActionRow] = useState(-1);
    const [modalOpenRow, setModalOpenRow] = useState(-1);
    useEffect(() => {
        if (onClose) setModalOpenRow(-1);
    }, [onClose]);

    return (
        <div className={styles.wrapper}>
            <div className={styles.tableContainer}>
                {loading ? <div style={{ height: '100%', width: '100%' }} className="d-flex align-items-center justify-content-center">
                    <Riple color="#317bcc" size="medium" text="" textColor="" />
                </div> :
                    <table tabIndex={0} className={`${styles.mainTable}`}>
                        <thead>
                            <tr>
                                <th colSpan="1" className={styles.assetIdHeader}>
                                    Asset ID
                                </th>
                                <th colSpan="12" className={styles.monthHeader}>
                                    Monthly Targets (bcm/hr)
                                </th>
                                <th colSpan="1" className={styles.actionsHeader}>
                                    Actions
                                </th>
                            </tr>
                            <tr
                                style={{
                                    position: "sticky",
                                    top: 0,
                                    zIndex: 5,
                                    ...(modalOpenRow === 0
                                        ? {
                                            borderBottom: "1px solid #186FD9",
                                        }
                                        : {
                                            borderBottom: "1px solid #e1e8f4",
                                        }),
                                }}
                            >
                                <th
                                    style={{ borderRight: "1px solid #e1e8f4" }}
                                    className={styles.emptySection}
                                ></th>
                                {[
                                    "Jan",
                                    "Feb",
                                    "Mar",
                                    "Apr",
                                    "May",
                                    "Jun",
                                    "Jul",
                                    "Aug",
                                    "Sep",
                                    "Oct",
                                    "Nov",
                                    "Dec",
                                ].map((month) => (
                                    <th key={month} className={styles.monthHeader}>
                                        {month}
                                    </th>
                                ))}
                                <th
                                    style={{ borderLeft: "1px solid #e1e8f4" }}
                                    className={styles.emptySection}
                                ></th>
                            </tr>
                        </thead>
                        <tbody>
                            {filteredAssets.map((asset, index) => (
                                <tr
                                    key={asset?.equipment?._id}
                                    onClick={() => {
                                        setSelectedActionRow("");
                                        handleRowClick(asset?.equipment);
                                        setModalOpenRow(index);
                                    }}
                                    style={
                                        modalOpenRow === index
                                            ? {
                                                borderBottom: "1px solid #186FD9",
                                                backgroundColor: "#F2F7FF",
                                            }
                                            : modalOpenRow === index + 1
                                                ? { borderBottom: "1px solid #186FD9" }
                                                : selectedActionRow === asset?.equipment?._id
                                                    ? { backgroundColor: "#E2ECFC" }
                                                    : {}
                                    }
                                    className={styles.bodyRow}
                                >
                                    <td
                                        style={
                                            selectedActionRow == asset?.equipment?._id
                                                ? {
                                                    backgroundColor: "#E2ECFC",
                                                }
                                                : modalOpenRow == index
                                                    ? {
                                                        backgroundColor: "#F2F7FF",
                                                    }
                                                    : {}
                                        }
                                        className={styles.assetId}
                                    >
                                        {asset?.equipment?.Unit} {asset?.equipment?.model}
                                    </td>
                                    {Object.values(asset?.yearlyData[0]?.monthlyData ?? {}).map(
                                        (value, idx) => (
                                            <td key={idx} className={styles.targetValue}>
                                                {value ?? "-"}
                                            </td>
                                        )
                                    )}
                                    <td className={styles.actionsCell}>
                                        <div className="d-flex align-items-center justify-content-center">
                                            <ActionMenu
                                                isOpen={selectedActionRow == asset?.equipment?._id}
                                                options={[
                                                    {
                                                        name: "View Details",
                                                        onClick: () => {
                                                            setSelectedActionRow("");
                                                            handleRowClick(asset?.equipment);
                                                            setModalOpenRow(index);
                                                        },
                                                    },
                                                    {
                                                        name: "Edit",
                                                        onClick: () => {
                                                            setSelectedActionRow("");
                                                            setModalOpenRow(index);
                                                            handleRowClick(asset?.equipment, true);
                                                        },
                                                    },
                                                    {
                                                        name: "Download PDF",
                                                        disabled: true,
                                                    },
                                                    {
                                                        name: "Download CSV",
                                                        disabled: true,
                                                    },
                                                ]}
                                                onClick={() =>
                                                    setSelectedActionRow(asset?.equipment?._id)
                                                }
                                                optionClass={"py-0"}
                                            />
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>}
            </div>
            <div
                style={{
                    position: "absolute",
                    bottom: 0,
                    backgroundColor: "white",
                    font: " normal normal normal 12px/18px Open Sans",
                    width: "100%",
                    color: "#6A7C9A",
                    borderBottomLeftRadius: 10,
                    borderBottomRightRadius: 10,
                }}
            >
                <div
                    style={{ borderTop: "1.5px solid #E1E8F4" }}
                    className="pt-3 pb-4 px-4"
                >
                    Displaying {filteredAssets?.length}/{totalAssets} Assets
                </div>
            </div>
        </div>
    );
};

export default Table;
