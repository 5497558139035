import React from "react";
import '../../style/SpeedingEvents/SpeedingEventsPieCharts.css'


export default function SpeedingEventList(props) {
    const { color, label, data, selected, onSelected } = props;
    return (
        <div className="d-flex justify-content-between px-3" >
            <div className="d-flex align-items-center justify-content-between" >
                <input
                    type="checkbox"
                    id="x"
                    className="mr-2"
                    style={{
                        backgroundColor: color,
                        accentColor: color,
                    }}
                    checked={selected.includes(label)}
                    onChange={(e) => onSelected(label)}
                />
                <label style={{ border: "1px " }} for="x"></label>
                <div style={{ fontSize: "0.8rem", }} >
                    {label ? label : "31 - 50 (km/h)"}
                </div>
            </div>

            <div style={{ fontSize: "0.8rem", }} >
                {data ? data : "0"}
            </div>
        </div>
    );
}
