import ReactEcharts from "echarts-for-react";
// import { clickChartGraph } from "../helper";
import { getHourMinuteDifference } from "../helper";
import SpeedingEventsChart from './speedingEvents/SpeedingEventsChart'
import { dumpCycleChartHover } from "../helper";

export default function DumpCycleDurationSectionGraph({ title, data, graphObject, mouse }) {


  const handlePointClick = (params) => {
    console.log(params);
  }

  const onEvents = {
    click: handlePointClick,
  };

  const option = {
    // Configuration for the tooltip
    tooltip: {
      trigger: 'axis',
      backgroundColor: "white",
      borderColor: "#E1E8F4",
      padding: 0,
      borderRadius: 8,
      formatter: (x) => mouse === 'hover' ? dumpCycleChartHover(x, data, 3600) : null
    },
    // Configuration for the grid
    grid: {
      left: '5%',
      // right: "7.5%",
      bottom: "10%",
      height: "80%",
      width: '90%',
      paddinTop: 0,
      containLabel: true,
      backgroundColor: "#f5f5f5",
    },
    // Configuration for the X-axis
    xAxis: {
      name: graphObject?.xName,
      nameLocation: "end",
      nameGap: 6,
      alignTicks: false,
      axisTick: {
        show: false,
      },
      nameTextStyle: {
        fontFamily: "Open Sans",
        fontWeight: "bold",
        fontSize: "0.625rem",
        color: "#728BB5",
      },
      axisLabel: {
        color: "#576B8D",
        margin: 20,
      },
      type: 'category',
      data: data?.loads?.length ? data?.loads?.map((load, idx) => idx < 10 ? ("0" + idx) : idx.toString()) : ["", "", "", "", "", "", ""],
    },
    // Configuration for the Y-axis
    yAxis: {
      show: true,
      name: "Cycle Duration (h)",
      nameLocation: "middle",
      nameGap: 55,
      axisLabel: {
        show: true,
        formatter: (v) => getHourMinuteDifference(0, v * 1000), // Add the label for the Y-axis here
      },
      type: 'value',
      nameTextStyle: {
        fontFamily: "Open Sans",
        fontWeight: "bold",
        fontSize: "0.7rem",
        color: "#728BB5",
      },
    },
    // Configuration for the series (data)
    series: [
      {
        type: "line",
        data: data?.loads.length ? data?.loads?.map((load, idx) => load.dumpCycleDuration) : [0, 0, 0, 0, 0, 0, 0],
        showBackground: false,
        // showSymbol: false,
        label: {
          show: false,
          fontSize: "0.625rem",
          position: "outside",
          fontWeight: "600",
          color: "#576B8D",
          formatter: (v) => getHourMinuteDifference(v),
        },
        itemStyle: {
          color: data?.loads?.length ? "#1ED5C6" : "transparent",
        },
      },
    ],
  };

  return (
    <div style={{ height: "100%", width: "100%" }}>

      <ReactEcharts style={{ height: "20rem" }} option={option} onEvents={mouse == 'click' && onEvents} />
      {/* {isOpen && <div>

      </div>} */}
    </div>
  );
}
