import React, { useEffect, useState } from 'react';
import { AssetPopup } from './AssetPopup';

const ActiveMarkerSvg = (index, marker) => {
    return `<?xml version="1.0" encoding="utf-8"?>
  <svg viewBox="0 -75 150 445" width="80" height="130"
    xmlns="http://www.w3.org/2000/svg">
    <!-- Increased Text Width: Adjust width to 450px and border radius to 35px -->
    <rect x="-60" y="-75" width="270" height="70" style="fill: #fff; stroke: #EBEBEB; stroke-width: 1" rx="16" ry="16"/>
    <text x="75" y="-28" text-anchor="middle" font-family="Open Sans" font-size="40px" font-weight="bold" fill="#707070">${marker?.title
        }</text>
    <ellipse style="fill: rgba(255, 255, 255, 1 ); stroke-width: 4; stroke: rgb(255, 255, 255 );" cx="76.913" cy="74.565" rx="68.056" ry="69.967" transform="matrix(0.9999999999999999, 0, 0, 0.9999999999999999, 0, -7.105427357601002e-15)"/>
    <ellipse style="fill: rgb(207, 253, 105); stroke-width: 4; stroke: rgb(0, 0, 0);" cx="78.256" cy="73.399" rx="45.282" ry="46.933" transform="matrix(0.9999999999999999, 0, 0, 0.9999999999999999, 0, -7.105427357601002e-15)"/>
    <ellipse style="fill: rgb(255, 255, 255); stroke-width: 4; stroke: rgb(255, 255, 255 );" cx="80.129" cy="235.92" rx="0.127" ry="80.63" transform="matrix(0.9999999999999999, 0, 0, 0.9999999999999999, 0, -7.105427357601002e-15)"/>
    <ellipse style="fill: rgb(0, 0, 0); stroke-width: 4; stroke: rgb(0, 0, 0 );" cx="80.129" cy="${index * 100 + 230
        }" rx="0.127" ry="80.63" transform="matrix(0.9999999999999999, 0, 0, 0.1999999999999999, 0, -7.105427357601002e-15)"/>
  </svg>`;
};

const getInvertedMarkerSvg = (status) => {

    switch (status) {
        case "Active":
            return `<?xml version="1.0" encoding="utf-8"?>
 <svg viewBox="0 -75 150 445" width="80" height="130"
    xmlns="http://www.w3.org/2000/svg">
      <rect x="-60" y="-75" width="270" height="70" style="fill: #fff; stroke: #EBEBEB; stroke-width: 1" rx="16" ry="16"/>
    <text x="75" y="-28" text-anchor="middle" font-family="Open Sans" font-size="40px" font-weight="bold" fill="#707070"></text>

          <ellipse style="fill: rgba(207, 253, 105, 1 ); stroke-width: 4; stroke: rgb(207, 253, 105);" cx="76.913" cy="74.565" rx="68.056" ry="69.967" transform="matrix(0.9999999999999999, 0, 0, 0.9999999999999999, 0, -7.105427357601002e-15)"/>
          <ellipse style="fill: rgb(255, 255, 255);" cx="78.256" cy="73.399" rx="45.282" ry="46.933" transform="matrix(0.9999999999999999, 0, 0, 0.9999999999999999, 0, -7.105427357601002e-15)"/>
          <ellipse style="fill: rgb(255, 255, 255); stroke-width: 4; stroke: rgb(255, 255, 255 );" cx="80.129" cy="235.92" rx="0.127" ry="80.63" transform="matrix(0.9999999999999999, 0, 0, 0.9999999999999999, 0, -7.105427357601002e-15)"/>
          <ellipse style="fill: rgb(0, 0, 0); stroke-width: 4; stroke: rgb(0, 0, 0 );" cx="80.129" cy="${530}" rx="0.127" ry="80.63" transform="matrix(0.9999999999999999, 0, 0, 0.1999999999999999, 0, -7.105427357601002e-15)"/>
        </svg>`;


        case "Idling":
            return `<?xml version="1.0" encoding="utf-8"?>
            <svg viewBox="0 -75 150 445" width="80" height="130" xmlns="http://www.w3.org/2000/svg" overflow="visible">
                <!-- Increased Text Width: Adjust width to 450px and border radius to 35px -->
                <rect x="-60" y="-75" width="270" height="70" style="fill: #fff; stroke: #EBEBEB; stroke-width: 1" rx="16" ry="16"/>
                <text x="75" y="-28" text-anchor="middle" font-family="Open Sans" font-size="40px" font-weight="bold" fill="#707070"></text>
            
                <!-- Marker elements unchanged -->
                <ellipse style="fill: rgba(255, 255, 255, 1); stroke-width: 4; stroke: rgb(255, 255, 255);" cx="76.913" cy="74.565" rx="68.056" ry="69.967"/>
                <ellipse style="fill:  rgba(207, 253, 105, 1 ); stroke-width: 4; stroke: rgb(0, 0, 0);" cx="78.256" cy="73.399" rx="45.282" ry="46.933"/>
                <ellipse style="fill: rgb(255, 255, 255); stroke-width: 4; stroke: rgb(255, 255, 255);" cx="80.129" cy="235.92" rx="0.127" ry="80.63"/>
            </svg>`

        default:
            console.log("Invalid status for inverted marker");
            return "";

    }

}

let map;
let mapkit = undefined;
let markerAnnotations = [];
let intervalIds = [];
let currentassetsFlyout
let clusteredAnnotations = []
let currentMarker = null;
let markersAtMaxZoomLevel = []
let redrawMarkersBeforeMaxZoomLevel = [];

export const Map = ({ zoomLevel, selectedMarker, mapRef, markers, onMarkerClick }) => {
    const [setupCompleted, setSetupCompleted] = useState(false);
    const setup = async () => {
        await setupMapKitJs();
        mapkit = window.mapkit;
        setupMap({ mapRef, onMarkerClick });

        setSetupCompleted(true);
        return () => {
            delete window.initMapKit;
        };
    }
    useEffect(() => {
        setup();
    }, []);

    const createMarker = ({ marker, onMarkerClick, isSelected, clustering }) => {
        const { coordinate, url, title } = marker;
        const markerCoord = new mapkit.Coordinate(coordinate?.latitude, coordinate?.longitude);
        const getMarkerImageColor = (identifier) => {
            switch (identifier) {
                case 'Parked':
                    return '#BEB6B6';
                case 'Idling':
                    return '#CFFD69';
                case 'Active':
                    return '#CFFD69';
                default:
                    return '#ED5151';
            }
        };

        // const svgContent = `<?xml version="1.0" encoding="utf-8"?>
        // <svg viewBox="0 -45 ${marker?.title?.length * 0 + 150} 445" width="40" height="190" xmlns="http://www.w3.org/2000/svg" overflow="visible">
        //     <!-- Increased Text Width: Adjust width to 450px and border radius to 35px -->
        //     <rect x="-137" y="-75" width="${marker?.title?.length * 10 + 450}" height="70" style="fill: #FFFFFF; stroke: #EBEBEB; stroke-width: 1" rx="16" ry="16"/>
        //     <text x="75" y="-28" text-anchor="middle" font-family="Open Sans" font-size="30px" font-weight="bold" fill="#707070">${marker?.title}</text>

        //     <!-- Marker elements unchanged -->
        //     <ellipse style="fill: rgba(255, 255, 255, 1); stroke-width: 4; stroke: rgb(255, 255, 255);" cx="76.913" cy="74.565" rx="68.056" ry="69.967"/>
        //     <ellipse style="fill:  ${getMarkerImageColor(marker?.Status)}; stroke-width: 4; stroke: rgb(0, 0, 0);" cx="78.256" cy="73.399" rx="45.282" ry="46.933"/>
        //     <ellipse style="fill: rgb(255, 255, 255); stroke-width: 4; stroke: rgb(255, 255, 255);" cx="80.129" cy="235.92" rx="0.127" ry="80.63"/>
        // </svg>
        //   `;

        const svgContent = `<?xml version="1.0" encoding="utf-8"?>
            <svg viewBox="0 -75 150 445" width="80" height="130" xmlns="http://www.w3.org/2000/svg" overflow="visible">
                <!-- Increased Text Width: Adjust width to 450px and border radius to 35px -->
                <rect x="-60" y="-75" width="270" height="70" style="fill: #fff; stroke: #EBEBEB; stroke-width: 1" rx="16" ry="16"/>
                <text x="75" y="-28" text-anchor="middle" font-family="Open Sans" font-size="40px" font-weight="bold" fill="#707070">${marker?.title
            }</text>
            
                <!-- Marker elements unchanged -->
                <ellipse style="fill: rgba(255, 255, 255, 1); stroke-width: 4; stroke: rgb(255, 255, 255);" cx="76.913" cy="74.565" rx="68.056" ry="69.967"/>
                <ellipse style="fill:  ${getMarkerImageColor(
                marker?.Status,
            )}; stroke-width: 4; stroke: rgb(0, 0, 0);" cx="78.256" cy="73.399" rx="45.282" ry="46.933"/>
                <ellipse style="fill: rgb(255, 255, 255); stroke-width: 4; stroke: rgb(255, 255, 255);" cx="80.129" cy="235.92" rx="0.127" ry="80.63"/>
            </svg>`;

        const markerAnnotation = new mapkit.ImageAnnotation(markerCoord, {
            url: {
                1: `data:image/svg+xml;base64,${btoa(svgContent)}`,
            },
            anchorOffset: new DOMPoint(0, 0),
            clusteringIdentifier:
                isSelected || clustering == 'disabled' ? null : marker?.Status,
        });

        function updateMarkerIcon(index, marker) {
            markerAnnotation.url = {
                1: `data:image/svg+xml;base64,${btoa(ActiveMarkerSvg(index, marker))}`,
            };
        }
        // Function to animate marker icon
        const animateMarker = () => {
            let index = 0;
            const intervalId = setInterval(() => {
                updateMarkerIcon(index, marker);
                index = (index + 1) % 4;
            }, 400); // Change the interval as needed (in milliseconds)
            return intervalId
        }
        let currentIntervalId;
        if (marker?.Status == "Active") {
            currentIntervalId = animateMarker()
            intervalIds?.push(currentIntervalId)
        }

        const removeAnnotation = () => {
            if (currentassetsFlyout?.visible) {
                currentassetsFlyout.visible = false;
                map.removeAnnotation(currentassetsFlyout)
            }
        }

        const createAnnotation = (selected) => {
            removeAnnotation()
            const markerCoord = new mapkit.Coordinate(coordinate.latitude, coordinate.longitude);
            const options = {
                data: marker,
                visible: false,
                anchorOffset: new DOMPoint(selected ? (0) : (0), selected ? (330) : (105)),
                clusteringIdentifier: null,
            };
            let annotation = new mapkit.Annotation(markerCoord, AssetPopup, options);
            map.setCenterAnimated(markerCoord);
            map.addAnnotation(annotation)
            currentassetsFlyout = annotation
            annotation.visible = true;
        }


        markerAnnotation.addEventListener('select', () => {
            onMarkerClick({ marker, select: true });
            if (marker?.Status == "Active") {
                clearInterval(currentIntervalId);
                intervalIds = intervalIds.filter((i) => i !== currentIntervalId);
                // to remove currentIntervalId from intervalIds
            }


            if (marker?.Status == "Active" || marker?.Status == "Idling") {
                markerAnnotation.url = { 1: `data:image/svg+xml;base64,${btoa(getInvertedMarkerSvg(marker?.Status))}` }

            }


            createAnnotation(false)
        });

        markerAnnotation.addEventListener('deselect', () => {
            removeAnnotation()

            if (marker?.Status == "Active") {
                intervalIds?.push(animateMarker())
            }


            const svgContent = `<?xml version="1.0" encoding="utf-8"?>
            <svg viewBox="0 -75 150 445" width="80" height="130" xmlns="http://www.w3.org/2000/svg" overflow="visible">
                <!-- Increased Text Width: Adjust width to 450px and border radius to 35px -->
                <rect x="-60" y="-75" width="270" height="70" style="fill: #fff; stroke: #EBEBEB; stroke-width: 1" rx="16" ry="16"/>
                <text x="75" y="-28" text-anchor="middle" font-family="Open Sans" font-size="40px" font-weight="bold" fill="#707070">${marker?.title
                }</text>
            
                <!-- Marker elements unchanged -->
                <ellipse style="fill: rgba(255, 255, 255, 1); stroke-width: 4; stroke: rgb(255, 255, 255);" cx="76.913" cy="74.565" rx="68.056" ry="69.967"/>
                <ellipse style="fill:  ${getMarkerImageColor(
                    marker?.Status,
                )}; stroke-width: 4; stroke: rgb(0, 0, 0);" cx="78.256" cy="73.399" rx="45.282" ry="46.933"/>
                <ellipse style="fill: rgb(255, 255, 255); stroke-width: 4; stroke: rgb(255, 255, 255);" cx="80.129" cy="235.92" rx="0.127" ry="80.63"/>
            </svg>`;


            if (marker?.Status == "Active" || marker?.Status == "Idling") {
                markerAnnotation.url = {
                    1: `data:image/svg+xml;base64,${btoa(svgContent)}`,
                }
            }

            onMarkerClick({ marker: {}, select: false });
        });

        if (isSelected) createAnnotation(true)
        if (clustering == "disabled") { markersAtMaxZoomLevel.push(markerAnnotation) }
        else if (isSelected) { currentMarker = markerAnnotation }
        else {
            markerAnnotations.push(markerAnnotation);
        }
        map.addAnnotation(markerAnnotation);
        return markerAnnotation
    }

    const setupMapKitJs = async () => {
        if (!window.mapkit || window.mapkit.loadedLibraries?.length === 0) {
            await new Promise((resolve) => {
                window.initMapKit = resolve;
            });
            delete window.initMapKit;
        }

        const jwt = process.env.REACT_APP_APPLE_MAP_TOKEN;
        window.mapkit.init({
            authorizationCallback: (done) => {
                done(jwt);
            },
            language: 'en',
        });
    };
    const CAMERA_DISTANCE = 400; // increasing will take view more farther from ground
    const MAP_CENTER_LAT = -37.61187;
    const MAP_CENTER_LNG = 175.08517;
    const setupMap = ({ mapRef, onMarkerClick }) => {
        const mapCenter = new mapkit.Coordinate(-37.61187, 175.08517);
        const mapRegion = new mapkit.CoordinateRegion(
            mapCenter,
            new mapkit.CoordinateSpan(0.04, 0.04)
        );
        map = new mapkit.Map('map-container', {
            center: mapCenter,
            mapType: mapkit.Map.MapTypes.Satellite,
            region: mapRegion,
            showsCompass: mapkit.FeatureVisibility.Hidden,
        });
        mapRef.current = map;
        map.cameraZoomRange = new mapkit.CameraZoomRange(200, 8000);
        map.cameraBoundary = mapRegion.toMapRect();
        map.cameraDistance = 400;

        map.addEventListener('single-tap', () => {
            if (currentassetsFlyout?.visible) {
                currentassetsFlyout.visible = false;
                map.removeAnnotation(currentassetsFlyout)
            }
            if (currentMarker != null) {
                map.removeAnnotation(currentMarker)
                currentMarker = null
            }
            onMarkerClick({ marker: {}, select: false });
        });

        map.addEventListener('zoom-end', () => {
            clusteredAnnotations.forEach((annotation) => {
                map.removeAnnotation(annotation);
            });
        })

    };

    const getClusteredImageColor = (identifier) => {
        switch (identifier) {
            case "Parked":
                return "#BEB6B6";
            case "Idling":
                return "#CFFD69";
            case "Active":
                return "#CFFD69";
            default:
                return "#ED5151";
        }
    };

    const clearActiveMarkerIntervals = () => {
        intervalIds.forEach((intervalId) => {
            clearInterval(intervalId);
        });
        intervalIds = []
    }

    const createMarkers = () => {
        if (!map) return;
        map.isZoomEnabled = true;
        map.isScrollEnabled = true;
        //=- Removing old markers and annotations 
        markerAnnotations.forEach((annotation) => {
            map.removeAnnotation(annotation)
        });
        clearActiveMarkerIntervals()
        markerAnnotations = []
        clusteredAnnotations.forEach((annotation) => {
            map.removeAnnotation(annotation);
        });

        //=- Creating new marker after removing old ones 
        markers.forEach((marker) => {
            createMarker({ marker: marker, onMarkerClick: onMarkerClick, })
        });

        map.annotationForCluster = (clusterAnnotation) => {
            const svgContent = `<?xml version="1.0" encoding="utf-8"?>
    <svg viewBox="0 0 150 330" width="40" height="90" xmlns="http://www.w3.org/2000/svg">
      <ellipse style="fill: rgba(255, 255, 255, 1 ); stroke-width: 4; stroke: rgb(255, 255, 255 );" cx="76.913" cy="74.565" rx="68.056" ry="69.967" transform="matrix(0.9999999999999999, 0, 0, 0.9999999999999999, 0, -7.105427357601002e-15)"/>
      <ellipse style="fill: ${getClusteredImageColor(clusterAnnotation.clusteringIdentifier)};" cx="78.256" cy="73.399" rx="45.282" ry="46.933" transform="matrix(0.9999999999999999, 0, 0, 0.9999999999999999, 0, -7.105427357601002e-15)"/>
      <ellipse style="fill: rgb(255, 255, 255); stroke-width: 4; stroke: rgb(255, 255, 255 );" cx="80.129" cy="235.92" rx="0.127" ry="80.63" transform="matrix(0.9999999999999999, 0, 0, 0.9999999999999999, 0, -7.105427357601002e-15)"/>
      <text x="78.256" y="85" text-anchor="middle" font-family="Arial" font-size="44" fill="black">(${clusterAnnotation?.memberAnnotations?.length})</text>
    </svg>`;

            var annotation = new mapkit.ImageAnnotation(clusterAnnotation.coordinate, {
                url: { 1: `data:image/svg+xml;base64,${btoa(svgContent)}` }
            });

            return annotation;
        };
    }

    useEffect(() => {
        createMarkers();
    }, [setupCompleted, markers]);


    const handleClustering = () => {
        if (map?._impl?.zoomLevel > 17.8) {
            markerAnnotations.forEach((annotation) => {
                map.removeAnnotation(annotation)
            });
            redrawMarkersBeforeMaxZoomLevel = markerAnnotations
            // markersAtMaxZoomLevel = []
            markers.forEach((marker) => {
                createMarker({ marker: marker, onMarkerClick: onMarkerClick, clustering: "disabled" })
            });
        } else {
            markersAtMaxZoomLevel.forEach((annotation) => {
                map.removeAnnotation(annotation)
            });
            redrawMarkersBeforeMaxZoomLevel.forEach((annotation) => {
                map.addAnnotation(annotation)
            });
            redrawMarkersBeforeMaxZoomLevel = []
        }
    }

    useEffect(() => {
        if (markers?.length && map) {
            map.removeEventListener('zoom-end')
            map.addEventListener('zoom-end', handleClustering)
            handleClustering()
        }
    }, [markers, zoomLevel])



    useEffect(() => {
        if (currentassetsFlyout?.visible) {
            currentassetsFlyout.visible = false;
            map.removeAnnotation(currentassetsFlyout)
        }
        if (currentMarker) {
            map.removeAnnotation(currentMarker)
            currentMarker = null
        }
        if (selectedMarker && Object.keys(selectedMarker).length > 0) {
            createMarker({ marker: selectedMarker, isSelected: true, onMarkerClick: onMarkerClick })
            const mapCenter = new mapkit.Coordinate(selectedMarker?.coordinate?.latitude, selectedMarker?.coordinate?.longitude)
            map.setCenterAnimated(mapCenter);
        }
    }, [selectedMarker])

    return (
        <section
            id="map-container"
            style={{ height: '100%', width: '100%' }}
        />
    );
};
