import React, { useEffect, useRef, useState } from 'react';
import { CgClose } from 'react-icons/cg';
import LoadingScreen from '../../../../../.././components/LoadingScreen';
import LegendDropDown from '../../../Components/LegendDropDown';
import DownloadIcon from '../../components/download.svg'
import DownloadWhiteIcon from '../../components/download-white.svg'
import DownloadMenu from '../../components/DownloadMenu';
import { legendDropDownData } from '../../../constData'
import TimeSelect from '../../components/TimeSelect';
import { downloadReportFromServer, getFilteredTimestamp } from '../../../utils/helper';
import axios from 'axios';
import { TRUCK_EVENTS } from '../../../../../../action/apiPath';
import { CallWithAuth } from '../../../../../../action/apiActions';
import { formatTimelineReport } from '../../../utils/helper';
import { toast } from 'react-toastify';

export default function Report({ date, setIsReportModalOpen, asset_ID, tripStartTime, tripEndTime, trip_id, setSelectedRowForMenu }) {
    const [loading, setLoading] = useState(false)
    const source = useRef(axios.CancelToken.source());
    const [reportData, setReportData] = useState([]);
    const [reportDataFiltered, setReportDataFiltered] = useState([])
    const [filterReportDataValues, setFilterReportDataValues] = useState({ from: '', to: '' })
    const [isReportDownloadMenuOpen, setIsReportDownloadMenuOpen] = useState(false);
    const [apiDataLoading, setApiDataLoading] = useState(false);

    useEffect(() => {
        source?.current?.cancel()
        source.current = axios.CancelToken.source();
        fetchTripshistoryReport(source.current.token)
    }, [])

    const fetchTripshistoryReport = async (cancelToken) => {
        try {
            setLoading(true)
            const payload = {
                asset_ID: asset_ID,
                activity_start_time: !filterReportDataValues?.from?.hours ? tripStartTime : getFilteredTimestamp(filterReportDataValues?.from, date),
                activity_end_time: !filterReportDataValues?.to?.hours ? tripEndTime : getFilteredTimestamp(filterReportDataValues?.to, date),
                date
            };
            const response = await CallWithAuth("POST", TRUCK_EVENTS, payload, cancelToken);


            if (response?.res?.status == 200) {
                const events = formatTimelineReport(response?.res?.data?.data)
                setReportData(events)
                setReportDataFiltered(events)
                setLoading(false)
            }

        } catch (error) {
            toast.error(error)
            setLoading(false)
            setIsReportModalOpen(false)
        }

    }

    useEffect(() => {
        const filteredData = filterByTime(reportData);
        setReportDataFiltered(filteredData)
    }, [filterReportDataValues])

    const filterByTime = (unFilteredData) => {
        const startTime = getFilteredTimestamp(filterReportDataValues?.from, date);
        const endTime = getFilteredTimestamp(filterReportDataValues?.to, date);
        return unFilteredData.filter((subitem) => (
            (!startTime || subitem.timestamp >= startTime) && (!endTime || subitem.timestamp <= endTime)
        ))
    };
    const reportDownloadMenuSelectHandler = async (optionNo) => {
        if (optionNo == 0) {
            setApiDataLoading(true)
            source?.current?.cancel()
            source.current = axios.CancelToken.source();
            await downloadReportFromServer(date, getFilteredTimestamp(filterReportDataValues.from, date), getFilteredTimestamp(filterReportDataValues.to, date), trip_id, source.current.token, [asset_ID], "", "TRIP_TIMELINE", false)
            setApiDataLoading(false)
        }
    }
    return (
        <>
            {apiDataLoading && <LoadingScreen />}
            <div className={"full-screen-modal"} style={{ right: 0, backgroundColor: '#FBFDFF', position: 'fixed' }}>
                <div className="modal-heading">
                    <div className='px-3 d-flex w-100 justify-content-between align-items-center'>
                        <div className='justify-content-between align-items-center' style={{ marginLeft: "1.875rem", }}>
                            <span style={{
                                font: 'normal normal bold 13px/18px Open Sans',
                                color: '#1A1A1A'
                            }}>
                                {asset_ID} | {new Date(date)
                                    .toLocaleDateString('en-NZ', { day: '2-digit', month: '2-digit', year: 'numeric', timeZone: 'Pacific/Auckland' })}
                            </span>
                        </div>
                        <CgClose
                            style={{ justifyContent: "flex-end", }}
                            onClick={() => {
                                setIsReportModalOpen(false);
                                setReportDataFiltered([]);
                                setSelectedRowForMenu('')
                            }}
                            size={"3rem"}
                            className="px-2"
                        />
                    </div>
                </div>
                <div style={{

                    height: 'calc(100vh - 3.4rem)',
                    padding: '4rem 12rem',
                    overflowY: 'scroll'
                }}>
                    <div className='d-flex justify-content-between'>
                        <div style={{ font: 'normal normal bold 1.5rem/2rem Open Sans' }}>Trip Report</div>
                        <div className='d-flex justify-content-between'>

                            <div className="d-flex align-items-center justify-content-center">

                                <div style={{
                                    height: '100%',
                                    alignItems: 'center',
                                    display: 'flex'
                                }} className="mr-3 ">

                                    <span style={{ font: 'normal normal normal 13px/15px Open Sans', color: 'black' }} className='mr-2'>From</span>
                                    <TimeSelect disabled={reportDataFiltered.length == 0} uniqueName={'from'} onChange={(value) => { setFilterReportDataValues(prev => ({ ...prev, from: value })) }} />
                                </div>
                                <div style={{
                                    height: '100%',
                                    alignItems: 'center',
                                    display: 'flex'
                                }} className="mr-3">

                                    <span style={{ font: 'normal normal normal 13px/15px Open Sans', color: 'black' }} className='mr-2'>To</span>
                                    <TimeSelect disabled={reportDataFiltered.length == 0} uniqueName={'to'} onChange={(value) => { setFilterReportDataValues(prev => ({ ...prev, to: value })) }} />
                                </div>
                                <LegendDropDown data={legendDropDownData} />
                                <div className='ml-3' style={{
                                    height: '100%',
                                    alignItems: 'center',
                                    display: 'flex'
                                }}>

                                    <DownloadMenu
                                        disabled={reportData.length == 0}
                                        uniqueName={'reportDownload'}
                                        menuOptions={[
                                            {
                                                displayName: 'Download Report (CSV)',
                                                icon: DownloadIcon,
                                            }
                                        ]}
                                        color={'#E2ECFC'}
                                        selectedColor={'#07273F'}
                                        selectedIcon={DownloadWhiteIcon}
                                        menuVisible={isReportDownloadMenuOpen}
                                        setMenuVisible={(value) => setIsReportDownloadMenuOpen(value)}
                                        onMenuSelect={(optionNo) => reportDownloadMenuSelectHandler(optionNo)}
                                    />


                                </div>
                            </div>


                        </div>
                    </div>
                    <table className='trips-report-table' style={{
                        margiTop: '1rem',
                        backgroundColor: '#FFFFFF',
                        border: '1px solid #E1E8F4',
                        borderRadius: '4px',
                        width: '100%',
                        height: 'calc(100vh -2rem)',
                        borderCollapse: 'collapse',
                        fontSize: '1.075rem'
                    }}>
                        <thead style={{ height: '50px' }}>
                            <tr style={{ borderLeft: "1.375", }}>
                                <th style={{ width: '3%' }}></th>
                                <th style={{ width: '9%' }}>Time</th>
                                <th style={{ width: '9%' }}>Event</th>
                                <th style={{ width: '7%' }}>Message No.</th>
                                <th style={{ width: '10%' }}>Location</th>
                                <th style={{ width: '7%' }}>Speed</th>
                                <th style={{ width: '8%' }}>Alert</th>
                                <th style={{ width: '7%' }}>Heading</th>
                                <th style={{ width: '7%' }}>Attitude</th>
                                <th style={{ width: '7%' }}>Satellite</th>
                                <th style={{ width: '6%' }}>Signal Strength</th>
                            </tr>
                        </thead>
                        {(loading) ? <LoadingScreen /> : <tbody>
                            {reportDataFiltered.map((row) => <tr

                                style={{

                                    position: 'relative',
                                    height: "3.8rem",
                                    font: "normal normal normal 1.075rem/1.125rem Open Sans",

                                }}

                            >
                                <div style={{ position: 'absolute', marginLeft: '10px', width: '7%' }}>
                                    {row.alertType == 0 && (
                                        <div className="legend-box1" style={{ left: 0, position: "absolute", background: "#ea9336", width: '0.84rem' }} />
                                    )}
                                    {row.alertType == 1 ? (
                                        <div className="legend-box1" style={{ position: "absolute", background: "#73bf63", width: '0.84rem', left: '1.2rem' }} />
                                    ) : row.alertType == 2 ? (
                                        <div className="legend-box1" style={{ position: "absolute", background: "#181cf5", width: '0.84rem', left: '1.2rem' }} />
                                    ) : (
                                        row.alertType == 3 ?
                                            <div className="legend-box1" style={{ position: "absolute", background: "#e23123", width: '0.84rem', left: '1.2rem' }} /> : <></>
                                    )}
                                </div>
                                <td style={{ width: '7%' }}>
                                    <div style={{
                                        font: 'normal normal normal 12px/18px Open Sans',
                                        color: '#707071'
                                    }}>
                                        {row.time || '-'}
                                    </div>
                                </td>

                                <td style={{ width: '7%' }}>
                                    <div className=''>
                                        {row.event || '-'}
                                    </div>
                                </td>

                                <td style={{ width: '7%' }}>
                                    <div className=''>
                                        {row.messageNo || '-'}
                                    </div>
                                </td>
                                <td style={{ width: '7%' }}>
                                    <div style={{ textDecoration: 'underline' }} className=''>
                                        {row.location.latitude}, {row.location.longitude}
                                    </div>
                                </td>
                                <td style={{ width: '7%' }}>
                                    <div className=''>
                                        {row.speed || '-'}
                                    </div>
                                </td>
                                <td style={{ width: '7%' }}>
                                    <div style={{ textDecoration: 'italic' }} className=''>
                                        {row.alert || '-'}
                                    </div>
                                </td>
                                <td style={{ width: '7%' }}>
                                    <div className=''>
                                        {row.heading || '-'}
                                    </div>
                                </td>
                                <td style={{ width: '7%' }}>
                                    <div className=''>
                                        {row.altitude || '-'}
                                    </div>
                                </td>
                                <td style={{ width: '7%' }}>
                                    <div className=''>
                                        {row.satellites || '-'}
                                    </div>
                                </td>
                                <td style={{ width: '7%' }}>
                                    <div className=''>
                                        {(row.signal) || '-'}
                                    </div>
                                </td>
                            </tr>)}
                        </tbody>}
                    </table>
                </div>
            </div>
        </>
    )
}
