import moment from 'moment';
import plusIcon from './../../assets/svgs/magnify.svg';
import minusIcon from './../../assets/svgs/minify.svg';

export default function TimelineGraphSubHeader({
  selectedSpeed = 0,
  speedUnit = 'km/h',
  timestamp = 0,
  xAxisGap,
  setXAxisGap,
  maxXAxisGap,
  minXAxisGap,
  isLastPage = false,
  isFirstPage = false,
}) {
  const formatSpeed = (speed) => {
    if (!speed) {
      return '00';
    }
    return speed;
  };

  const magnifyGraph = () => {
    if (xAxisGap - minXAxisGap <= minXAxisGap /*  || isFirstPage */) {
      return;
    }
    setXAxisGap(xAxisGap - minXAxisGap);
  };

  const minifyGraph = () => {
    if (xAxisGap + minXAxisGap > maxXAxisGap /*  || isLastPage */) {
      return;
    }
    setXAxisGap(xAxisGap + minXAxisGap);
  };

  return (
    <div className="d-flex flex-row justify-content-between">
      <div>
        <div
          className="d-flex justify-content-between"
          style={{
            bottom: '-1rem',
            zIndex: 1,
            right: '1rem',
            backgroundColor: 'white',
            borderRadius: '0.3rem',
          }}
        >
          <div
            className="align-items-center justify-content-center cursor-pointer asm-timeline-graph-plus-button"
            onClick={() => magnifyGraph()}
          >
            <img
              src={plusIcon}
              alt="Zoom In"
              style={{
                width: '1rem',
                cursor: "pointer",
                height: '1rem',
                opacity: xAxisGap - minXAxisGap <= minXAxisGap ? 0.5 : 1,
              }}
            />
            {isLastPage}
          </div>
          <div
            className="align-items-center justify-content-center cursor-pointer asm-timeline-graph-minus-button"
            onClick={() => minifyGraph()}
          >
            <img
              src={minusIcon}
              alt="Zoom Out"
              style={{
                width: '1rem',
                cursor: "pointer",
                height: '1rem',
                opacity: xAxisGap + minXAxisGap > maxXAxisGap ? 0.5 : 1,
              }}
            />
          </div>
        </div>
      </div>

      <div
        className="d-flex justify-content-between"
        style={{ marginRight: '2%' }}
      >
        <span style={{ color: '#000', fontWeight: '800' }}>
          {formatSpeed(selectedSpeed)}
        </span>
        &nbsp;
        <span style={{ color: '#3880F5', fontWeight: '800' }}>{speedUnit}</span>
        <span className="px-1">-</span>
        <span style={{ fontWeight: '500' }}>
          {moment(timestamp * 1000).format('hh:mm:ss')}
        </span>
        <span className="px-1">-</span>
        <span style={{ fontWeight: '500', color: '#646464' }}>
          {moment(timestamp * 1000).format('DD/MM/YY')}
        </span>
      </div>
    </div>
  );
}
