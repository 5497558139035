import ReactEcharts from 'echarts-for-react';
import { getHourMinuteDifference } from '../../helper';

export default function ShiftTotalDelayCompareSectionBarGraph({ data }) {
    const option = {
        // Configuration for the tooltip
        tooltip: {
            backgroundColor: 'white',
            borderColor: '#E1E8F4',
            padding: 0,
            borderRadius: 8,
            formatter: (x) => `
      <div style="width: max-content; padding: 15px; display: flex; flex-direction: column;
        background: #FFFFFF 0% 0% no-repeat padding-box; border-radius: 8px;">
        <span style="font-family: 'Open Sans'; font-size: 12px; font-weight: 600;">
          ${x?.name}
        </span>
        <span style="margin-bottom: 4.5px; font-size: 12px; font-family: 'Open Sans'; font-style: italic;
          font-weight: 600; color: #5F6672;">
          Idle Time Delays (ITD)
        </span>
        <span style="font-family: 'Open Sans'; font-size: 12px; font-weight: 900; color: black;">
          ${getHourMinuteDifference(0, x.value * 1000)}
        </span>
      </div>
    `
        },
        // Configuration for the grid
        grid: {
            left: '60px',
            right: '4%',
            bottom: '5px',
            height: '85%',
            // width: '90%',
            paddinTop: 0,
            containLabel: true,
            backgroundColor: '#f5f5f5', // Background color for the grid
        },
        // Configuration for the X-axis
        xAxis: {
            axisLabel: {
                color: '#576B8D',
                // lineHeight: 150
                margin: 20,
            },
            axisTick: {
                show: false
            },
            data: data.categories,
        },
        // Configuration for the Y-axis
        yAxis: {

            name: 'Delay Hours',
            nameLocation: 'middle',
            nameGap: 55,
            axisLabel: {
                show: true,
                formatter: (v) => getHourMinuteDifference(0, v * 1000), // Add the label for the Y-axis here
            },
            nameTextStyle: {
                fontFamily: 'Open Sans',
                fontWeight: 'bold',
                fontSize: '12px',
                color: '#728BB5',
            }
        },
        // Configuration for the series (data)
        series: [
            {
                type: 'bar',
                data: data.values,
                showBackground: true,
                barMaxWidth: '35rem',
                label: {
                    show: true,
                    fontSize: '10px',
                    position: 'outside',
                    fontWeight: '600',
                    color: '#576B8D',
                    formatter: (v) => getHourMinuteDifference(0, v.value * 1000)
                },
                itemStyle: {
                    color: '#367ED8',

                },
            },
        ],
    };

    return <div style={{ height: '100%', width: '100%' }} >
        <ReactEcharts option={option} />
    </div>;
};