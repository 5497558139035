import { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import LoadingScreen from '../../../../../../components/LoadingScreen';
import BasicButton from '../BasicButton';
import '../styles.scss';
import { CallWithAuth } from './../../../../../../action/apiActions';
import {
  SAVE_HISTORY_DATA_SETTINGS
} from './../../../../../../action/apiPath';
import TimeFilter from './SettingsComponent/TimeFilter';
const SettingsModal = (props) => {
  const { modalOpen, modalToggle, getSettings } = props;
  const [loading, setLoading] = useState(false);
  const [minIdleTime, setMinIdleTime] = useState();
  const [value, setValue] = useState({
    hour: '',
    minutes: '',
    seconds: '',
  });

  function convertToSeconds(value) {
    let { hour = '0', minutes = '0', seconds = '0' } = value ?? {};
    if (hour === '') {
      hour = '0';
    }
    if (minutes === '') {
      minutes = '0';
    }
    if (seconds === '') {
      seconds = '0';
    }

    const totalSeconds =
      parseInt(hour, 10) * 3600 +
      parseInt(minutes, 10) * 60 +
      parseInt(seconds, 10);
    return totalSeconds;
  }

  function convertToTime(seconds) {
    const hour = Math.floor(seconds / 3600);
    const remainingSecondsAfterHours = seconds % 3600;
    const minutes = Math.floor(remainingSecondsAfterHours / 60);
    const remainingSeconds = remainingSecondsAfterHours % 60;

    // Function to pad single digits with leading zeros
    const padWithZero = (num) => {
      return num < 10 ? '0' + num : num.toString();
    };

    return {
      hour: hour.toString(),
      minutes: minutes.toString(),
      seconds: remainingSeconds.toString(),
    };
  }

  const onApply = () => {
    ApplySettings();
  };

  const ApplySettings = async () => {
    try {
      setLoading(true);
      const response = await CallWithAuth('POST', SAVE_HISTORY_DATA_SETTINGS, {
        min_idle_time: minIdleTime,
      });
      await getIdleTimeSettings();
      setLoading(false);
    } catch (error) {
      console.log('error', error);
      toast.error(error);
    }
  };
  const getIdleTimeSettings = async () => {
    try {
      // const response = await CallWithAuth('GET', GET_HISTORY_DATA_SETTINGS);
      const response = await getSettings();
      // setFilterData(response?.res?.data?.data?.min_idle_time);
      setMinIdleTime(response?.res?.data?.data?.min_idle_time);
      const tempIdleTime = convertToTime(
        response?.res?.data?.data?.min_idle_time,
      );
      setValue(tempIdleTime);
    } catch (error) {
      console.log('error', error);
      toast.error(error);
    }
  };
  useEffect(() => {
    getIdleTimeSettings();
  }, []);

  return (
    <div className="test-class" style={{ borderRadius: '0px 0px 0px 0px' }}>
      {modalOpen && (
        <Modal
          show={modalOpen}
          onHide={modalToggle}
          // backdrop="static"
          backdropClassName={'asm-settings-modal-backgrop-style'}
          keyboard={false}
          size="lg"
          dialogClassName="asm-settings-modal-position"
          className="asm-settings-modal-transparent fixed"
        >
          <Modal.Body>
            <div
              className="font-13 d-flex flex-column justify-content-center h-100 px-4 my-3 font-13 mb-3"
              style={{
                font: 'normal normal 0.875rem/1.1875rem Open Sans',
                borderRadius: '0.625rem 0 0 0',
                // borderBottom: "0.063rem solid #DEE5F2 !important",
              }}
            >
              <div className="d-flex flex-column gap-2" style={{ borderBottom: "0.063rem  solid #DEE5F2", }}>
                <p className="lh-17" style={{ font: "normal normal bold 1rem/1.375rem Open Sans" }}>
                  Account for idle time after…
                </p>
                <div className=" mb-1">
                  {loading ? (
                    <LoadingScreen />
                  ) : (
                    <TimeFilter
                      value={
                        value
                          ? value
                          : {
                            hour: '00',
                            minutes: '00',
                            seconds: '00',
                          }
                      }
                      setValue={setValue}
                      onChange={(value) => {
                        const seconds = convertToSeconds(value);
                        setMinIdleTime(seconds);
                      }}
                    />
                  )}
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer style={{ paddingLeft: '0rem' }}>
            <div
              className="d-flex align-items-center justify-content-between px-1 py-1 "
              style={{
                backgroundRepeat: 'no-repeat',
                padding: 'padding-box',
              }}
            >
              <div className=" d-flex">
                <div className=" px-4">
                  <BasicButton
                    className="px-4 btn-focus"
                    style={{
                      border: '0.0625rem solid #B3C8ED',
                      marginBottom: '1.25rem',
                      maxWidth: '5rem',
                      backgroundColor: "#FFFFFF",
                      borderRadius: "0.25rem",
                      height: "1.875rem", width: "5rem"
                    }}
                    onClick={() => modalToggle(false)}
                    variant="white"
                  >
                    <div
                      style={{
                        font: 'normal normal bold 0.75rem/1.063rem Open Sans',
                        color: "#186FD9"
                      }}
                    >
                      {' '}
                      Cancel
                    </div>
                  </BasicButton>
                </div>
                <div>
                  <BasicButton
                    className="px-4 btn-focus"
                    onClick={onApply}
                    variant="dark"
                    style={{ marginBottom: '1.25rem', backgroundColor: "#186FD9", borderRadius: "0.25rem", border: "none", height: "1.875rem", width: "5rem" }}
                  >
                    <div
                      style={{
                        font: 'normal normal bold 0.75rem/1.063rem Open Sans',
                        color: "#FFFFFF"
                      }}
                    >
                      {' '}
                      Apply
                    </div>
                  </BasicButton>
                </div>
              </div>
            </div>
          </Modal.Footer>
        </Modal>
      )
      }
    </div >
  );
};
export default SettingsModal;
