import React from "react";

export default function CycleActivityDataPoint(props) {
  const { color, label, selected, onSelected, index } = props;
  return (
    <div
      onClick={() => {
        if (onSelected) {
          onSelected(index);
        }
      }}
      className="d-flex align-items-center justify-content-flex-start"
      style={{
        backgroundColor: selected ? "#F2F7FF" : "white",
        cursor: onSelected ? "pointer" : "initial",
      }}
    >
      <div
        className="mx-2 ml-3 d-flex align-items-center justify-content-center"
        style={{
          backgroundColor: "transparent",
          borderRadius: "50%",
          width: "1rem",
          height: "1rem",
          borderWidth: 1,
          borderStyle: "solid",
          borderColor: selected ? color : "transparent",
        }}
      >
        <div
          style={{
            backgroundColor: color,
            borderRadius: "50%",
            width: "0.5rem",
            height: "0.5rem",
          }}
        />
      </div>
      <div className="mr-4"
        style={{
          color: selected ? "black" : "#5F6672",
          fontWeight: selected ? "700" : "600",
          fontSize: "0.82rem",
          lineHeight: '1.8rem'
          // width: "10rem",
        }}
      >
        {label}
      </div>
    </div>
  );
}
