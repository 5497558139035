import React, { useState } from "react";
import { Dropdown } from "react-bootstrap";

const LegendDropDown = ({ data, align }) => {
    const [isOpen, setIsOpen] = useState(false);

    const handleToggle = (isOpen) => {
      setIsOpen(isOpen);
    };

    return <Dropdown show={isOpen} onToggle={handleToggle} style={{ display: 'flex', alignItems: 'center' }} className="">
        <Dropdown.Toggle className="" align={{ lg: 'start' }}
            style={{background : isOpen ? "#2B2D2F" : "#E2ECFC", height: '2.5rem', width: '10rem', borderRadius: '0.3333rem', outline: 'none' }}
            bsPrefix="custom-toggle"
            id=""
        >
            <div style={{ color : !isOpen ? "#2B2D2F" : "#E2ECFC", font: " normal normal 600  1.075rem/1.063 Open Sans", }}>
                Legend
            </div>
        </Dropdown.Toggle>
        <Dropdown.Menu style={{
            borderRadius: '0.3rem'
        }} className="p-0 pr-4 pb-2 pt-1" bsPrefix="dropdown-menu legend_dropdown">
            {data?.map((section, sectionIndex) => (
                <div key={sectionIndex}>
                    {section?.title && <Dropdown.Item>
                        <span style={{ fontWeight: 600 }}>{section?.title}</span>{' '}
                        <span style={{ fontStyle: 'italic', color: '#707071' }}>
                            {section?.subtitle}
                        </span>
                    </Dropdown.Item>}
                    {section?.legends?.map((legend, legendIndex) => (
                        <Dropdown.Item
                            className="d-flex align-items-center"
                            style={{ lineHeight: 1.8 }}
                            key={legendIndex}
                            disabled
                        >
                            <div className="mr-2"
                            // className="legend-box1"
                            // style={{ background: legend?.color }}
                            >
                                {legend?.icon}
                            </div>
                            <div style={{ fontSize: '0.917rem' }}>{legend?.label}</div>
                        </Dropdown.Item>
                    ))}
                    {sectionIndex !== data.length - 1 && <div className="d-flex justify-content-center" >
                        <hr style={{
                            color: 'black',
                            width: '80%',
                            marginTop: '0.5rem',
                            marginBottom: '0.5rem'
                        }} />
                    </div>}
                </div>

            ))}
        </Dropdown.Menu>
    </Dropdown>

};

export default LegendDropDown;
