import React, { useState, useEffect } from "react";
import ShiftTotalDelaysRow from "../../Delays/components/components/ShiftTotalDelaysRow";
import { calculateAverageCycleDurationDumpCycle, getHourMinuteDifference, calculateLoading, calculateTravelling, calculateDumping, calculateTramming } from "../helper";
import PieChartSection from "../../Delays/components/components/PieChartSection";
import { constData, eventTypes } from '../constData';

export default function DumpCycleDurationSection({ data, selected, setSelected }) {
  return (
    <div className="d-flex justify-content-between pl-0" >
      <div
        className="d-flex flex-column col-lg-7 p-0"
        style={{
          borderRight: "1px solid #E1E8F4",
        }}
      >
        <div className="row" style={{
          marginRight: 0
        }}>
          <div
            className="col-7"
            style={{
              color: "#6A7C9A",
              fontSize: "0.8rem",
            }}
          >
            Truck ID
          </div>
          <div
            className="col-5"
            style={{
              color: "#6A7C9A",
              fontSize: "0.8rem",
            }}
          >
            Cycle Duration (h)
          </div>
        </div>

        <div>
          {data?.map((truck, i) => (
            <ShiftTotalDelaysRow
              data={{ ...truck, Unit: truck.truckName, total: truck?.averageDumpCycleDuration }}
              targetValue={3600}
              key={i}
              onSelected={() => setSelected(i)}
              selected={selected === i}
              showTarget={true}
              // extendedTrue={true}
              showTargetDifference={true}
              colorMore={"#EB5F5F"}
              colorLess={"#3AC49F"}
              tagColorLess={"#E2FDF1"}
              tagColorMore={"#FCE5E5"}
            />
          ))}
        </div>
      </div>
      <div className="col-lg-5 p-0 ml-4">
        <PieChartSection
          listDetails={constData?.map((row, j) => ({
            ...row,
            data: data?.[selected]?.breakdownOfAverageDumpCycleDuration?.[eventTypes[j]]
              ? getHourMinuteDifference(
                0,
                1000 *
                data?.[selected]?.breakdownOfAverageDumpCycleDuration?.[eventTypes[j]],
                "h"
              )
              : "00:00 h",
          }))}
          data={constData?.map((row, j) => ({
            ...row,
            value: data?.[selected]?.breakdownOfAverageDumpCycleDuration?.[eventTypes[j]]
              ? data?.[selected]?.breakdownOfAverageDumpCycleDuration?.[eventTypes[j]]
              : 0,
          }))}
          title={data?.[selected]?.truckName + " (Shift Average)"}
        />
      </div>
    </div>
  );
}
