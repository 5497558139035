import moment from 'moment';
import ReactDOM from 'react-dom';
// import cross.svg from './'


export const StartPopup = ({ data }) => {
    const div = document.createElement('div');
    const popup = (

        <div >
            <div className='start_popup row d-flex' style={{ border: "2px solid #92D5DB", position: "relative" }}>
                <img alt="" style={{ height: "2.6rem", width: "2.6rem", position: 'absolute', right: "-1.3rem", top: "-1.3rem", cursor: "pointer" }} src='./assets/images/cross.svg' />
                <div style={{ lineHeight: 2.5, width: "100%" }}>
                    <div className='row d-flex'>
                        <div style={{ color: '#707070' }} className='col-5'>Start:</div>
                        <div className='col-7' style={{ fontWeight: 600 }}>{moment().format('DD/MM/yyyy') === moment(data?.timestamp * 1000).format('DD/MM/yyyy') ? moment(data?.timestamp * 1000).format('hh:mm:ss A') : moment(data?.timestamp * 1000).format('hh:mm:ss A DD/MM/yyyy')}</div>
                    </div>
                </div>
                <div style={{ borderTop: "" }} className='popup-tip-container_start_popup_border' />
                <div className="popup-tip-container_start_popup " />
            </div>
        </div>
    );

    ReactDOM.render(popup, div);
    return div;
};