import React from "react";
import '../../style.css'
import { getHourMinuteDifference } from "../../helper";

const WDBox = ({ data, type, type2 }) => {
    return <div className=' d-flex justify-content-end'>
        <div className='p-2 wd-box'>
            <div className="d-flex align-items-center my-1 wd-box-row">
                <div className="indicator-dot" />
                <div className="label">
                    Planned
                </div>
                <div className="value">
                    {getHourMinuteDifference(0, data?.delays?.[type2] ? (data?.delays?.[type2] * 1000) : 0)}
                </div>
            </div>

            <div className="d-flex align-items-center  my-1 wd-box-row">
                <div className="indicator-dot"
                    style={{
                        backgroundColor: '#F04AA4'
                    }}
                />
                <div className="label">
                    Actual
                </div>
                <div className="value">
                    {getHourMinuteDifference(0, data?.delays?.[type] ? (data?.delays?.[type] * 1000) : 0)}
                </div>
            </div>

            <div className='d-flex align-items-center' style={{
                lineHeight: 2
            }}>

                <span className='mx-2 d-flex align-items-center justify-content-center' style={{
                    backgroundColor: data?.delays?.[type] >= data?.delays?.[type2] ? "#FCE5E5" : "#E2FDF1",
                    color: data?.delays?.[type] >= data?.delays?.[type2] ? "#EB5F5F" : "#3AC49F",
                    borderRadius: 10,
                    height: "1.15rem",
                    width: "3.5rem",
                    fontWeight: '600'
                }}>
                    {data?.delays?.[type2] > data?.delays?.[type] ? '-' : '+'} {getHourMinuteDifference(0, Math.abs(data?.delays?.[type] - data?.delays?.[type2]) * 1000, "")}
                </span>


                <div style={{
                    color: '#5F6672'
                }}>
                    Above planned
                </div>
            </div>
        </div>
    </div >

};

export default WDBox;
