import React from "react";
import moment from "moment-timezone";
import DelaysMontlyYearlyGraph from "../components/DelaysMontlyYearlyGraph";
import PieChartSection from "../components/PieChartSection";
import { getHourMinuteDifference } from "../../helper";
import { constData, issueTypes } from "../../constData";

const DelaysMontlyYearlySection = ({ data, choice }) => {

    const daysInMonth = moment().tz("Pacific/Auckland").daysInMonth();
    const categories = data?.data
        ? data?.data?.map((_, i) =>
            i > 8 ? (i + 1).toString() : `0${i + 1}`
        )
        : Array.from({ length: daysInMonth }, (_, i) =>
            i > 8 ? (i + 1).toString() : `0${i + 1}`
        );

    const listDetails = constData.map((row, j) => ({
        ...row,
        data:
            data?.delays?.[issueTypes[j]] !== undefined
                ? getHourMinuteDifference(
                    0,
                    1000 * data?.delays?.[issueTypes[j]],
                    "h"
                )
                : "00:00 h",
    }));

    const pieChartData = constData.map((row, j) => ({
        ...row,
        value: data?.delays?.[issueTypes[j]]
            ? data?.delays?.[issueTypes[j]]
            : 0,
    }));

    return (
        <div className="row d-flex">
            <div className="col-9"
                style={{
                    borderRight: "1px solid #E1E8F4",
                    // minWidth: "215px",
                    display: "flex",
                    justifyContent: "flex-end",
                    width: "calc(100% - 300px)",
                    height: "275px",
                }}
            >
                <DelaysMontlyYearlyGraph
                    data={data?.data}
                    choice={choice}
                    categories={categories}
                />
            </div>
            <div className="col-3">
                <PieChartSection
                    listDetails={listDetails}
                    data={pieChartData}
                    title="Truck Delays (Shift Totals)"
                />
            </div>
        </div>
    );
};

export default DelaysMontlyYearlySection;
