import React, { useState } from "react";
import AuthLayout from "../../components/AuthLayout";
import { Helmet } from "react-helmet";
import LoadingScreen from "../../components/LoadingScreen";
import { truckOptions, exOptions } from "./data";
import Button from "../ShiftPlanning/components/Button/Button";
import DynamicSelectField from "../ShiftPlanning/components/DynamicSelectField/DynamicSelectField";
import DateTimeSelect from "../ShiftPlanning/components/GeneralFields/DateTimeSelect/DateTimeSelect";
import TextInputField from "../ShiftPlanning/components/GeneralFields/TextInputField";
import axios from "axios";
import { ProgressBar } from 'react-bootstrap';

const Simulator = () => {
    const BASE_URL = "https://staging.bathurst-operator.imos.co.nz"
    const [isLoading, setIsLoading] = useState(false);
    const [progress, setProgress] = useState(0);
    const [progressText, setProgressText] = useState("");
    const [formData, setFormData] = useState({
        truck: "",
        excavator: "",
        startTime: new Date(),
        loads: 0,
    });

    const validateForm = () => {
        if (!formData.truck || !formData.excavator || !formData.startTime || !formData.loads) {
            alert("Please fill in all fields.");
            return false;
        }
        if (formData.loads <= 0 || formData.loads > 3) {
            alert("Number of loads must be greater than 0 and not more than 3.");
            return false;
        }
        return true;
    };

    async function simulateLoads(tagsn, anchorsn, device_id, startTime, totalLoads) {
        setIsLoading(true);
        setProgress(0);
        setProgressText("Starting simulation...");

        let currentTimestamp = Math.floor(startTime.getTime() / 1000); // Convert to Unix timestamp

        const createTagContactElikoPayload = (type, proximity, offset) => ({
            type,
            proximity,
            tag_sn: tagsn,
            anchor_sn: anchorsn,
            unix_time: currentTimestamp + offset,
            limit: 35,
            duration: 305,
            min_dist: 5,
            processed: false,
        });

        const createNewTagContactPayload = (eid, offset) => ({
            message_id: (device_id ?? "") + ((currentTimestamp ?? 0) + (offset ?? 0)).toString(),
            alt: 45,
            car_id: 9976,
            cu: 1,
            device_id: device_id,
            edt: new Date((currentTimestamp + offset) * 1000).toISOString(),
            eid,
            extra: "1",
            fenceEntry: false,
            ign: true,
            latitude: -37.610532,
            longitude: 175.07465,
            nc: null,
            nt: 4,
            processed: false,
            timestamp: currentTimestamp + offset,
            nzDate: new Date((currentTimestamp + offset) * 1000).toLocaleDateString("en-NZ").replace(/\//g, ""),
        });

        try {
            for (let load = 0; load < totalLoads; load++) {
                setProgressText(`Processing load ${load + 1} of ${totalLoads}...`);

                const urls = [
                    {
                        url: BASE_URL + "/createNewTagContactEliko",
                        payload: createTagContactElikoPayload("entry", "nearby", 0),
                    },
                    {
                        url: BASE_URL + "/createNewTagContactEliko",
                        payload: createTagContactElikoPayload("entry", "contact", 100),
                    },
                    {
                        url: BASE_URL + "/createNewTagContactEliko",
                        payload: createTagContactElikoPayload("exit", "contact", 200),
                    },
                    {
                        url: BASE_URL + "/createNewTagContactEliko",
                        payload: createTagContactElikoPayload("exit", "nearby", 300),
                    },
                    {
                        url: BASE_URL + "/createNewTagContact",
                        payload: createNewTagContactPayload(14, 400),
                    },
                    {
                        url: BASE_URL + "/createNewTagContact",
                        payload: createNewTagContactPayload(15, 500),
                    },
                ];

                for (const [i, { url, payload }] of urls.entries()) {
                    setProgressText(`Sending request ${i + 1} of 6 for load ${load + 1}...`);
                    try {
                        await axios.post(url, payload);
                        setProgressText(`Request ${i + 1} of 6 for load ${load + 1} completed.`);
                        setProgress((((load * urls.length + i + 1) / (totalLoads * urls.length)) * 100).toFixed(2));
                    } catch (error) {
                        console.error(`Error with request ${i + 1} of load ${load + 1}:`, error.message);
                        setProgressText(`Error on request ${i + 1} of load ${load + 1}.`);
                        throw Error(error.message);
                    }
                }
                setProgressText(`Waiting before starting next load...`);
                currentTimestamp += 600; // Increment timestamp for next request
                await new Promise((resolve) => setTimeout(resolve, 3000)); // Wait for 3 seconds before sending the next request
            }
            setProgressText("Finalizing simulation...");
            await axios.post(BASE_URL + "/dump/cycle/reprocess", {});
            setProgressText("Simulation completed.");
        } catch (error) {
            console.error("An error occurred during simulation:", error.message);
            setProgressText("Simulation failed. Please check the console for details.");
        } finally {
            // setIsLoading(false);
            setProgress(100);
        }
    }

    const handleSimulate = () => {
        if (validateForm()) {
            simulateLoads(
                formData?.truck.tag_sn,
                formData?.excavator?.anchor_sn,
                formData?.truck?.device_id,
                formData?.startTime,
                formData?.loads
            );
        }
    };

    return (
        <AuthLayout pageName={"Simulator"}>
            <Helmet>
                <title>Simulator | iMOS</title>
            </Helmet>
            <div
                className="right_sidebar_body"
                style={{
                    padding: "0px 2rem",
                    height: "calc(100vh - 5.5em)",
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                }}
            >
                <div style={{ width: "30%", minWidth: "max-content" }}>
                    <DynamicSelectField
                        label={"Excavator"}
                        placeholder={"Select Excavator"}
                        value={formData?.excavator}
                        options={exOptions}
                        onChange={(v) => setFormData((prev) => ({ ...prev, excavator: v }))}
                    />
                    <DynamicSelectField
                        label={"Truck"}
                        placeholder={"Select Truck"}
                        value={formData?.truck}
                        options={truckOptions}
                        onChange={(v) => setFormData((prev) => ({ ...prev, truck: v }))}
                    />

                    <DateTimeSelect
                        label={"Start Time"}
                        value={formData.startTime}
                        onChange={(v) => setFormData((prev) => ({ ...prev, startTime: v }))}
                    />

                    <TextInputField
                        type="number"
                        label={"Number of Loads"}
                        placeholder={"Number of Loads"}
                        value={formData?.loads}
                        onChange={(v) => setFormData((prev) => ({ ...prev, loads: v }))}
                    />
                    <Button className={"my-1"} isDisabled={progress != 0 && progressText != "Simulation completed."} label={"Add Loads"} onClick={handleSimulate} />
                    {isLoading && (
                        <>
                            <div style={{ marginTop: '20px', width: '100%' }}>
                                <ProgressBar
                                    now={progress}
                                    label={`${progress}%`}
                                    animated
                                    striped
                                    style={{
                                        height: '30px',
                                        borderRadius: '5px',
                                        backgroundColor: '#e9ecef',
                                        color: '#007bff',
                                        fontSize: '14px',
                                        fontWeight: 'bold',
                                        textAlign: 'center'
                                    }}
                                />
                            </div>
                            <p style={{
                                marginTop: '10px',
                                fontSize: '14px',
                                color: '#6c757d',
                                fontStyle: 'italic'
                            }}>
                                {progressText}
                            </p>
                        </>
                    )}
                </div>
            </div>
        </AuthLayout>
    );
};

export default Simulator;
