import moment from 'moment';
import ReactDOM from 'react-dom';


export const EndPopup = ({ data }) => {
    const div = document.createElement('div');
    const popup = (
        <div >
            <div className='end_popup ' style={{ position: 'relative', border: "2px solid #E39F9F" }}>

                <img style={{ height: "2.6rem", width: "2.6rem", position: 'absolute', right: "-1.3rem", top: "-1.3rem", cursor: "pointer" }} src='./assets/images/cross.svg' />

                <div style={{ lineHeight: 1.5 }}>
                    <div className='row d-flex'>
                        <div style={{ color: '#707070' }} className='col-5'>End:</div>
                        <div style={{ fontWeight: 600 }} className='col-7'>{moment().format('DD/MM/yyyy') === moment(data?.timestamp * 1000).format('DD/MM/yyyy') ? moment(data?.timestamp * 1000).format('hh:mm:ss A') : moment(data?.timestamp * 1000).format('hh:mm:ss A DD/MM/yyyy')}</div>
                    </div>
                    <div className='row d-flex'>
                        <div style={{ color: '#707070' }} className='col-5'>Duration:</div>
                        <div className='col-7' style={{ fontWeight: 600 }}>{data?.activity_duration}</div>
                    </div>
                    <div className='row d-flex'>
                        <div style={{ color: '#707070' }} className='col-5'>Distance:</div>
                        <div className='col-7' style={{ fontWeight: 600 }}>{data?.activity_distance}</div>
                    </div>
                </div>

                <div className='popup-tip-container_end_popup_border' />
                <div className="popup-tip-container_end_popup" />

            </div>

        </div>
    );

    ReactDOM.render(popup, div);
    return div;
};