import React from "react";
import ProgressBar from "../../components/components/ProgressBar";
import { getHourMinuteDifference } from "../../helper";

export default function ShiftTotalDelaysRow({ selected, onSelected, data, showTarget, targetValue, showTargetDifference,
    colorMore,
    colorLess,
    tagColorLess,
    color,
    tagColorMore }) {

    return (
        <div
            onClick={() => onSelected()}
            className="d-flex justify-content-between"
            style={{
                backgroundColor: selected ? '#F2F7FF' : 'white',
                cursor: "pointer",
                lineHeight: 2.5,
            }}
        >
            <div className="col-7 d-flex justify-content-center align-items-center p-0">
                <div className="w-25"
                    style={{
                        fontSize: "0.75rem" /* Equivalent to 12px */,
                        color: selected ? "black" : "#1A1A1A",
                        width: "2.8125rem" /* Equivalent to 45px */,
                    }}
                >
                    {data?.Unit}
                </div>

                <div className="w-75">
                    <ProgressBar
                        progress={(data?.total / targetValue) * 100}
                        // progress={76}
                        color={color ? color : (data?.total >= targetValue ? colorMore : colorLess)} // Make sure to provide the correct color value
                        height={10}
                        targetValue={targetValue}
                        width={"100%"}
                        showTarget={showTarget}
                    />
                </div>
            </div>
            <div
                className="col-5"
                style={{
                    font: "normal normal 0.8125rem/0.9375rem Open Sans",
                    fontWeight: selected ? "700" : "400",
                    color: selected ? "black" : "#1A1A1A",
                    display: "flex",
                    alignItems: "center",
                    marginLeft: "0.9375rem",
                    width: "5rem",
                    justifyContent: "start",
                }}
            >
                {getHourMinuteDifference(0, data?.total * 1000, "")} {showTargetDifference && <span className='mx-2 d-flex align-items-center justify-content-center' style={{
                    backgroundColor: data?.total >= targetValue ? tagColorMore : tagColorLess,
                    color: data?.total >= targetValue ? colorMore : colorLess,
                    borderRadius: 10,
                    height: "1.15rem",
                    width: "3.5rem",
                    fontWeight: '600'
                }}>
                    {targetValue > data?.total ? '+' : '-'} {getHourMinuteDifference(0, Math.abs(data?.total - targetValue) * 1000, "")}
                </span>}

            </div>
        </div >
    );
}
