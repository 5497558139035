import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';
import moment from "moment";
import React, { useEffect, useRef, useState } from 'react';
import { toast } from "react-toastify";
import { CallWithAuth } from '../../../../action/apiActions';
import { ALL_EQUIPMENTS, TRIP_HISTORY_DATA, GET_HISTORY_DATA_SETTINGS, } from '../../../../action/apiPath';
import { Table } from "../TripsHistory/Table/Table";
import { DropdownData, TripHistoryDropdowns } from './DropdownData';
import "./style.css";
import axios from 'axios';
import FilterBar from './components/FilterBar';
import {
    getFilteredTimestamp,
    downloadReportFromServer
} from '../utils/helper'

const TripsHistory = () => {
    const originalData = useRef([]);
    const [data, setData] = useState([]);
    const [totalLength, setTotalLength] = useState(0);
    const [filteredDataLength, setFilteredDataLength] = useState(0);
    const [minIdleTime, setMinIdleTime] = useState();
    const queryParams = new URLSearchParams(window.location.search);
    const [topSlider, setTopSlider] = useState(true);
    const [filteredValue, setFilteredValue] = useState({
        date: moment().format('YYYY-MM-DD'),
        searchQuery: queryParams.get("asset") ?? "",
        categoryType: DropdownData.categoryType.defaultValues,
        activityType: DropdownData.activityType.defaultValues,
        eventType: DropdownData.eventType.defaultValues,
        statusType: DropdownData.statusType.defaultValues,
        from: { hour: '', minute: '', second: '', isAM: false },
        to: { hour: '', minute: '', second: '', isAM: false },
    })
    const [dropdownData, setDropDownData] = useState(DropdownData)
    const [allEquipments, setAllEquipments] = useState([])
    const [rawGroupedData, setRawGroupedData] = useState([])
    const [isHistoryPageLoading, setIsHistoryPageLoading] = useState(false);
    const [downloadMenu, setDownloadMenu] = useState(false)
    const source = useRef(axios.CancelToken.source());

    const getSettings = async () => {
        try {
            const response = await CallWithAuth('GET', GET_HISTORY_DATA_SETTINGS);
            setMinIdleTime(response?.res?.data?.data?.min_idle_time);
            return response;
        } catch (error) {
            toast.error(error);
        }
    };

    const getSortedData = (data) => {
        const sortedData = {};
        data?.forEach((entry) => {
            if (!sortedData[entry?.Department]) {
                sortedData[entry?.Department] = {};
            }
            if (!sortedData[entry?.Department][entry?.EquipmentType]) {
                sortedData[entry?.Department][entry?.EquipmentType] = [];
            }
            sortedData[entry?.Department][entry?.EquipmentType].push(entry);
        });
        return sortedData;
    };

    function findTripIdleTime(idleMoments) {
        let idleSeconds = 0;
        if (!idleMoments.length) {
            return idleSeconds;
        }
        for (let i = 0; i < idleMoments.length; i++) {
            const element = idleMoments[i];
            if (
                element?.firstLocation?.timestamp && 
                element?.lastLocation.timestamp && 
                (!minIdleTime   ? true :  (element?.lastLocation.timestamp - element?.firstLocation.timestamp >= minIdleTime) )
                ) {
                idleSeconds = idleSeconds + (element?.lastLocation.timestamp - element?.firstLocation.timestamp);
            }
        }
        return idleSeconds;
    }

    const groupDataAccordingToEquipments = (data) => {
        const groupdEquipmentsWithAssets = []
        allEquipments?.map((equipment) => {
            data?.map((asset) => {
                if (asset?.asset_ID == equipment?.Unit) {
                    const groupedAsset = { ...equipment, ...asset }
                    const updatedActivities = asset?.activity?.map((assetActivity) => {
                        return { ...assetActivity, activity_idle_time: findTripIdleTime(assetActivity?.idleMoments) }
                    })
                    groupdEquipmentsWithAssets.push({
                        ...groupedAsset,
                        activity: updatedActivities
                    })
                }
            })
        })
        return groupdEquipmentsWithAssets
    }

    const fetchAllEquipments = async () => {
        try {
            const response = await CallWithAuth("POST", ALL_EQUIPMENTS);
            const equipments = response?.res?.data?.data;

            const allEquipmentTypes = []
            const allEquipmentOptions = []
            equipments?.map((equipment, index) => {
                if (!allEquipmentTypes?.includes(equipment?.EquipmentType)) {
                    allEquipmentTypes.push(equipment?.EquipmentType)
                    allEquipmentOptions.push({ name: equipment?.EquipmentType, value: equipment?.EquipmentType })
                }
            })

            setDropDownData({ ...DropdownData, "categoryType": { options: allEquipmentOptions, defaultValues: allEquipmentTypes } })
            setFilteredValue({ ...filteredValue, categoryType: allEquipmentTypes })
            setAllEquipments(equipments)
        } catch (error) {
            console.log("error", error)
            toast.error(error)
        }
    }

    useEffect(() => {
        fetchAllEquipments()
    }, [])

    useEffect(() => {
        setFilteredValue(prev => ({ ...prev, searchQuery: queryParams.get("asset") ?? "" }))
    }, [queryParams.get("asset")])


    const fetchTripshistorydata = async ({ cancelToken = "" }) => {

        try {
            const response = await CallWithAuth("POST", TRIP_HISTORY_DATA, {
                date: filteredValue?.date
            }, cancelToken);
            if (response?.res?.status == 200) {
                setIsHistoryPageLoading(false)
            }
            const rawData = groupDataAccordingToEquipments(response?.res?.data?.data)
            const groupedData = getSortedData(rawData)
            if (JSON?.stringify(groupedData) != JSON?.stringify(originalData?.current)) {
                setTotalLength(response?.res?.data?.data?.length)
                setRawGroupedData(rawData)
                originalData.current = groupedData
            }

        } catch (error) {
            console.log("error", error)
            toast.error(error)
            setIsHistoryPageLoading(false)
        }

    }

    const generateCanvasPdf = () => {
        let dashboard_header = document.getElementById('dashboard_header');
        let height1 = dashboard_header.getBoundingClientRect().height;

        let button_header = document.getElementById('button_header');
        button_header.style.removeProperty('box-shadow');
        let height2 = button_header.getBoundingClientRect().height;

        let excavator_part = document.getElementById('excavator_part');
        excavator_part.style.removeProperty('box-shadow');
        let height3 = excavator_part.getBoundingClientRect().height;

        let truck_part = document.getElementById('truck_part');
        let height4 = truck_part.getBoundingClientRect().height;

        let truck_cover = document.getElementsByClassName('truck_cover');
        for (var i = 0; i < truck_cover.length; i++) {
            truck_cover[i].style.removeProperty('border-right');
        }

        html2canvas(dashboard_header).then((canvas1) => {
            const imgData1 = canvas1.toDataURL(' image/jpeg');
            html2canvas(button_header).then((canvas2) => {
                const imgData2 = canvas2.toDataURL(' image/jpeg');
                html2canvas(excavator_part).then((canvas3) => {
                    const imgData3 = canvas3.toDataURL(' image/jpeg');
                    html2canvas(truck_part)
                        .then((canvas4) => {
                            const imgData4 = canvas4.toDataURL(' image/jpeg');
                            let pdf = new jsPDF('1', 'px', [
                                1480,
                                height1 + height2 + height3 + height4 + 200,
                            ]);

                            pdf.addImage(imgData1, 'JPEG', 0, 40, 1400, height1);
                            pdf.addImage(imgData2, 'JPEG', 0, height1 + 50, 1480, height2);
                            pdf.addImage(imgData3, 'JPEG', 10, height2 + 140, 1470, height3);
                            pdf.addImage(imgData4, 'JPEG', 10, height3 + 250, 1480, height4);

                            pdf.save('Hourly Report');
                        })
                        .then(() => {
                            button_header.style.boxShadow = 'rgba(0, 0, 0, 0.16) 0px 3px 6px';
                            for (var i = 0; i < truck_cover.length; i++) {
                                truck_cover[i].style.borderRight = '1px solid #E1E8F4';
                            }
                        });
                });
            });
        });
    };

    const generateSimplePDFByCanvas = () => {
        let dashboard_header = document.getElementById('dashboard_header');
        let height1 = dashboard_header.getBoundingClientRect().height;

        let truck_excavator_summary = document.getElementById(
            'truck_excavator_summary'
        );
        truck_excavator_summary.style.removeProperty('box-shadow');

        // button_header.style.removeProperty("box-shadow");
        let height2 = truck_excavator_summary.getBoundingClientRect().height;

        let excavator_heading = document.getElementById('excavator_heading');
        // y_scroll
        let y_scroll = document.getElementById('y_scroll');
        y_scroll.style.overflowY = 'visible';
        let height3 = excavator_heading.getBoundingClientRect().height;

        let excavatorCard_container = document.getElementById(
            'excavatorCard_container'
        );
        let height4 = excavatorCard_container.getBoundingClientRect().height;

        let excavator_card_container = document.getElementsByClassName(
            'excavator_card_container'
        );
        for (var i = 0; i < excavator_card_container.length; i++) {
            excavator_card_container[i].style.removeProperty('box-shadow');
        }

        let remove_scroll = document.getElementsByClassName('remove_scroll');
        //  if(remove_scroll.scrollHeight > remove_scroll.clientHeight)
        // console.log(r)
        {
            for (var i = 0; i < remove_scroll.length; i++) {
                remove_scroll[i].style.height = 'max-content';
            }
        }

        let remove_scroll_container = document.getElementsByClassName(
            'remove_scroll_container'
        );
        // if(remove_scroll_container.scrollHeight > remove_scroll_container.clientHeight )
        {
            for (var i = 0; i < remove_scroll_container.length; i++) {
                remove_scroll_container[i].style.height = 'max-content';
            }
        }

        html2canvas(dashboard_header).then((canvas1) => {
            const imgData1 = canvas1.toDataURL(' image/jpeg');
            html2canvas(truck_excavator_summary).then((canvas2) => {
                const imgData2 = canvas2.toDataURL(' image/jpeg');
                html2canvas(excavator_heading).then((canvas3) => {
                    const imgData3 = canvas3.toDataURL(' image/jpeg');
                    html2canvas(excavatorCard_container)
                        .then((canvas4) => {
                            const imgData4 = canvas4.toDataURL(' image/jpeg');
                            let pdf = new jsPDF('1', 'px', [
                                1480,
                                height1 + height2 + height3 + height4 + 200,
                            ]);

                            pdf.addImage(imgData1, 'JPEG', 0, 40, 1400, height1);
                            pdf.addImage(imgData2, 'JPEG', 0, height1 + 50, 1480, height2);
                            pdf.addImage(imgData3, 'JPEG', 20, height2 + 140, 1480, height3);
                            topSlider
                                ? pdf.addImage(
                                    imgData4,
                                    'JPEG',
                                    0,
                                    height3 + 500,
                                    1480,
                                    height4
                                )
                                : pdf.addImage(
                                    imgData4,
                                    'JPEG',
                                    0,
                                    height3 + 200,
                                    1480,
                                    height4
                                );

                            pdf.save('Dashboard Report');
                        })
                        .then(() => {
                            truck_excavator_summary.style.boxShadow =
                                'rgba(0, 0, 0, 0.06) 0px 8px 6px;';
                            for (var i = 0; i < excavator_card_container.length; i++) {
                                excavator_card_container[i].style.boxShadow =
                                    'rgb(0 0 0 / 16%) 0px 3px 6px';
                            }
                            for (var i = 0; i < remove_scroll.length; i++) {
                                remove_scroll[i].style.height = '120px';
                            }

                            for (var i = 0; i < remove_scroll_container.length; i++) {
                                remove_scroll_container[i].style.height = '230px';
                            }
                            y_scroll.style.overflowY = 'scroll';
                        });
                });
            });
        });
    };

    const mainDownloadHandler = async (optionNo) => {
        if (optionNo == 0) {
            source?.current?.cancel()
            source.current = axios.CancelToken.source();
            await downloadReportFromServer(
                filteredValue.date,
                getFilteredTimestamp(filteredValue.startTime, filteredValue.date),
                getFilteredTimestamp(filteredValue.endTime, filteredValue.date),
                undefined,
                source.current.token,
                filteredValue?.assets ?? [],
                "",
                "GENERAL",
                true
            )
        }
    }

    const dropdownStyle = {
        display: "flex",
        alignItems: "center",
        minWidth: "10rem",
        height: "2.5rem",
        // border: "0.1rem solid #EBEBEB",
        // padding: "0.14rem",
        borderRadius: "0.188rem",
        background: "#FFFFFF 0% 0 % no - repeat padding- box",
    }

    useEffect(() => {
        const handleSearch = (unFilteredData) => {
            if (filteredValue.searchQuery === "") {
                return unFilteredData;
            }
            return unFilteredData.filter((item) => {
                return item?.asset_ID?.toLowerCase().includes(filteredValue?.searchQuery.toLowerCase())
            })
        }

        const filterByCategory = (unFilteredData) => {
            if (filteredValue?.categoryType.includes("Truck") && filteredValue?.categoryType.includes("Excavator")) {
                return unFilteredData;
            }
            return unFilteredData.filter((item) => filteredValue.categoryType.includes(item.category));
        };

        const filterByStatus = (unFilteredData) => {
            if (filteredValue?.statusType.includes("Active") && filteredValue?.statusType.includes("Disconnected") && filteredValue?.statusType.includes("Parked")) {
                return unFilteredData;
            }
            return unFilteredData.filter((item) =>
                item.activity_status == "Idling" ? filteredValue?.statusType?.includes("Active") : filteredValue?.statusType?.includes(item.activity_status)
            )
        };

        const filterByEvent = (unFilteredData) => {
            if (filteredValue?.eventType.includes("Speeding") && filteredValue?.eventType.includes("Idle Time")) {
                return unFilteredData;
            }
            else {
                return unFilteredData.map((item) => {
                    if (filteredValue.eventType.includes('Speeding')) {
                        return { ...item, activity: item?.activity?.filter((subitem) => subitem?.maxSpeed >= 30) }
                    } else if (filteredValue.eventType.includes('Idle Time')) {
                        return { ...item, activity: item?.activity.filter((subitem) => subitem?.activity_idle_time > 0) }
                    }
                });

            }
        };

        const filterByActivity = (unFilteredData) => {
            return unFilteredData.map((item) => {
                const filteredActivity = item.activity.filter((subitem) => {
                    return filteredValue?.activityType?.includes(subitem.activity_type);
                });
                return { ...item, activity: filteredActivity };
            });
        };

        const filterByTime = (unFilteredData) => {

            const startTime = getFilteredTimestamp(filteredValue?.from, filteredValue.date);
            const endTime = getFilteredTimestamp(filteredValue?.to, filteredValue.date);
            console.log(startTime, endTime);
            return unFilteredData.map((item) => {
                const filteredActivity = item.activity.filter((subitem) => (
                    (!startTime || subitem.startTime >= startTime) && (!endTime || subitem.endTime <= endTime)
                ));
                return { ...item, activity: filteredActivity };
            });
        };

        const filterData = () => {
            let filteredData = rawGroupedData;
            filteredData = handleSearch(filteredData);
            filteredData = filterByCategory(filteredData);
            filteredData = filterByStatus(filteredData);
            filteredData = filterByActivity(filteredData);
            filteredData = filterByEvent(filteredData);
            filteredData = filterByTime(filteredData);
            setFilteredDataLength(filteredData?.length)
            setData(getSortedData(filteredData))
        };

        try {
            filterData()
        } catch (error) {
            console.log("-----", error);
        }

    }, [(filteredValue), rawGroupedData]);

    useEffect(() => {
        setIsHistoryPageLoading(true)
        const fetchData = () => {
            fetchTripshistorydata({ cancelToken: source?.current?.token });
        };
        const intervalId = setInterval(fetchData, 120000);
        return () => {
            source.current.cancel('Request canceled by cleanup');
            clearInterval(intervalId);
        };
    }, [filteredValue?.date, allEquipments]);

    useEffect(() => {
        source?.current?.cancel()
        source.current = axios.CancelToken.source();
        getSettings();
        fetchTripshistorydata({ cancelToken: source?.current?.token })
    }, [filteredValue?.date, allEquipments, minIdleTime])

    return (
        <div >
            <div className="trips-history">
                <FilterBar
                    getSettings={getSettings}
                    setFilteredValue={setFilteredValue}
                    TripHistoryDropdowns={TripHistoryDropdowns}
                    filteredValue={filteredValue}
                    loading={isHistoryPageLoading}
                    downloadMenu={downloadMenu}
                    dropdownData={dropdownData}
                    dropdownStyle={dropdownStyle}
                    setDownloadMenu={setDownloadMenu}
                    mainDownloadHandler={mainDownloadHandler} />
                <Table
                    minIdleTime={minIdleTime}
                    loading={isHistoryPageLoading}
                    tripHistoryData={data}
                    activityType={filteredValue.activityType}
                    categoryType={filteredValue.categoryType}
                    date={filteredValue?.date}
                    totalLength={totalLength}
                    filteredDataLength={filteredDataLength}
                    startTIme={getFilteredTimestamp(filteredValue?.from, filteredValue.date)}
                    endTime={getFilteredTimestamp(filteredValue?.to, filteredValue.date)}
                />
            </div>
        </div >
    )
}

export default TripsHistory;
