import React from "react";
import { Form } from "react-bootstrap";

const HeaderFormSelect = ({equipmentType, setEquipmentType}) => {
    return <Form.Group
        className="d-flex align-items-start"
        controlId="formBasicSelect">
        <Form.Control
            as="select"
            value={equipmentType}
            onChange={(v) =>
                setEquipmentType(v.target.value)
            }
            style={{
                fontSize: ".75rem",
                borderRadius: "5px",
                color: "grey", margin: " 9px",
                // padding: "1px 7px",
                width: "10rem",
                height:'1.8rem'
            }}
            // className="d-flex align-items-start"
        >
            <option className="d-flex align-items-start" value={0}>Trucks</option>
            <option value={1}>Excavators</option>
        </Form.Control>
    </Form.Group>
};

export default HeaderFormSelect;
