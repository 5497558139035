import { useState, useEffect } from "react";
import ReactEcharts from "echarts-for-react";
import { getHourMinuteDifference } from "../../helper";
import ChartHover from "./ChartHover";
import DelaysChartHoverDetails from "./DelaysChartHoverDetails";

export default function DelaysMontlyYearlyGraph({ data, categories, choice }) {
    const [isOpen, setIsOpen] = useState(false);
    const [cardContent, setCardContent] = useState({});
    const [coordinates, setCoordinates] = useState({});
    const [graphPosition, setGraphPosition] = useState({});
    const [showDetails, setShowDetails] = useState(false);

    const handlePointClick = (params) => {
        const index = params.dataIndex;

        const cardObject = {
            ...data[index],
            date: choice === 0 ? (index + 1) : "",
            month: choice === 0 ? new Date().toLocaleString('en-NZ', { timeZone: 'Pacific/Auckland', month: 'long' }) : (() => {
                const date = new Date();
                date.setMonth(index);
                const longMonthName = date.toLocaleString('en-NZ', { timeZone: 'Pacific/Auckland', month: 'long' });
                return longMonthName;
            })(),
        };
        const coordinate = {
            x: params.event.offsetX,
            y: params.event.offsetY
        };

        console.log(coordinate);

        setCoordinates(coordinate);
        setCardContent(cardObject);
        setIsOpen(true);
    };

    useEffect(() => {
        const graphElement = document.querySelector(".graphContainer");
        const rect = graphElement.getBoundingClientRect();
        setGraphPosition({
            top: rect.top + window.scrollY,
            left: rect.left + window.scrollX,
            width: rect.width,
            height: rect.height,
        });

        const handleClickOutside = (event) => {
            const chartHoverElement = document.getElementById("popup");
            if (
                chartHoverElement &&
                !chartHoverElement.contains(event.target) &&
                !document.getElementById("popup").contains(event.target)
            ) {
                setIsOpen(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const onEvents = {
        click: handlePointClick,
    };

    const option = {
        tooltip: {
            trigger: "axis",
            formatter: (params) => {
                return "";
            },
        },
        // Configuration for the tooltip
        //     tooltip: {
        //         backgroundColor: 'white',
        //         borderColor: '#E1E8F4',
        //         padding: 0,
        //         borderRadius: 8,
        //         formatter: (x) => `
        //   <div style="width: max-content; padding: 15px; display: flex; flex-direction: column;
        //     background: #FFFFFF 0% 0% no-repeat padding-box; border-radius: 8px;">
        //     <span style="font-family: 'Open Sans'; font-size: 12px; font-weight: 600;">
        //       ${x?.name}
        //     </span>
        //     <span style="margin-bottom: 4.5px; font-size: 12px; font-family: 'Open Sans'; font-style: italic;
        //       font-weight: 600; color: #5F6672;">
        //       Idle Time Delays (ITD)
        //     </span>
        //     <span style="font-family: 'Open Sans'; font-size: 12px; font-weight: 900; color: black;">
        //       ${getHourMinuteDifference(0, x.value * 1000)}
        //     </span>
        //   </div>
        // `
        //     },
        // Configuration for the grid
        grid: {
            left: "60px",
            right: "4.5%",
            bottom: "25px",
            height: "85%",
            // width: '90%',
            paddinTop: 0,
            containLabel: true,
            backgroundColor: "#f5f5f5", // Background color for the grid
        },
        // Configuration for the X-axis
        xAxis: {
            name: "Date",
            nameLocation: "end",
            nameGap: 6,
            // alignTicks: false,
            axisTick: {
                show: false,
            },
            nameTextStyle: {
                fontFamily: "Open Sans",
                fontWeight: "bold",
                fontSize: "12px",
                color: "#728BB5",
            },
            axisPointer: {
                type: "shadow",
            },
            axisLabel: {
                color: "#576B8D",
                // lineHeight: 150
                margin: 20,
                clickable: true,
            },
            data: categories,
        },
        // Configuration for the Y-axis
        yAxis: {
            show: true,
            name: "Delay Hours",
            nameLocation: "middle",
            nameGap: 55,
            axisLabel: {
                show: true,
                formatter: (v) => getHourMinuteDifference(0, v * 1000), // Add the label for the Y-axis here
            },
            nameTextStyle: {
                fontFamily: "Open Sans",
                fontWeight: "bold",
                fontSize: "12px",
                color: "#728BB5",
            },
        },
        // Configuration for the series (data)
        series: [
            {
                type: "line",
                name: "Line 1",
                data: data?.map(d => d.total),
                showBackground: true,
                showSymbol: false,
                label: {
                    show: false,
                    fontSize: "10px",
                    position: "outside",
                    fontWeight: "600",
                    color: "#576B8D",
                    formatter: (v) =>
                        getHourMinuteDifference(0, v.value * 1000),
                },
                emphasis: {
                    focus: "series",
                },
                itemStyle: {
                    color: "#1ED5C6",
                },
            },
        ],
    };

    return (
        <div
            className="graphContainer"
            style={{ height: "100%", width: "100%" }}
        >
            <ReactEcharts option={option} onEvents={onEvents} />
            {isOpen && (
                <div id="popup">
                    <ChartHover
                        month={data.month}
                        year={data.year}
                        setIsOpen={setIsOpen}
                        data={cardContent}
                        coordinate={coordinates}
                        setShowDetails={setShowDetails}
                    />
                </div>
            )}
            {showDetails && (
                <DelaysChartHoverDetails
                    month={data.month}
                    data={cardContent}
                    closeviewDetails={setShowDetails}
                    coordinate={coordinates}
                />
            )}
        </div>
    );
}




































// export default function DelaysMontlyYearlyGraph({ data }){
//     console.log(data);
// const [hoveredPoint, setHoveredPoint] = useState(null);
//     const [clickedXValue, setClickedXValue] = useState(null);
//     const [isPopupVisible, setIsPopupVisible] = useState(false);

//     const handleXValueClick = (params) => {
//         if (params.value !== clickedXValue) {
//             setClickedXValue(params.value);
//             setIsPopupVisible(true);
//         } else {
//             setClickedXValue(null);
//             setIsPopupVisible(false);
//         }
//     }

//     const option = {
//         tooltip: {
//              trigger:'axis',
//             backgroundColor: 'white',
//             borderColor: '#E1E8F4',
//             padding: 0,
//             borderRadius: 8,
//             formatter: (x) => `

//       <div style="width: max-content; padding: 15px; display: flex; flex-direction: column;
//         background: #FFFFFF 0% 0% no-repeat padding-box; border-radius: 8px;">
//         <span style="font-family: 'Open Sans'; font-size: 12px; font-weight: 600;">
//           ${x?.name}
//         </span>
//         <span style="margin-bottom: 4.5px; font-size: 12px; font-family: 'Open Sans'; font-style: italic;
//           font-weight: 600; color: #5F6672;">
//           Idle Time Delays (ITD)
//         </span>
//         <span style="font-family: 'Open Sans'; font-size: 12px; font-weight: 900; color: black;">
//           ${getHourMinuteDifference(0, x.value * 1000)}
//         </span>
//       </div>
//     `
//         },
//         // Configuration for the grid
//         grid: {
//             left: '60px',
//             right: '4.5%',
//             bottom: '25px',
//             height: '85%',
//             // width: '90%',
//             paddinTop: 0,
//             containLabel: true,
//             backgroundColor: '#f5f5f5', // Background color for the grid
//         },
//         // Configuration for the X-axis
//         xAxis: {
//             name: 'Date',
//             nameLocation: 'end',
//             nameGap: 6,
//             // alignTicks: false,
//             axisTick: {
//                 show: false
//             },
//             nameTextStyle: {
//                 fontFamily: 'Open Sans',
//                 fontWeight: 'bold',
//                 fontSize: '12px',
//                 color: '#728BB5',
//             },
//             axisPointer: {
//                 type: 'shadow',
//                 label: {
//                     show: true,
//                     formatter: (params) => {
//                         if (params.value === clickedXValue) {
//                             return params.value;
//                         }
//                         return '';
//                     },
//                 },
//             },
//             axisLabel: {
//                 color: '#576B8D',
//                 // lineHeight: 150
//                 margin: 20,
//                 clickable: true,
//                 // onClick: (params) => {
//                 //     handleXValueClick(params.value);
//                 // },
//             },
//             data: data.categories,
//         },
//         // Configuration for the Y-axis
//         yAxis: {
//             show: true,
//             name: 'Delay Hours',
//             nameLocation: 'middle',
//             nameGap: 55,
//             axisLabel: {
//                 show: true,
//                 formatter: (v) => getHourMinuteDifference(0, v * 1000), // Add the label for the Y-axis here
//             },
//             nameTextStyle: {
//                 fontFamily: 'Open Sans',
//                 fontWeight: 'bold',
//                 fontSize: '12px',
//                 color: '#728BB5',
//             }
//         },
//         // Configuration for the series (data)
//         series: [
//             {
//                 type: 'line',
//                 data: data.values,
//                 showBackground: true,
//                 showSymbol: false,
//                 label: {
//                     show: false,
//                     fontSize: '10px',
//                     position: 'outside',
//                     fontWeight: '600',
//                     color: '#576B8D',
//                     formatter: (v) => getHourMinuteDifference(0, v.value * 1000)
//                 },
//                 itemStyle: {
//                     color: '#1ED5C6',
//                 },
//             },
//         ],
//         emphasis:{
//             focus:'series'
//         }
//     };

//     return <div style={{ height: '100%', width: '100%' }} >
//         <ReactEcharts option={option}/>

//     </div>;
// };
