import moment from "moment";
import React from "react";
import { secondsToHhMm } from "../../../../../utils/helper";
import "./dropdowns.css";




const Details = ({ data = {}, }) => {

  return (
    <div className="details-dropdown" style={{ marginBottom: "1.313rem" }}>
      <div className="d-flex row">
        <div className="details-key col-3">START:</div> <div className="col-9 details-value ">
          {moment(data?.firstLocation?.timestamp * 1000).format('hh:mm:ss a')}
        </div>
      </div>

      <div className="d-flex row ">
        <div className="details-key col-3 ">FINISH:  </div> <span className=" col-9 details-value">
          {moment(data?.lastLocation?.timestamp * 1000).format('hh:mm:ss a')}
        </span>
      </div>

      <div>
        {/* <div className="col-4 details-key">DURATION :</div>
        <span className="col-8 details-value">
          {secondsToHhMm(data?.lastLocation?.timestamp - data?.firstLocation?.timestamp) + '  '}
        </span> */}
        <div className="details-key" style={{ marginTop: "1.5rem" }}>
        DURATION:
          </div>
          <div className="details-value">
          {secondsToHhMm(data?.lastLocation?.timestamp - data?.firstLocation?.timestamp) + '  '}
          </div>
      </div>
 
      {data?.activity_type === "Trip" &&
        <div className="d-flex row" style={{ marginTop: "1.5rem" }}>
          <div className="details-key col-3">FROM: </div>
          <span className="details-value col-9"> {data?.firstLocation?.latitude},  {data?.firstLocation?.longitude}</span>
        </div>
      }
      {data?.activity_type === "Trip" &&
        <div className="d-flex row">
          <div className="details-key col-3">TO: </div> <span className="details-value col-9">
            {data?.lastLocation?.latitude},  {data?.lastLocation?.longitude}</span>


        </div>
      }
      {data?.activity_type === "Park" &&
        <div className="d-flex row mt-2">
          <div className="col-3 details-key">AT : </div> <span className="col-9 details-value">
            {data?.firstLocation?.latitude},  {data?.firstLocation?.longitude}
          </span>
        </div>
      }

      {/* <div className="details-key" style={{ marginTop: "1rem" }}>
        OPERATOR :
      </div>
      <div className="details-value">
        {data?.activity_operator}
      </div> */}

      {data?.activity_type === "Trip" &&
        <div>
          <div className="details-key" style={{ marginTop: "1.5rem" }}>
            DISTANCE
          </div>
          <div className="details-value">
            {((data?.lastLocation?.odo - data?.firstLocation?.odo)).toFixed(2)} km
          </div>
          <div className="details-key" style={{ marginTop: "1.5rem" }}>
            MAX SPEED
          </div>
          <div className="details-value">
            {data?.maxSpeed} km/h
          </div>
          <div className="details-key" style={{ marginTop: "1.5rem" }}>
            TOTAL IDLE TIME
          </div>
          <div className="details-value">
            {secondsToHhMm(data?.activity_idle_time ?? 0) + '  '}
          </div>
        </div>
      }
    </div>
  );
}
export default Details;

