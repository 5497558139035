import React, { useEffect, useState } from "react";
import Flyer from "pages/ShiftPlanning/components/Flyers/Flyer";
import CRUDComponent from "./CRUDComponent";
import moment from "moment-timezone";
import { CallWithAuth, CallWithAuthFormData } from "action/apiActions";
import { toast } from "react-toastify";
import { Riple } from "react-loading-indicators";
import DeleteYears from "pages/DigRates/components/DeleteYears";

export default function CRUDModal({ isOpen, onCancel, selectedAsset, MODULE_START_YEAR, onSaveChanges, editMode, setSavedChanges }) {
    const CURRENT_YEAR = moment().tz("Pacific/Auckland").year();
    const EMPTY_MONTHLY_DATA = {
        "1": null,
        "2": null,
        "3": null,
        "4": null,
        "5": null,
        "6": null,
        "7": null,
        "8": null,
        "9": null,
        "10": null,
        "11": null,
        "12": null,
    }

    const [isLoading, setIsLoading] = useState(false)
    const [showError, setShowError] = useState(false);
    const [viewOnly, setViewOnly] = useState(true);
    const [changeTab, setChangeTab] = useState(0);
    const [OpenDeleteModal, setOpenDeleteModal] = useState(false)
    const [originalData, setOriginalData] = useState({
        equipment: {},
        yearlyData: []
    })
    const [formData, setFormData] = useState({
        equipment: {},
        yearlyData: []
    })

    useEffect(() => {
        const fetchData = async () => {
            try {
                setIsLoading(true)
                const response = await CallWithAuth("GET", "api/bcmHr?equipmentId=" + selectedAsset._id)
                setOriginalData(response?.res?.data?.[0])
                setFormData(response?.res?.data?.[0])
                const idx = response?.res?.data?.[0]?.yearlyData?.findIndex((y) => y.year == CURRENT_YEAR)
                setChangeTab(idx == -1 ? 0 : idx)

            } catch (error) {
                console.log(error);
                toast(error.message)
            }
            setIsLoading(false)
        }
        fetchData()

    }, [selectedAsset])

    useEffect(() => {
        setViewOnly(editMode)
    }, [editMode])

    const handleFieldChange = (location, value) => {
        setFormData({
            ...formData,
            yearlyData: formData?.yearlyData?.map((v) =>
                v.year === location.year
                    ? {
                        ...v,
                        monthlyData: {
                            ...v.monthlyData,
                            [location.month + 1]: value,
                        },
                    }
                    : v
            ),
        });
    };

    const handleDeleteYears = async (selectedYears) => {

        try {
            const response = await CallWithAuth("POST", `/api/bcmHr/delete-years`, {
                equipmentId: selectedAsset?._id,
                years: selectedYears
            });

            if (response?.res?.status === 200) {
                toast.success("Data deleted successfully!");
                setOriginalData((prev) => ({ ...prev, yearlyData: prev?.yearlyData?.filter(d => !selectedYears.includes(d.year)) }))
                setFormData((prev) => ({ ...prev, yearlyData: prev?.yearlyData?.filter(d => !selectedYears.includes(d.year)) }))
                setSavedChanges(true)
            } else {
                throw new Error('Failed to delete data');
            }
        } catch (error) {
            console.error('Error deleting data:', error);
            toast.error('An error occurred while deleting data. Please try again.');
        }


        setOpenDeleteModal(false)
        setChangeTab(0)
    }

    function getUpdatedYearsData(originalData, formData) {
        // Convert originalData into a hash map for faster lookup
        const originalDataMap = originalData.reduce((acc, yearData) => {
            acc[yearData.year] = yearData.monthlyData;
            return acc;
        }, {});

        const updates = [];

        formData.forEach((formYear) => {
            const originalYearData = originalDataMap[formYear.year];
            const updatedMonths = [];

            // If the year doesn't exist in originalData, treat all months as new updates
            if (!originalYearData) {
                for (const [month, value] of Object.entries(formYear.monthlyData)) {
                    updatedMonths.push({
                        month: parseInt(month), // Convert month string to number
                        value: parseInt(value)
                    });
                }
            } else {
                // Compare only if the year exists in originalData
                for (const [month, formValue] of Object.entries(formYear.monthlyData)) {
                    const originalValue = originalYearData[month] || null;

                    // Compare values and add only the changed months
                    if (formValue !== originalValue) {
                        updatedMonths.push({
                            month: parseInt(month),
                            value: parseInt(formValue)
                        });
                    }
                }
            }

            // Add the year with updated months if there are changes or if it's a new year
            if (updatedMonths.length > 0) {
                updates.push({
                    year: formYear.year,
                    monthlyData: updatedMonths
                });
            }
        });

        return updates;
    }

    const saveChanges = async () => {
        try {
            const reqBody = {
                equipmentId: selectedAsset?._id,
                yearsData: getUpdatedYearsData(originalData?.yearlyData, formData?.yearlyData),
                deletedYears: originalData?.yearlyData?.filter((d) =>
                    !formData?.yearlyData?.find((fd => fd.year == d.year))
                )?.map(d => d.year)
            };

            const response = await CallWithAuth("POST", '/api/bcmHr/update', reqBody);

            if (response?.res?.status === 201) {
                toast.success("Dig rates updated successfully!");
                setSavedChanges(true)
            } else {
                throw new Error('Failed to update dig rates');
            }
        } catch (error) {
            setFormData(originalData)
            console.error('Error saving changes:', error);
            toast.error('An error occurred while updating dig rates. Please try again.');
        }
    };

    return (
        <>
            {OpenDeleteModal && <DeleteYears handleDeleteYears={handleDeleteYears} setShowDeleteYears={setOpenDeleteModal} years={formData?.yearlyData?.filter(d => moment().tz("Pacific/Auckland").year() < d?.year).map((d) => d.year?.toString())} showDeleteYears={OpenDeleteModal} />}
            <Flyer
                showVerticalJumpTo={true}
                isOpen={isOpen}
                wantSingleTab={true}
                onClose={onCancel}
                containerStyle={{
                    zIndex: 99,
                    top: 120,
                    right: 19,
                    position: 'fixed',
                    height: 'calc(100% - 13rem)',
                    width: '620px',
                    top: '127px',
                    right: '19px',
                    zIndex: 99,
                }}
                setChangeTab={setChangeTab}
                title={`${selectedAsset?.Unit} ${selectedAsset?.model} - Dig Rates`}
                changeTab={changeTab}

                tabs={isLoading ? [
                    {
                        title: CURRENT_YEAR?.toString(),
                        component: (
                            <div style={{ height: '100%' }} className="d-flex align-items-center justify-content-center">
                                <Riple color="#317bcc" size="medium" text="" textColor="" />
                            </div>
                        ),
                    }, {}
                ] : formData?.yearlyData?.map((data) =>
                ({
                    title: data?.year,
                    leftActionButtons: viewOnly ? [
                        {
                            label: "Edit",
                            style: { border: "1px solid  #186FD9", },
                            onClick: () => {
                                setViewOnly(false);
                            },
                        },
                        {
                            label: "Add Year",
                            style: { backgroundColor: "transparent", color: "#186FD9", border: "1px solid #E1E8F4" },
                            onClick: () => {
                                const start = formData?.yearlyData && formData.yearlyData.length > 0
                                    ? Math.min(...formData.yearlyData.map((x) => x.year))
                                    : moment.tz("Pacific/Auckland").year();
                                ;
                                console.log(start, formData.yearlyData);

                                const missIndex = formData?.yearlyData?.findIndex((x, i) => x.year !== (i + start));

                                const newYear = {
                                    year: start + (missIndex === -1 ? formData?.yearlyData?.length : missIndex),
                                    monthlyData: EMPTY_MONTHLY_DATA
                                };

                                if (missIndex === -1) {
                                    setFormData({
                                        ...formData,
                                        yearlyData: [...formData.yearlyData, newYear],
                                    });
                                } else {
                                    setFormData({
                                        ...formData,
                                        yearlyData: [
                                            ...formData.yearlyData.slice(0, missIndex),
                                            newYear,
                                            ...formData.yearlyData.slice(missIndex),
                                        ]
                                    });
                                }
                                setChangeTab(missIndex == -1 ? formData?.yearlyData?.length : missIndex)
                                setViewOnly(false);
                            },
                        }
                    ] : [
                        moment().tz("Pacific/Auckland").year() < data?.year && {
                            label: "Delete Year",
                            style: { backgroundColor: "transparent", color: "#186FD9", border: "1px solid #E1E8F4" },
                            onClick: () => {
                                const filteredData = formData?.yearlyData?.filter(d => d.year !== data?.year);
                                const idx = formData?.yearlyData?.findIndex(d => d.year === data?.year);

                                setChangeTab(idx - 1);
                                setFormData({ ...formData, yearlyData: filteredData });
                            },
                        }
                    ].filter(Boolean),
                    rightActionButtons: viewOnly ? [
                        {
                            label: "Delete Year",
                            isDisabled: formData?.yearlyData?.filter(d => moment().tz("Pacific/Auckland").year() < d?.year)?.length == 0,
                            style: { backgroundColor: "transparent", color: "#186FD9", border: "1px solid #E1E8F4" },
                            onClick: () => {
                                setOpenDeleteModal(true);
                            },
                        }
                    ] : [
                        {
                            label: "Save Changes",
                            style: { border: "1px solid  #186FD9" },
                            onClick: async () => {
                                await saveChanges()
                                setViewOnly(true);
                            },
                        },
                    ],
                    component: (
                        <CRUDComponent
                            originalFormData={originalData?.yearlyData?.find((o) => o.year == data?.year)?.monthlyData || {}}
                            setFormData={setFormData}
                            formData={data?.monthlyData || {}}
                            year={data?.year}
                            showError={showError}
                            viewOnly={viewOnly}
                            handleFieldChange={handleFieldChange}
                        />
                    ),
                })
                ) || []}
            />
        </>
    );
}
