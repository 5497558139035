import { useState, useEffect } from "react";
import ReactEcharts from "echarts-for-react";
import { getHourMinuteDifference } from "../../helper";
import WDChart from "./WDChart";
import WDChartViewDetails from "./WDChartViewDetails";

export default function SpecificDelayMontlyYearlyGraph({ categories, data, type, choice, type2 }) {
    const [isOpen, setIsOpen] = useState(false);
    const [coordinates, setCoordinates] = useState({});
    const [showDetails, setShowDetails] = useState(false);
    const [cardData, setCardData] = useState(null)


    const handlePointClick = (params) => {
        const index = params.dataIndex;
        const cardObject = {
            ...data[index],
            date: choice === 0 ? (index + 1) : "",
            month: choice === 0 ? new Date().toLocaleString('en-NZ', { timeZone: 'Pacific/Auckland', month: 'long' }) : (() => {
                const date = new Date();
                date.setMonth(index);
                const longMonthName = date.toLocaleString('en-NZ', { timeZone: 'Pacific/Auckland', month: 'long' });
                return longMonthName;
            })(),
        };

        const coordinate = {};
        coordinate.x = params.event.event.offsetX;
        coordinate.y = params.event.event.offsetY;

        setCoordinates(coordinate);
        setIsOpen(true);
        setCardData(cardObject)
    };

    useEffect(() => {
        const graphElement = document.querySelector(".graphContainer");
        const rect = graphElement.getBoundingClientRect();
        // setGraphPosition({
        //     top: rect.top + window.scrollY,
        //     left: rect.left + window.scrollX,
        //     width: rect.width,
        //     height: rect.height,
        // });

        const handleClickOutside = (event) => {
            const chartHoverElement = document.getElementById("popup");
            if (
                chartHoverElement &&
                !chartHoverElement.contains(event.target) &&
                !document.getElementById("popup").contains(event.target)
            ) {
                setIsOpen(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);
    const onEvents = {
        click: handlePointClick,
    };
    const option = {
        // Configuration for the tooltip
        tooltip: {
            trigger: "axis",
            formatter: (params) => {
                return "";
            },
            // backgroundColor: 'white',
            // borderColor: '#E1E8F4',
            // padding: 0,
            // borderRadius: 8,
            //         formatter: (x) => `
            //   <div style="width: max-content; padding: 15px; display: flex; flex-direction: column;
            //     background: #FFFFFF 0% 0% no-repeat padding-box; border-radius: 8px;">
            //     <span style="font-family: 'Open Sans'; font-size: 12px; font-weight: 600;">
            //       ${x?.name}
            //     </span>
            //     <span style="margin-bottom: 4.5px; font-size: 12px; font-family: 'Open Sans'; font-style: italic;
            //       font-weight: 600; color: #5F6672;">
            //       Idle Time Delays (ITD)
            //     </span>
            //     <span style="font-family: 'Open Sans'; font-size: 12px; font-weight: 900; color: black;">
            //       ${getHourMinuteDifference(0, x.value * 1000)}
            //     </span>
            //   </div>
            // `
        },
        // Configuration for the grid
        grid: {
            left: "60px",
            right: "10%",
            // bottom: '250px',
            height: "200px",
            // width: '90%',
            // top: '-17px',
            paddinTop: 0,
            containLabel: true,
            backgroundColor: "#f5f5f5", // Background color for the grid
        },
        // Configuration for the X-axis
        xAxis: {
            name: "Date",
            nameLocation: "end",
            nameGap: 6,
            axisTick: {
                show: false,
            },
            // alignTicks: false,
            nameTextStyle: {
                fontFamily: "Open Sans",
                fontWeight: "bold",
                fontSize: "0.75rem",
                color: "#728BB5",
            },
            axisLabel: {
                color: "#576B8D",
                // lineHeight: 150
                margin: 20,
            },
            data: categories,
        },
        // Configuration for the Y-axis
        yAxis: {
            show: true,
            name: "Delay Hours",
            nameLocation: "middle",
            nameGap: 55,
            // max: Math.max(...data?.values) * 6 / 5,
            axisLabel: {
                show: true,
                formatter: (v) => getHourMinuteDifference(0, v * 1000),
                // Add the label for the Y-axis here
            },
            nameTextStyle: {
                fontFamily: "Open Sans",
                fontWeight: "bold",
                fontSize: "0.75rem",
                color: "#728BB5",
            },
        },
        // Configuration for the series (data)
        series: [
            {
                type: "line",
                data: data?.map(x => x?.delays?.[type]),
                showBackground: true,
                showSymbol: false,
                label: {
                    show: false,
                    fontSize: "0.625rem",
                    position: "outside",
                    fontWeight: "600",
                    color: "#576B8D",
                    formatter: (v) =>
                        getHourMinuteDifference(0, v.value * 1000),
                },
                itemStyle: {
                    color: "#F04AA4",
                },
            },
            {
                type: "line",
                data: data?.map(x => x?.delays?.[type2]),
                showBackground: true,
                showSymbol: false,
                label: {
                    show: false,
                    fontSize: "0.625rem",
                    position: "outside",
                    fontWeight: "600",
                    color: "#576B8D",
                    formatter: (v) =>
                        getHourMinuteDifference(0, v.value * 1000),
                },
                itemStyle: {
                    color: "#367ED8",
                },
            },
        ],
    };

    return (
        <div >
            <ReactEcharts
                // style={{ height: "100%", padding: "10px 0", }}
                option={option}
                onEvents={onEvents}
            />
            {isOpen && (
                <div id="popup">
                    <WDChart
                        setIsOpen={setIsOpen}
                        data={cardData}
                        type={type}
                        coordinate={coordinates}
                        setShowDetails={setShowDetails}
                    />
                </div>
            )}
            {showDetails && (
                <WDChartViewDetails
                    data={cardData}
                    type={type}
                    closeviewDetails={setShowDetails}
                    coordinate={coordinates}
                />
            )}
        </div>
    );
}
