import React, { useState } from 'react'
import "./index.css"

const Button = ({
    onClick,
    key,
    style,
    title,
    DataLength,
    active,
}) => {

    const handleClick = () => {
        onClick();
    };

    return (
        <div
            key={key}
            className={`align-items-center justify-content-center ${active ? 'live-active' : 'live-inactive'} assets-button `}
            onClick={handleClick}
            style={{
                marginTop: "1.25rem",
                width: "9.5rem",
                height: "3.8rem",
                font: "  normal normal 1.075rem Open Sans",
                padding: "0.1rem 0.7rem",
                boxShadow: "0px 0.25rem 0.33rem #00000029",
                borderRadius: "0.25rem",
                border: "0.1rem solid #A0B8E3",
                cursor: "pointer",
                overflow: "hidden",
                whiteSpace: "nowrap",
                ...style
            }}>
            <div style={{ fontWeight : active ? "bold" : "normal", marginTop: "0.375rem", display: "flex", alignItems: "center", justifyContent: "center" }}>{title}</div>
            <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>{DataLength}</div>
        </div >
    )
}
export default Button;