import React, { useEffect, useState } from "react";

import DeleteYear from "./DeleteYearSelection";
import DeleteConfirmation from "./DeleteConfirmation";
import ConfirmAlert from "pages/ShiftPlanning/components/ConfirmAlert/ConfirmAlert";

const DeleteYears = ({
    years,
    showDeleteYears,
    setShowDeleteYears,
    handleDeleteYears
}) => {
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const [selectedYears, setSelectedYears] = useState([]);

    if (!showDeleteYears) {
        return null;
    }

    return (
        <div
            style={{
                display: "flex",
                position: "absolute",
                zIndex: 1000,
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                backgroundColor: "rgb(7,22,41,.85)",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
                width: "100%",
                inset: 0,
            }}
        >
            {showDeleteConfirmation ? (
                <ConfirmAlert
                    title={"Delete " + selectedYears?.join(',')}
                    message={<div style={{ lineHeight: 1.5 }} className="px-5 mx-5">Are you sure you want to remove this year and all the dig rates assigned to it?</div>}
                    containerStyle={{ backgroundColor: 'transparent' }}
                    modalStyle={{ paddingBottom: 65 }}
                    onClose={() => setShowDeleteConfirmation(false)}
                    onConfirm={() => handleDeleteYears(selectedYears)}
                />
            ) : (
                <DeleteYear
                    years={years}
                    setShowDeleteConfirmation={setShowDeleteConfirmation}
                    setShowDeleteYears={setShowDeleteYears}
                    setSelectedYears={setSelectedYears}
                    selectedYears={selectedYears}
                />
            )}
        </div>
    );
};

export default DeleteYears;
