import CycleActivityDataPoint from "./CycleActivityDataPoint";
import { constData } from "../constData";

export default function CycleActiviyList({ selected, setSelected }) {
  // const { data } = props;
  // const {  data,setSelected } = data;
  // console.log(data);



  return (
    <div className="border py-2" style={{ borderRadius: '0.5rem', width: 'fit-content' }}>
      {constData.map((data, i) => <CycleActivityDataPoint color={data.color} index={i} selected={selected == i} onSelected={setSelected} label={data.label} />
      )}

    </div>
  );
}
