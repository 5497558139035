import React, { useState, useRef, useEffect, useContext, createContext } from 'react';
import useAuth from '../../../hooks/useAuth';
import { CallWithAuth, CallWithAuthFormData } from '../../../action/apiActions';
import { GET_KPI_SPEEDING_DATA } from '../../../action/apiPath';
import {
    GET_KPI_DELAYS_DATA,
    GET_KPI_MONTHLY_YEARLY_DELAYS_DATA,
    GET_KPI_DUMP_CYCLE_DATA

} from "../../../action/apiPath";
import moment from "moment";


const ManagementKPIsContext = createContext()

export function ManagementKPIsContextProvider({ children }) {
    const [loading, setLoading] = useState(true);
    const [assetAnalyticsDate, setAssetAnalyticsDate] = useState('')
    const [assetAnalyticsData1, setassetAnalyticsData1] = useState(undefined)
    const [delaysData1, setDelaysData1] = useState(undefined)
    const [delaysData2, setDelaysData2] = useState(undefined)
    const [delaysDate, setDelaysDate] = useState('');
    const [speedingData, setSpeedingData] = useState(undefined)
    let fetchDataTimeoutDelays1Ref = useRef(null);
    let fetchDataTimeoutDelays2Ref = useRef(null);
    let fetchDataTimeoutAssetAnanlytics2Ref = useRef(null);
    let fetchDataTimeoutSpeedingRef = useRef(null);

    const fetchSpeedingEvents = async () => {
        if (fetchDataTimeoutSpeedingRef.current) {
            clearTimeout(fetchDataTimeoutSpeedingRef.current);
        }
        // const date = new Date(delaysDate);
        const date = new Date();
        const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
        const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
        try {
            const response = await CallWithAuth("POST", GET_KPI_SPEEDING_DATA, {
                fromDate: moment(firstDay).format("YYYY-MM-DD"),
                tillDate: moment(lastDay).format("YYYY-MM-DD"),
            });
            if (response.status) {
                setSpeedingData(response?.res.data?.data);
            }
        } catch (error) {
            console.log(error);
        }
        fetchDataTimeoutSpeedingRef.current = setTimeout(fetchSpeedingEvents, 60000);
    };

    const fetchDataDelays1 = async () => {
        if (fetchDataTimeoutDelays1Ref.current) {
            clearTimeout(fetchDataTimeoutDelays1Ref.current);
        }
        try {
            const response = await CallWithAuth("POST", GET_KPI_DELAYS_DATA, {
                date: delaysDate,
            });
            if (response.status) {
                setDelaysData1(response?.res.data?.data);
            }
        } catch (error) {
            console.log(error);
        }
        fetchDataTimeoutDelays1Ref.current = setTimeout(fetchDataDelays1, 60000);
    };
    const fetchDataDelays2 = async () => {
        if (fetchDataTimeoutDelays2Ref.current) {
            clearTimeout(fetchDataTimeoutDelays2Ref.current);
        }
        try {
            const response = await CallWithAuth(
                "POST",
                GET_KPI_MONTHLY_YEARLY_DELAYS_DATA,
                { date: delaysDate }
            );
            if (response.status) {
                setDelaysData2(response?.res.data?.data);
            }
        } catch (error) {
            console.log(error);
        }
        fetchDataTimeoutDelays2Ref.current = setTimeout(fetchDataDelays2, 30000);
    };
    const fetchDataAssetAnalytics1 = async () => {
        try {
            if (fetchDataTimeoutAssetAnanlytics2Ref.current) {
                clearTimeout(fetchDataTimeoutAssetAnanlytics2Ref.current);
            }
            const response = await CallWithAuth("POST", GET_KPI_DUMP_CYCLE_DATA, {
                date: assetAnalyticsDate
            });
            if (response.status)
                setassetAnalyticsData1(response?.res.data?.data);
        } catch (error) {
            console.log(error)
        }
        fetchDataTimeoutAssetAnanlytics2Ref.current = setTimeout(fetchDataAssetAnalytics1, 60000);
    };
    useEffect(() => {
        setTimeout(() => {
            setLoading(false)
        }, 3000);
    }, [])

    useEffect(() => {
        fetchDataAssetAnalytics1()

    }, [assetAnalyticsDate]);
    useEffect(() => {
        fetchSpeedingEvents();
        fetchDataDelays1();
        fetchDataDelays2();
    }, [delaysDate])

    return (
        <ManagementKPIsContext.Provider value={
            {
                loading,
                setLoading,
                delaysData1,
                delaysData2,
                delaysDate,
                setDelaysDate,
                assetAnalyticsData1,
                assetAnalyticsDate,
                setAssetAnalyticsDate,
                speedingData
            }
        }>
            {children}
        </ManagementKPIsContext.Provider>
    )
}


export default ManagementKPIsContext;