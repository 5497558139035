import { LatLng, LatLngBounds } from 'leaflet';
import React, { useEffect, useState, useRef } from 'react';
import minusIcon from '../../../../assets/svgs/minus.svg';
import plusIcon from '../../../../assets/svgs/plus.svg';
import { TitlePopup } from './TitlePopup';
import ReactDOM from 'react-dom';
import speedIcon from '../assets/svgs/speed.svg'
import signalIcon from '../assets/svgs/signal.svg'
import locationIcon from '../assets/svgs/location.svg'
import { getSignalStatus } from '../utils/helper';
import TimelineGraphModal from "../Components/TimelineGraph/TimelineGraphModal"

const CAMERA_DISTANCE = 1800; // increasing will take view more farther from ground
const MAP_CENTER_LAT = -37.61193359511409;
const MAP_CENTER_LNG = 175.0833966543725;

let map;
let mapkit = undefined;

let markerAnnotations = [];
let currentassetsFlyout
let polylineStyle;
let polylineBorderStyle;
var polylineOverlays = [];
var initialMarkers = [];
let currentMarker = null
let currentDirectionFlyout = null
let currentDirectionMarker = null
let directionMarkerAnnotations = []
let selectedPolylineCoordinate = null;

export const Map = ({
    isTrip,
    loading,
    selectedMarker = {},
    mapRef,
    markers = [],
    onMarkerClick = () => { },
    showDefaultMarkers = true,
    endpoints = [],
    onSelectMarker = () => { },
    isFullScreenMode = false,
    allCoordinates = [],
    showTimelineGraphToggle,
    showTimelineGraph,
}) => {
    const [selectedSpeed, setSelectedSpeed] = useState(null);
    const [speedUnit, setSpeedUnit] = useState('km/h');
    const [selectedTime, setSelectedTime] = useState(null);
    const [setupCompleted, setSetupCompleted] = useState(false);
    const [markersRendered, setMarkersRendered] = useState(false);
    const isFullScreenModeRef = useRef(isFullScreenMode);
    const allCoordinateRef = useRef(allCoordinates);
    useEffect(() => {
        allCoordinateRef.current = allCoordinates;
    }, [allCoordinates])


    const handleAnnotation = (marker, coordinates, popup, type, selected, remove = false, showPopupInitially) => {
        const removeAnnotation = () => {
            if (currentassetsFlyout?.visible) {
                currentassetsFlyout.visible = false;
                map.removeAnnotation(currentassetsFlyout)
            }
        }

        const createAnnotation = (selected) => {
            removeAnnotation()
            const markerCoord = new mapkit.Coordinate(coordinates?.latitude, coordinates?.longitude);
            const options = {
                title: "title",
                data: marker,
                visible: false,
                anchorOffset: new DOMPoint(
                    !isFullScreenMode && type == 'end'
                        ? 82
                        : !isFullScreenMode && type == 'start'
                            ? 79
                            : !isFullScreenMode && (type == 'idle' || type == 'speeding')
                                ? -17
                                : showPopupInitially
                                    ? type == "start"
                                        ? (79)
                                        : (81)
                                    : selected
                                        ? -17
                                        : 0,
                    !isFullScreenMode && type == 'end'
                        ? 95
                        : !isFullScreenMode && type == 'start'
                            ? 81
                            : !isFullScreenMode && (type == 'idle' || type == 'speeding')
                                ? 30
                                : showPopupInitially
                                    ? type == 'end'
                                        ? 92
                                        : 81
                                    : selected
                                        ? 30
                                        : 50,
                ),
                clusteringIdentifier: null,
            };
            let annotation;
            switch (type) {
                case "start":
                case "park":
                case "end":
                case "speed":
                case "idle":
                case "checkpoint":
                    annotation = new mapkit.Annotation(markerCoord, popup, options);
                    break;
                default:
                    annotation = new mapkit.Annotation(markerCoord, TitlePopup, options);
            }
            if (coordinates?.latitude && coordinates?.longitude)

                map.setCenterAnimated(markerCoord);
            map.addAnnotation(annotation)
            if (showPopupInitially) {
                initialMarkers.push(annotation)
            } else {
                currentassetsFlyout = annotation
            }
            annotation.visible = true;
        }
        if (remove) {
            removeAnnotation();
        } else {
            createAnnotation(selected)
        }
    }

    const createMarker = ({ marker, onMarkerClick, isSelected }) => {


        const { coordinates, url, popup, type } = marker;
        const markerCoord = new mapkit.Coordinate(coordinates?.latitude, coordinates?.longitude);

        const markerAnnotation = new mapkit.ImageAnnotation(
            markerCoord,
            {
                url: {
                    1: url ?? `./assets/images/idle.svg`,
                },
                anchorOffset: new DOMPoint(0, 0),
            }
        );
        let urlForIconBeforeSelection = marker.url;
        markerAnnotation.addEventListener('select', () => {
            onMarkerClick({ marker, select: true });
            if (marker?.type === 'idle' || marker?.type === 'speed') {
                markerAnnotation.url = {
                    1:
                        marker?.type === 'idle'
                            ? `./assets/images/selectedIdleMarker.svg`
                            : marker?.type === 'speed'
                                ? `./assets/images/selectedSpeedingMarker.svg`
                                : marker.url,
                };
            }
            handleAnnotation(marker, coordinates, popup, type, true, true, true);
        });

        markerAnnotation.addEventListener('deselect', () => {
            // removeAnnotation();
            onMarkerClick({ marker, select: false });
            if (marker?.type === 'idle' || marker?.type === 'speed') {
                markerAnnotation.url = {
                    1: urlForIconBeforeSelection,
                };
            }
            handleAnnotation(marker, coordinates, popup, type, true, true, false);
        });
        if (marker?.showPopupInitially && isFullScreenMode) handleAnnotation(marker, coordinates, popup, type, false, false, true);
        if (isSelected && !(marker?.showPopupInitially && isFullScreenMode)) handleAnnotation(marker, coordinates, popup, type, true, false, false);
        if (isSelected) currentMarker = markerAnnotation

        markerAnnotations.push(markerAnnotation);
        map.addAnnotation(markerAnnotation);
    }

    const createDirectionMarker = ({ marker, isSelected }) => {
        const { latitude, longitude, head, title } = marker;

        const markerCoord = new mapkit.Coordinate(latitude, longitude);

        const svgContent1 = `<?xml version="1.0" encoding="utf-8"?>
        <svg xmlns="http://www.w3.org/2000/svg" width="20.155" style="transform:rotateZ(${head - 45}deg);" height="22.07" viewBox="0 0 20.155 22.07">
            <path id="Icon_ionic-md-arrow-dropright" data-name="Icon ionic-md-arrow-dropright" d="M13.5,9l10.43,9L13.5,27l3.572-9Z" transform="translate(-15.628 6.662) rotate(-34)" fill="#fff" stroke="#000" stroke-width="1"/>
        </svg>
    `;

        const svgContent2 = `<svg xmlns="http://www.w3.org/2000/svg" width="20.155" style="transform:rotateZ(${head - 90}deg);" height="22.07" viewBox="0 0 12.833 19.276">
        <g id="Icon_ionic-md-arrow-dropright" data-name="Icon ionic-md-arrow-dropright" transform="translate(-14.621 -5.582) rotate(-8)">
            <path d="M 15.20361137390137 24.60532760620117 L 17.7228832244873 18.25823974609375 L 17.82538414001465 18 L 17.7228832244873 17.74176025390625 L 15.20361137390137 11.39467239379883 L 22.85821723937988 18 L 15.20361137390137 24.60532760620117 Z" stroke="none"/>
            <path d="M 16.90724182128906 13.78936576843262 L 18.57851409912109 18 L 16.90724182128906 22.21063613891602 L 21.78675079345703 18 L 16.90724182128906 13.78936576843262 M 13.50000381469727 9 L 23.92968368530273 18 L 13.50000381469727 27 L 17.07226371765137 18 L 13.50000381469727 9 Z" stroke="none" fill="#fff"/>
        </g>
    </svg>
    `;

        const markerAnnotation = new mapkit.ImageAnnotation(markerCoord, {
            url: {
                1: `data:image/svg+xml;base64,${btoa(!isSelected ? svgContent1 : svgContent2)}`,
            },
            anchorOffset: new DOMPoint(0, 0),
        });

        const removeAnnotation = () => {
            if (currentDirectionFlyout?.visible) {
                currentDirectionFlyout.visible = false;
                map.removeAnnotation(currentDirectionFlyout);
            }
        };

        const popup = (coordinate, { data }) => {
            const div = document.createElement('div');
            const popupContent = (
                <>
                    <div
                        style={{
                            padding: '10px 12.5px',
                            width: '210px',
                            backgroundColor: 'white',
                            borderRadius: '.75rem',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'space-around',
                            font: ' 600 14px/17px Open Sans',
                            color: '#000'
                        }}
                    >
                        <div className="my-1" style={{ display: 'flex', alignItems: "center" }}>
                            <div style={{ width: 22, height: 20, display: 'flex', justifyContent: 'center', marginRight: '5px', alignItems: 'center' }}>
                                <img
                                    width={18}
                                    height={14}
                                    style={{ objectFit: 'contain' }}
                                    src={speedIcon}
                                    alt="speed"
                                /></div>
                            <div>{data?.spd} km/hr</div>
                        </div>
                        <div className="my-1" style={{ display: 'flex', alignItems: "center" }}>
                            <div style={{ width: 22, height: 20, display: 'flex', justifyContent: 'center', marginRight: '5px', alignItems: 'center' }}>
                                <img
                                    width={18}
                                    height={14}
                                    style={{ objectFit: 'contain' }}
                                    src={signalIcon}
                                    alt="signal"
                                /></div>
                            <div>{getSignalStatus(data.css)}</div>
                        </div>
                        <div className="my-1" style={{ display: 'flex', alignItems: "center" }}>
                            <div style={{ width: 22, height: 20, display: 'flex', justifyContent: 'center', marginRight: '5px', alignItems: 'center' }}>
                                <img
                                    width={14}
                                    height={18}
                                    style={{ objectFit: 'contain' }}
                                    src={locationIcon}
                                    alt="location"
                                /></div>
                            <div>{latitude}/{longitude}</div>
                        </div>
                    </div>

                    <div className="popup-tip-container"></div>
                </>
            );

            ReactDOM.render(popupContent, div);
            return div;
        };

        if (isSelected) currentDirectionMarker = markerAnnotation;

        const createAnnotation = (selected) => {
            removeAnnotation();

            const markerCoord = new mapkit.Coordinate(latitude, longitude);

            const options = {
                title: title ?? "title",
                data: marker,
                visible: false,
                anchorOffset: new DOMPoint(selected ? (-17) : (0), selected ? (70) : (85)),
                clusteringIdentifier: null,
            };

            let annotation = new mapkit.Annotation(markerCoord, popup, options);
            if (latitude && longitude)

                map.setCenterAnimated(markerCoord);
            map.addAnnotation(annotation);
            currentDirectionFlyout = annotation;
            annotation.visible = true;
        };

        markerAnnotation.addEventListener('select', () => {
            if (!isSelected) {
                map.removeAnnotation(markerAnnotation);
                createAnnotation(true);

                const mapCenter = new mapkit.Coordinate(marker?.coordinate?.latitude, marker?.coordinate?.longitude);
                if (marker?.coordinate?.latitude && marker?.coordinate?.longitude)

                    map.setCenterAnimated(mapCenter);
            }
        });

        markerAnnotation.addEventListener('deselect', () => {
            if (!isSelected) {
                map.removeAnnotation(currentDirectionMarker);
            }
        });

        directionMarkerAnnotations.push(markerAnnotation);
        map.addAnnotation(markerAnnotation);
    };

    const createDirectionMarkers = () => {
        if (!map) return;
        //=- Removing old markers and annotations 
        directionMarkerAnnotations.forEach((annotation) => {
            map.removeAnnotation(annotation)
        });
        directionMarkerAnnotations = []
    }

    const handleZoom = (delta) => {
        let counter = 0;
        const interval = setInterval(() => {
            mapRef.current._impl.zoomLevel += delta;
            counter += 0.05;
            if (counter >= 1) {
                clearInterval(interval);
                if (!isFullScreenModeRef.current || mapRef.current._impl.zoomLevel <= 15) {
                    directionMarkerAnnotations.forEach((annotation) => {
                        map.removeAnnotation(annotation)
                    });
                    if (currentDirectionFlyout) { map.removeAnnotation(currentDirectionFlyout); currentDirectionFlyout = null }
                    directionMarkerAnnotations = []
                }

            }
        }, 20);
    };

    const createMarkers = () => {
        if (!map) return;
        map.isZoomEnabled = true;
        map.isScrollEnabled = true;
        map.annotationForCluster = (clusterAnnotation) => {
            const svgContent = `<?xml version="1.0" encoding="utf-8"?>
    <svg viewBox="0 0 150 330" width="40" height="90" xmlns="http://www.w3.org/2000/svg">
      <ellipse style="fill: rgba(255, 255, 255, 1 ); stroke-width: 4; stroke: rgb(255, 255, 255 );" cx="76.913" cy="74.565" rx="68.056" ry="69.967" transform="matrix(0.9999999999999999, 0, 0, 0.9999999999999999, 0, -7.105427357601002e-15)"/>
      <ellipse style="fill: ${getClusteredImageColor(clusterAnnotation.clusteringIdentifier)}; " cx="78.256" cy="73.399" rx="45.282" ry="46.933" transform="matrix(0.9999999999999999, 0, 0, 0.9999999999999999, 0, -7.105427357601002e-15)"/>
      <ellipse style="fill: rgb(255, 255, 255); stroke-width: 4; stroke: rgb(255, 255, 255 );" cx="80.129" cy="235.92" rx="0.127" ry="80.63" transform="matrix(0.9999999999999999, 0, 0, 0.9999999999999999, 0, -7.105427357601002e-15)"/>
      <text x="78.256" y="85" text-anchor="middle" font-family="Arial" font-size="44" fill="black">(${clusterAnnotation?.memberAnnotations?.length})</text>
    </svg>`;
            var annotation = new mapkit.ImageAnnotation(clusterAnnotation.coordinate, {
                url: { 1: `data:image/svg+xml;base64,${btoa(svgContent)}` }
            });
            return annotation;
        };
    }

    const createGraphMarker = () => {
        const myCoord = allCoordinates.filter((a) => a.timestamp == selectedTime);
        const removeAnnotation = () => {
            if (selectedPolylineCoordinate) {
                selectedPolylineCoordinate.visible = false;
                map.removeAnnotation(selectedPolylineCoordinate);
            }
        };

        removeAnnotation();
        if (!myCoord?.length) {
            return;
        }
        setSelectedSpeed(myCoord[0].spd);
        const markerCoord = new mapkit.Coordinate(
            myCoord[0].latitude,
            myCoord[0].longitude,
        );

        const graphSelectionMarker = `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
        <defs>
          <linearGradient id="grad" x1="0%" y1="0%" x2="100%" y2="100%">
            <stop offset="0%" style="stop-color:#8EB9FF;" />
            <stop offset="100%" style="stop-color:#004AC1;" />
          </linearGradient>
        </defs>
        <circle cx="8" cy="8" r="8" style="fill:url(#grad); opacity:1;" />
      </svg>`;

        selectedPolylineCoordinate = new mapkit.ImageAnnotation(markerCoord, {
            url: {
                1: `data:image/svg+xml;base64,${btoa(graphSelectionMarker)}`,
            },
            anchorOffset: new DOMPoint(0, 0),
        });
        if (myCoord[0].latitude && myCoord[0].longitude)
            map.setCenterAnimated(markerCoord);
        map.addAnnotation(selectedPolylineCoordinate);
        selectedPolylineCoordinate.visible = true;
    };

    const setupMapKitJs = async () => {
        if (!window.mapkit || window.mapkit.loadedLibraries?.length === 0) {
            await new Promise((resolve) => {
                window.initMapKit = resolve;
            });
            delete window.initMapKit;
        }

        const jwt = process.env.REACT_APP_APPLE_MAP_TOKEN;
        window.mapkit.init({
            authorizationCallback: (done) => {
                done(jwt);
            },
            language: 'en',
        });
    };

    const getClusteredImageColor = (identifier) => {
        switch (identifier) {

            default:
                return "#ED5151";
        }
    };

    const setupMap = ({ mapRef }) => {
        const mapCenter = new mapkit.Coordinate(MAP_CENTER_LAT, MAP_CENTER_LNG);
        const mapRegion = new mapkit.CoordinateRegion(
            mapCenter,
            new mapkit.CoordinateSpan(0.06, 0.06)
        );
        map = new mapkit.Map('map-container', {
            center: mapCenter,
            mapType: mapkit.Map.MapTypes.Satellite,
            region: mapRegion,
            showsCompass: mapkit.FeatureVisibility.Hidden,
        });
        mapRef.current = map;
        map.cameraZoomRange = new mapkit.CameraZoomRange(100, 8000);
        map.cameraBoundary = mapRegion.toMapRect();
        map.cameraDistance = CAMERA_DISTANCE;
        map.addEventListener('single-tap', () => {
            if (currentassetsFlyout?.visible) {
                currentassetsFlyout.visible = false;
                map.removeAnnotation(currentassetsFlyout)
            }
            if (currentMarker != null) {
                map.removeAnnotation(currentMarker)
                currentMarker = null
                onSelectMarker(null);
            }
            onMarkerClick({ marker: {}, select: false });
        });
        map.addEventListener('zoom-end', () => {
            if (!isFullScreenModeRef.current || mapRef.current._impl.zoomLevel <= 15) {
                directionMarkerAnnotations.forEach((annotation) => {
                    map.removeAnnotation(annotation)
                });
                if (currentDirectionFlyout) { map.removeAnnotation(currentDirectionFlyout); currentDirectionFlyout = null }
                directionMarkerAnnotations = []
            }

        });

        map.addEventListener('single-tap', () => {
            if (currentDirectionFlyout?.visible) {
                currentDirectionFlyout.visible = false;
                map.removeAnnotation(currentDirectionFlyout)
            }
            if (currentDirectionMarker != null) {
                map.removeAnnotation(currentDirectionMarker)
                currentDirectionMarker = null
            }
        });
    };


    useEffect(() => {
        if (showDefaultMarkers && markersRendered && endpoints?.length) {
            for (let i = 0; i < endpoints.length; i++) {
                let marker = endpoints[i];
                handleAnnotation(marker, marker.coordinates, marker?.popup, marker?.type, true, false);
            }
        }
    }, [showDefaultMarkers, markersRendered, endpoints]);

    const setUpMap = async () => {
        await setupMapKitJs();
        mapkit = window.mapkit;
        setupMap({ mapRef });
        polylineBorderStyle = new mapkit.Style({
            lineWidth: 3,
            strokeColor: '#000',
        });
        polylineStyle = new mapkit.Style({
            lineWidth: 1,
            strokeColor: '#faeb4f',
        });
        setSetupCompleted(true);
        return () => {
            delete window.initMapKit;
        };
    }
    useEffect(() => {
        setUpMap();
    }, []);
    useEffect(() => {
        createGraphMarker();
    }, [selectedTime]);


    useEffect(() => {
        if (!map) return;
        if (!setupCompleted) return;
        map.isZoomEnabled = true;
        map.isScrollEnabled = true;

        markerAnnotations.forEach((annotation) => {
            map.removeAnnotation(annotation)
            markerAnnotations = []
        });

        polylineOverlays.forEach((overlay) => {
            map.removeOverlay(overlay);
        });

        initialMarkers.forEach((annotation) => {
            map.removeAnnotation(annotation);
            initialMarkers = []
        });

        markers.forEach((marker, index) => {
            const { coordinates } = marker;
            if (coordinates?.latitude && coordinates?.longitude) {
                createMarker({ marker: marker, onMarkerClick: onMarkerClick });
            }
        });

        const boundCoordinates = markers?.map(({ coordinates }) => {
            return new LatLng(coordinates?.latitude, coordinates?.longitude);
        });

        if (boundCoordinates && boundCoordinates?.length > 0) {
            const bounds = new LatLngBounds(boundCoordinates);
            if (bounds.getCenter()?.lat && bounds.getCenter()?.lng) {
                const center = new mapkit.Coordinate(
                    bounds.getCenter().lat,
                    bounds.getCenter().lng
                );
                map.setCenterAnimated(center);
            }
        }


        const polylineCoordinates = [];
        allCoordinates?.forEach((myCoordinate, index) => {
            if (myCoordinate?.latitude && myCoordinate?.longitude) {
                const markerCoord = new mapkit.Coordinate(
                    myCoordinate?.latitude,
                    myCoordinate?.longitude,
                );
                polylineCoordinates?.push(markerCoord);
            }
        });

        function isCoordinateInsidePolyline(coordinate, polylineCoordinates) {
            const tolerance = 10; // Adjust the tolerance value as needed
            for (let i = 0; i < polylineCoordinates.length - 1; i++) {
                const start = polylineCoordinates[i];
                const end = polylineCoordinates[i + 1];
                const distance = distanceToSegment(coordinate, start, end);
                if (distance < tolerance) {
                    return true;
                }
            }
            return false;
        }

        // Function to calculate the distance between a point and a line segment
        function distanceToSegment(point, start, end) {
            const dx = end.latitude - start.latitude;
            const dy = end.longitude - start.longitude;
            const lengthSquared = dx * dx + dy * dy;
            let t;
            if (lengthSquared === 0) {
                t = 0;
            } else {
                t =
                    ((point.latitude - start.latitude) * dx +
                        (point.longitude - start.longitude) * dy) /
                    lengthSquared;
                t = Math.max(0, Math.min(1, t));
            }
            const closestPoint = new mapkit.Coordinate(
                start.latitude + t * dx,
                start.longitude + t * dy,
            );
            return mapkit.Coordinate.fromMapPoint(point).distanceTo(closestPoint);
        }


        if (polylineCoordinates?.length >= 2) {
            const polylineBorder = new mapkit.PolylineOverlay(polylineCoordinates, { style: polylineBorderStyle });
            const overlayPolylineBorder = map.addOverlay(polylineBorder);
            polylineOverlays.push(overlayPolylineBorder);

            const polyline = new mapkit.PolylineOverlay(polylineCoordinates, { style: polylineStyle });
            const overlay = map.addOverlay(polyline);
            polylineOverlays.push(overlay);
        }

    }, [setupCompleted, markers, isFullScreenMode]);

    useEffect(() => {
        if (currentassetsFlyout?.visible) {
            currentassetsFlyout.visible = false;
            map.removeAnnotation(currentassetsFlyout)
        }
        if (currentMarker) {
            map.removeAnnotation(currentMarker)
            currentMarker = null
        }
        initialMarkers.forEach((annotation) => {
            map.removeAnnotation(annotation);
            initialMarkers = []
        });
        if (selectedMarker && Object.keys(selectedMarker).length > 0) {
            createMarker({
                marker: selectedMarker,
                isSelected: selectedMarker?.select == false ? false : true,
                onMarkerClick: onMarkerClick
            })

            if (
                selectedMarker?.coordinates?.latitude &&
                selectedMarker?.coordinates?.longitude
            ) {
                const mapCenter = new mapkit.Coordinate(
                    selectedMarker?.coordinates?.latitude,
                    selectedMarker?.coordinates?.longitude,
                );
                map.setCenterAnimated(mapCenter);
            }

        }
        setMarkersRendered(true)
    }, [selectedMarker])

    useEffect(() => {
        createMarkers();
    }, [setupCompleted, markers]);

    useEffect(() => {
        isFullScreenModeRef.current = isFullScreenMode;
        if (!isFullScreenMode) {
            directionMarkerAnnotations.forEach((annotation) => {
                map.removeAnnotation(annotation)
            });
            if (currentDirectionFlyout)
                map.removeAnnotation(currentDirectionFlyout)
            directionMarkerAnnotations = []
        }

    }, [isFullScreenMode]);

    return (
        <>
            <section
                id="map-container"
                style={{ height: '100%', width: '100%' }}
            />

            <div style={{ bottom: "-1rem", marginBottom: '2rem', position: 'absolute', zIndex: 1, right: "1rem", backgroundColor: 'white' }}>
                <div className="d-flex align-items-center justify-content-center cursor-pointer p-2" onClick={() => handleZoom(0.05)}>
                    <img src={plusIcon} alt="Zoom In" style={{ width: "2rem", height: "2rem" }} />
                </div>
                <div className="d-flex align-items-center justify-content-center cursor-pointer p-2" onClick={() => handleZoom(-0.05)}>
                    <img src={minusIcon} alt="Zoom Out" style={{ width: "1.4rem", height: "1.5rem" }} />
                </div>
            </div>

            <div
                style={{
                    bottom: "-1rem",
                    marginBottom: "2rem",
                    position: "absolute",
                    zIndex: 1,
                    right: "4.4rem",
                    backgroundColor: "white",
                    borderRadius: "4px ",
                }}
            >
                <div
                    style={{
                        cursor: "pointer",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        height: "3rem",
                    }}
                    onClick={(e) => {
                        e.preventDefault();
                        mapRef.current._impl.zoomLevel = 1;
                        directionMarkerAnnotations.forEach((annotation) => {
                            map.removeAnnotation(annotation)
                        });
                        if (currentDirectionFlyout) { map.removeAnnotation(currentDirectionFlyout); currentDirectionFlyout = null }
                        directionMarkerAnnotations = []
                    }}
                >
                    <div style={{ fontSize: "1rem" }} className="p-2 px-2">
                        Fit Screen
                    </div>
                    {/* Show Time Progression button */}
                    {isFullScreenMode && !showTimelineGraph && isTrip && (
                        <div
                            onClick={() => showTimelineGraphToggle(true)}
                            className="d-flex justify-content-center align-items-center p-2 px-2"
                            style={{
                                fontSize: '1rem',
                                borderRadius: '0.125rem',
                                position: 'absolute',
                                width: 'max-content',
                                right: 75,
                                height: '3rem',
                                backgroundColor: 'white',
                                borderRadius: '0.3rem ',
                            }}
                        >
                            Time Progression
                        </div>
                    )}
                </div>
                <div>
                    {isFullScreenMode && showTimelineGraph && (
                        <div>
                            <TimelineGraphModal
                                allCoordinates={allCoordinates}
                                title="TIME PROGRESSION"
                                modalOpen={showTimelineGraph}
                                modalToggle={(v) => showTimelineGraphToggle(v)}
                                selectedSpeed={selectedSpeed}
                                speedUnit={speedUnit}
                                selectedTime={selectedTime}
                                setSelectedTime={(time) => setSelectedTime(time)}
                            />
                        </div>
                    )}
                </div>
            </div>


        </>
    );
};
