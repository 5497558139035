import React, { useState } from "react";
import {
    wdViewDetailsTableData,
    wdViewDetailsTableHeader,
} from "../../constData";
import { getHourMinuteDifference } from "../../helper";

const WDChartViewDetails = ({ closeviewDetails, coordinate, data, type }) => {
    const [toggle, setToggle] = useState("dayData");
    // const toggleData = delaysViewDetailsdata[0][toggle];

    const handleCloseViewDetails = () => {
        closeviewDetails(false);
    };
    const handleToggle = (toggle) => {
        setToggle(toggle);
    };

    return (
        <div
            style={{
                minWidth: "450px",
                position: "absolute",
                top: `${coordinate.y}px`,
                left: `${coordinate.x + 40}px`,
                background: "#FFFFFF",
                border: "1px solid #E1E8F4",
                borderRadius: "8px",
                zIndex: 1000,
            }}
            className="p-4 pt-1"
        >
            <div className=" p-0 d-flex justify-content-between">
                <div>
                    {data.date} {data.month}
                    {/* {data.date} {months[data.date - 1]} */}
                </div>
                <div
                    style={{
                        color: "#092344",
                        fontSize: "24px",
                        cursor: "pointer",
                    }}
                    onClick={handleCloseViewDetails}
                >
                    ×
                </div>
            </div>
            <div className="d-flex align-items-center">
                <div
                    className="p-0 align-items-start font-weight-normal"
                    style={{ fontSize: "14px", color: "#1A1A1A" }}
                >
                    Weather Delays (A/P)* -{" "}
                    <span className="font-weight-bold">{getHourMinuteDifference(0, data?.delays?.[type] * 1000)}/00:00 (h)</span>
                </div>
                <div className="px-3 pt-2 justify-content-center align-items-center">
                    <div className="wdViewDetaislFR-box">
                        <div className="wdViewDetaislFR-text">+ 00:00</div>
                    </div>
                </div>
            </div>
            <div className="wdViewDetails-trucks">Trucks</div>

            <div
                className="d-flex p-0 delaysViewDetailstoogleButton jusitfy-content-start"
                style={{
                    fontSize: "12px",
                }}
            >
                <div>
                    <button
                        className={`delaysToggleButton ${toggle == "dayData" ? "active" : ""
                            } mr-2`}
                        style={{ minWidth: "186px" }}
                        onClick={() => handleToggle("dayData")}
                    >
                        Day (A/P)* - {getHourMinuteDifference(0, data?.dayData?.delays?.[type] * 1000)}/00:00 (h)
                    </button>
                </div>
                <div>
                    <div>
                        <button
                            className={`delaysToggleButton ${toggle == "nightData" ? "active" : ""
                                }`}
                            style={{ minWidth: "186px" }}
                            onClick={() => handleToggle("nightData")}
                        >
                            Night (A/P)* - {getHourMinuteDifference(0, data?.nightData?.delays?.[type] * 1000)}/00:00 (h)
                        </button>
                    </div>
                </div>
            </div>
            <div
                style={{
                    borderTop: "0.063rem solid #0000001A",
                }}
            >
                <div className="table pl-3  mt-3">
                    <div
                        className="table-header row"
                        style={{
                            fontSize: "0.625rem",
                        }}
                    >
                        {wdViewDetailsTableHeader.map((header, index) => (
                            <div
                                key={index}
                                style={{
                                    color: "#576B8D",
                                    width: "12.5%",
                                }}
                            >
                                {header}
                            </div>
                        ))}
                    </div>
                    <div className="table-body">
                        {data[toggle].equipmentData?.map((rowData, rowIndex) => (
                            <div className="table-row row" key={rowIndex}>
                                <div
                                    style={{
                                        width: "12.5%",
                                        fontSize: "0.75rem",
                                        color: "#1A1A1A",
                                    }}
                                >
                                    {rowData?.Unit}
                                </div>
                                <div

                                    style={{
                                        width: "12.5%",
                                        fontSize: "0.75rem",
                                        color: "#1A1A1A",
                                    }}
                                >
                                    {getHourMinuteDifference(0, rowData.delays.WD * 1000)}
                                </div>
                                <div

                                    style={{
                                        width: "12.5%",
                                        fontSize: "0.75rem",
                                        color: "#1A1A1A",
                                    }}
                                >
                                    {getHourMinuteDifference(0, 0)}
                                </div>
                                <div className="d-flex align-items-center" style={{ width: "12.5%" }}>
                                    <div className="wdViewDetaislFR-box">
                                        <div className="wdViewDetaislFR-text">+ 00:00</div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <div
                style={{
                    color: "#576B8D",
                    fontSize: '10px'
                }}
            >
                * (A/P) - Actual/Planned
            </div>
        </div>
    );
};

export default WDChartViewDetails;
