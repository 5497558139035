import React from 'react';
import moment from 'moment-timezone';
import SpecificDelayMontlyYearlyGraph from '../components/SpecificDelayMontlyYearlyGraph';
import PieChartSection from '../components/PieChartSection';
import { getHourMinuteDifference } from '../../helper';
import { constData, issueTypes } from '../../constData';
import WDBox from '../components/WDBox';

const MaintenanceDelaysMontlyYearlySection = ({ data }) => {
    const daysInMonth = moment().tz('Pacific/Auckland').daysInMonth();
    const categories = data?.data
        ? data?.data?.map((_, i) => (i > 8 ? (i + 1).toString() : `0${i + 1}`))
        : Array.from({ length: daysInMonth }, (_, i) => (i > 8 ? (i + 1).toString() : `0${i + 1}`));


    return (

        <div className='d-flex flex-column '>
            <WDBox data={data} type={"MD"} type2={"POD"} />
            <SpecificDelayMontlyYearlyGraph categories={categories} data={data?.data} type={"MD"} type2={"POD"} />
        </div>


    );
};

export default MaintenanceDelaysMontlyYearlySection;
