import React, { useEffect, useState } from "react";
import moment from "moment-timezone";
import PieChartComponent from "../../../Delays/components/components/PieChartComponent";
import { getHourMinuteDifference } from "../../helper";
import { constData, eventTypes } from "../../constData";
import { speedingEventsTypes, speedingEventsTypesCostData } from "../../constData";
import SpeedingEventSectionGraph from "../SpeedingEventSectionGraph";
// import SpeedingEvenetsPieCharts from "./SpeedingEvenetsPieCharts";
import SpeedingEventList from './SpeedingEventsList'
const SpeedingEvents = ({ data, choice, date, fromDetail }) => {

  const [selectedValues, setSelectedValues] = useState([]);
  const [graphData, setGraphData] = useState([]);
  const [pieChartData, setPieChartData] = useState([]);

  useEffect(()=> {
    console.log("selectedValues", selectedValues)
  }, [selectedValues])

  const daysInMonth = date ? moment(date).tz("Pacific/Auckland").daysInMonth() : moment().tz("Pacific/Auckland").daysInMonth();
  // const categories = data
  //   ? data?.map((_, i) =>
  //     i > 8 ? (i + 1).toString() : `0${i + 1}`
  //   )
  //   : Array.from({ length: daysInMonth }, (_, i) =>
  //     i > 8 ? (i + 1).toString() : `0${i + 1}`
  //   );

  const categories = Array.from({ length: daysInMonth }, (_, i) =>
    i > 8 ? (i + 1).toString() : `0${i + 1}`
  );

  // const pieChartData = speedingEventsTypesCostData.map((row, j) => ({
  //   ...row,
  //   value: 9
  //     ? 9
  //     : 0,
  // }));

  const makeAndSetPieChartData = (array) => {
    const newArray = speedingEventsTypesCostData.map((row, j) => ({
      ...row,
      value: 0
    }));
    let total_20 = 0;
    let total_30 = 0;
    let total_50 = 0;
    array.forEach((item) => {
      if (item.total_20) {
        total_20 = total_20 + item.total_20;
      }
      if (item.total_30) {
        total_30 = total_30 + item.total_30;
      }
      if (item.total_50) {
        total_50 = total_50 + item.total_50;
      }
    });
    newArray[0].value = total_50;
    newArray[1].value = total_30;
    newArray[2].value = total_20;

    setPieChartData(newArray);
  }

  const findMyDateEvent = (events, date) => {
    if (events?.length) {
      for (let i = 0; i < events.length; i++) {
        const event = events[i];
        if (event.date == date) {
          return event;
        }
      }
    }
  }

  const formatData = (event) => {
    if (event?.events) {
      for (let j = 0; j < event.events.length; j++) {
        event.events[j]['total'] = event.events[j].speedings.reduce((accumulator, value) => {
          return accumulator + value.count;
        }, 0);
        event.events[j]['total_20'] = event.events[j].speedings.reduce((accumulator, value) => {
          if (value.speedHigh <= 30)
            return accumulator + value.count;
          else
            return accumulator;
        }, 0);
        event.events[j]['total_30'] = event.events[j].speedings.reduce((accumulator, value) => {
          if (value.speedHigh <= 50)
            return accumulator + value.count;
          else
            return accumulator;
        }, 0);
        event.events[j]['total_50'] = event.events[j].speedings.reduce((accumulator, value) => {
          if (value.speedHigh > 50)
            return accumulator + value.count;
          else
            return accumulator;
        }, 0);
      }

      event.total = event.events.reduce((accumulator, value) => {
        return accumulator + value.total;
      }, 0);
      event.total_20 = event.events.reduce((accumulator, value) => {
        return accumulator + value.total_20;
      }, 0);
      event.total_30 = event.events.reduce((accumulator, value) => {
        return accumulator + value.total_30;
      }, 0);
      event.total_50 = event.events.reduce((accumulator, value) => {
        return accumulator + value.total_50;
      }, 0)
    }
    return event;
  }

  const setChartData = () => {
    if (data) {
      data.sort((a, b) => a.date - b.date);
      let tempArray = [];
      const ym = moment().tz("Pacific/Auckland").format("YYYY-MM");
      for (let k = 1; k <= daysInMonth; k++) {
        const singleDay = ym + "-" + (k < 10 ? (0 + "" + k) : k);
        const myEvent = findMyDateEvent(data, (singleDay.replace("-", "").replace("-", "")));
        if (!myEvent) {
          tempArray.push({
            date: (singleDay.replace("-", "").replace("-", "")),
            total: 0,
            total_20: 0,
            total_30: 0,
            total_50: 0
          })
        } else {
          tempArray.push(formatData(myEvent));
        }
      }
      setGraphData(tempArray);
      makeAndSetPieChartData(tempArray);
    } else {
      setGraphData([]);
      makeAndSetPieChartData([]);
    }
  }

  useEffect(() => {
    setChartData();
  }, [])

  return (
    <div className="row d-flex">
      <div
        style={{
          // borderRight: "1px solid #E1E8F4",
          // minWidth: "215px",
          display: "flex",
          justifyContent: "flex-end",
          width: "calc(100% - 300px)",
          height: "300px",
        }}
      >
        <SpeedingEventSectionGraph
          data={graphData}
          choice={choice}
          categories={categories}
          linesSelected={selectedValues}
          fromDetail={fromDetail}
        />
      </div>
      <div className="pt-5" style={{ width: "300px", display: 'flex', alignItems: 'center', justifyContent: 'space-between', flexDirection: 'column' }}>
        <PieChartComponent
          data={pieChartData}
        />
        <div className='border mt-2' style={{
          borderRadius: "8px",
          width: '200px',
          fontSize: '1rem'
        }}>
          <div className='px-3'>Speed Categories</div>
          <div>{pieChartData.map((event, i) => <SpeedingEventList key={i} color={event.color} selected={selectedValues} onSelected={(val) => {
            setSelectedValues(prev => {
              const mySelectedValuesIndex = prev.findIndex((v) => v == val);
              if (mySelectedValuesIndex >= 0) {
                prev?.splice(mySelectedValuesIndex, 1)
                return prev;
                // return [...prev?.splice(mySelectedValuesIndex, 1)]
              } else {
                return [...prev, val];
              }
            })
          }} label={event.label} rangeCode={i} data={event.value} />)}</div>

        </div>
      </div>
    </div>
  );
};

export default SpeedingEvents;
