import React from "react";
import "../../style.css";
import { getHourMinuteDifference } from "../../helper";
const WDChart = ({
    data,
    type,
    setIsOpen,
    coordinate,
    setShowDetails,
}) => {
    console.log('====================================');
    console.log(data, "hhh");
    console.log('====================================');
    const handleViewDetailsClick = () => {
        setIsOpen(false);
        setShowDetails(true);
    };
    return (
        <div
            style={{
                position: "absolute",
                top: `${coordinate.y / 16}rem`,
                left: `${(coordinate.x + 40) / 16}rem`,
                background: "#FFFFFF",
                border: "1px solid #E1E8F4",
                borderRadius: "0.5rem",
                zIndex: 1000,
            }}
            className="px-4 py-1"
        >
            <div>
                <div style={{ fontSize: "0.75rem", color: "#1A1A1A" }}>
                    <span
                        style={{
                            fontWeight: 600,
                        }}
                    >
                        {data?.date} {data?.month}
                    </span>{" "}
                    <span style={{ fontWeight: "bold" }}> - {getHourMinuteDifference(0, data?.delays?.[type] * 1000)} h</span>
                </div>
                <div
                    className="wdChart"
                    style={{
                        fontStyle: "italic",
                    }}
                >
                    Trucks
                </div>
            </div>
            <div className="mt-3">
                <div className="wdChart d-flex align-items-center justify-content-between">
                    <div className="d-flex align-items-center">
                        <div
                            className="wdChartColor"
                            style={{
                                backgroundColor: "#367ED8",
                            }}
                        ></div>

                        <div>Planned</div>
                    </div>
                    <div style={{ color: "#1A1A1A", fontWeight: "bold" }}>
                        00:00 h
                    </div>
                </div>
                <div className="wdChart d-flex align-items-center justify-content-between">
                    <div className="d-flex align-items-center">
                        <div
                            className="wdChartColor"
                            style={{
                                backgroundColor: "#F04AA4",
                            }}
                        ></div>

                        <div>Actual</div>
                    </div>
                    <div style={{ color: "#1A1A1A", fontWeight: "bold" }}>
                        {getHourMinuteDifference(0, data?.delays[type] * 1000)} h
                    </div>
                </div>
            </div>
            <div>
                <a
                    style={{
                        borderBottom: "0.125rem solid #006BED",
                        color: "#006BED",
                        cursor: "pointer",
                        fontSize: "0.625rem",
                    }}
                    onClick={handleViewDetailsClick}
                >
                    view details
                </a>
            </div>
        </div>
    );
};

export default WDChart;
