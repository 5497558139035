import React, { useState } from "react";
import { Form } from "react-bootstrap";
import '../style.css'
import DayNightToggleButton from "../../components/DayNightToggleButton"

export default function ExcavatorSelectionHeader({
  choice,
  onChange,
  excavatorList,
  onExcavatorChange,
  title,
  selectedExcavator,
  onMenuSelect,
  menuOptions
}) {
  const commonStyles = {
    padding: "0 1.6rem",
    borderRadius: "4px",
    textAlign: "center",
    font: "1rem",
    letterSpacing: "0px",
    border: "1px solid #E1E8F4",
  };
  const [menuVisible, setMenuVisible] = useState(false)
  const getSelectedStyles = (i) => {
    return {
      backgroundColor: selectedExcavator === i ? "#E6F0FF" : "#F8FAFD",
      color: selectedExcavator === i ? "black" : "#6A7C9A",
      fontWeight: selectedExcavator === i ? "600" : "500",
    };
  }

  return (
    <div className="pb-2 mb-4"
      style={{
        borderBottom: "1px solid #E1E8F4",
        fontSize: '0.9rem'
      }}
    >
      <div className="d-flex justify-content-between">
        <div style={{ fontWeight: '600' }}>{title}</div>
        <div className="d-flex align-items-center">
          <DayNightToggleButton
            choice={!choice ? "Day" : "Night"}
            onToggle={(v) => {
              onChange(v == "Day" ? 0 : 1);
              // setActiveHeader(0)
            }}
          />
          <div style={{ position: 'relative' }}>
            <div
              className={`px-3 py-1 d-flex align-items-center justify-content-center ${menuVisible ? 'rounded' : ''
                }`}
              onClick={() => setMenuVisible(!menuVisible)}
              style={{ cursor: 'pointer', backgroundColor: menuVisible ? "#E2ECFC" : "transparent" }}
            >
              <ul className="icons">
                <li></li>
                <li></li>
                <li></li>
              </ul>
            </div>
            {menuVisible && (
              <div
                className="py-2 d-flex flex-column mt-1"
                style={{
                  right: '0',
                  backgroundColor: 'white',
                  position: 'absolute',
                  minWidth: 'max-content',
                  boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.1)',
                  border: '1px solid #E1E8F4',
                  borderRadius: '0.5rem',
                  cursor: 'pointer',
                  zIndex: "2000"
                }}
              >
                {menuOptions?.map((option, idx) => <div
                  className="hover-blue pl-3 pr-5"
                  style={{
                    textAlign: 'left',
                    fontWeight: '600',
                    fontSize: '1rem',
                  }}
                  onClick={() => {
                    setMenuVisible(false);
                    onMenuSelect(idx);
                  }}
                >
                  {option}
                </div>)}

              </div>
            )}
          </div>
        </div>
      </div>
      <div>
        <div className="d-flex">
          {excavatorList?.map((header, index) => (
            <div
              // key={index}
              className={`headerBox border mr-2 d-flex align-items-center justify-content-center`}
              style={{
                ...commonStyles,
                ...getSelectedStyles(index)
              }}
              onClick={() => onExcavatorChange(index)}
            >
              {header}
            </div>
          ))}
        </div>
      </div>

    </div >
  );
}
