import React, { useEffect, useState } from 'react';
import { Form, Image } from 'react-bootstrap';
import { CgClose } from 'react-icons/cg';
import assests from "../../AssetTracking/assets/icons/assests.svg";
import SignalBars from '../Signal/Signal';
import './index.css';
import DropdownField from '../../AssetTracking/TripsHistory/components/FormDropdown'
import { DropdownData } from '../../AssetTracking/TripsHistory/DropdownData'
import { ALL_EQUIPMENTS } from '../../../../action/apiPath';
import { CallWithAuth } from '../../../../action/apiActions';
import { toast } from 'react-toastify';

const AssetsModal = ({
    selectedRow,
    onClose,
    data,
    onSelectRow
}) => {
    const [dropdownData, setDropDownData] = useState(DropdownData)
    const [filteredData, setFilteredData] = useState([...data]);
    const [categoryType, setCategoryType] = useState(DropdownData.categoryType.defaultValues);
    const [searchQuery, setSearchQuery] = useState("");
    const [allEquipments, setAllEquipments] = useState([])



    const fetchAllEquipments = async () => {
        try {
            const response = await CallWithAuth("POST", ALL_EQUIPMENTS);
            const equipments = response?.res?.data?.data;

            const allEquipmentTypes = []
            const allEquipmentOptions = []
            equipments?.map((equipment, index) => {
                if (!allEquipmentTypes?.includes(equipment?.EquipmentType)) {
                    allEquipmentTypes.push(equipment?.EquipmentType)
                    allEquipmentOptions.push({ name: equipment?.EquipmentType, value: equipment?.EquipmentType })
                }
            })

            setDropDownData({ ...DropdownData, "categoryType": { options: allEquipmentOptions, defaultValues: allEquipmentTypes } })
            setCategoryType(allEquipmentTypes)
            setAllEquipments(equipments)
        } catch (error) {
            console.log("error", error)
            toast.error(error)
        }
    }

    useEffect(() => {
        fetchAllEquipments()
    }, [])


    useEffect(() => {
        setFilteredData(data)
    }, [data, allEquipments])

    const filterData = (unFilteredData) => {
        if (searchQuery === "") {
            return unFilteredData;
        }
        return unFilteredData.filter((item) => {
            return item['Asset ID']?.toLowerCase()?.includes(searchQuery?.toLowerCase())
        })
    }



    const filterByCategory = () => {
        if (categoryType.includes("Truck") && categoryType.includes("Excavator")) {
            return data;
        }
        return data?.filter((item) => categoryType.includes(item?.Category));

    };

    useEffect(() => {
        let filteredData = data;
        filteredData = filterByCategory(filteredData);
        filteredData = filterData(filteredData);
        setFilteredData(filteredData);
    }, [searchQuery, categoryType])

    return (
        <div className="modal-container">
            <div style={{ borderRadius: "0.8rem 0.8rem 0 0", background: "#FAFAFA", }}>
                <div style={{ position: "absolute", right: 0 }}>
                    <CgClose
                        onClick={() => onClose()}
                        style={{
                            cursor: 'pointer',
                            borderRight: "0px",
                        }}
                        size={"2.3rem"}
                        className="px-2"
                    />
                </div>
                <div className="d-flex pt-4 pb-2" style={{ font: "normal normal 600 1rem/1.375rem Open Sans", marginLeft: "1.5rem", }}>
                    <Image src={assests} style={{ marginBottom: "0.625rem", }} />
                    <div style={{ marginLeft: '0.879rem', marginBottom: "0.625rem", }}>ASSETS - <span style={{ color: "#6B6B6B" }}>LIVE</span></div>
                </div>
            </div>

            <div style={{ marginLeft: "1.5rem", marginRight: "1.5rem" }}>
                <div style={{ width: '100%', marginTop: '1.25rem', paddingBottom: "1.625rem", borderBottom: "0.1rem solid #707070" }}>
                    <div
                        className="d-flex align-items-center"
                    >
                        {/* <div
                                className=''
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    width: "12rem",
                                    height: "2.2rem",
                                    border: "0.1rem solid #EBEBEB",
                                    padding: "0.14rem",
                                    borderRadius: "0.188rem",
                                    marginTop: "0.02rem",
                                }}>
                                <div className="d-flex" style={{ font: "normal normal normal 0.9rem / 1rem Open Sans", }}>
                                    <div style={{ marginTop: "0.2rem", marginLeft: "0.3rem", }}>Category :</div>
                                    <Form.Group controlId="formBasicSelect" >
                                        <Form.Control
                                            as="select"
                                            value={categoryType}
                                            onChange={(e) => {
                                                setCategoryType(e.target.value);
                                            }}
                                            style={{
                                                fontSize: "0.8rem",
                                                height: "1.438rem",
                                                borderRadius: "1.125rem",
                                                color: "black",
                                                padding: "0rem 0.438rem",
                                                width: "6.4rem",
                                                marginLeft: "0.5rem",
                                            }}
                                        >
                                            <option value="All">All</option>
                                            <option value="Truck">Truck</option>
                                            <option value="Excavator">Excavator</option>
                                        </Form.Control>
                                    </Form.Group>
                                </div>
                            </div> */}
                        <div className="">
                            {/* <div style={{
                                display: "flex",
                                alignItems: "center",
                                width: "13.5rem",
                                height: "2.3rem",
                                border: "0.1rem solid #EBEBEB",
                                padding: "0.14rem",
                                borderRadius: "0.188rem",
                                background: "#FFFFFF 0% 0 % no - repeat padding- box",
                            }}> */}
                            <div className="d-flex " style={{ width: "13.938rem", }}>
                                {/* <div className='category-filter-dropdown'>Category :</div> */}
                                <DropdownField
                                    title={'Categories'}
                                    selectedOptions={categoryType}
                                    allOptions={dropdownData.categoryType.options}
                                    lowerLevelClassName="p-0"
                                    multiselect
                                    handleChange={(value) => {
                                        setCategoryType(value)
                                    }}
                                    name={'Category'}
                                />

                                {/* </div> */}
                            </div>
                        </div>
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                border: '1px solid #dde5f3',
                                padding: '0.25rem 0.8rem',
                                borderRadius: '15px',
                                width: '15rem',
                                backgroundColor: 'white',
                                marginLeft: "1.25rem",
                            }}
                        >
                            <i
                                style={{ fontSize: '1rem', color: "#707070" }}
                                className="mr-2 fa-regular fa-magnifying-glass"
                            ></i>
                            <input
                                type="search"
                                className="shadow-none"
                                value={searchQuery}
                                placeholder="Search for an asset"
                                style={{
                                    border: 'none',
                                    font: 'normal normal normal 1rem Open Sans',
                                    // width: '10rem',
                                }}
                                onChange={(e) => setSearchQuery(e?.target?.value)}
                            />

                        </div>


                    </div>
                </div>
                <table style={{ borderBottom: "0.1rem solid #707070" }} className="custom-table">
                    <thead>
                        <tr>
                            <td style={{ width: "0.625rem" }}></td>
                            <td style={{ width: "25%" }}>Asset ID</td>
                            <td style={{ width: "25%" }}>Category</td>
                            <td style={{ width: "20%" }}>Trips Today</td>
                            <td style={{ width: "20%" }}>KM Today</td>
                            <td style={{ width: "10%" }}>Signal</td>
                        </tr>
                    </thead>
                </table>
                <div
                    className='scroll-div'
                    style={{
                        overflow: "auto",
                        position: "relative",
                        maxHeight: "calc(100vh - 33rem)",
                    }}>
                    {filteredData?.length == 0 && <div className='d-flex text-muted mt-2 justify-content-center align-items-center'>No Record Found</div>}
                    <table style={{ width: "100%", }} className="custom-table">
                        <tbody className=''
                            style={{ overflow: "auto" }}>
                            {filteredData?.map((asset, index) => (
                                <tr
                                    style={{
                                        backgroundColor: JSON.stringify(selectedRow) == JSON.stringify(asset) ? "#3880F51A" : "#FFF",
                                        borderBottom: JSON.stringify(selectedRow) == JSON.stringify(asset) ? "0.1rem solid #377FF2" : " 0px solid #EBEBEB ",
                                        borderTop: JSON.stringify(selectedRow) == JSON.stringify(asset) ? "0.13rem solid #377FF2" : " 1px solid #EBEBEB ",
                                    }}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        onSelectRow(asset)
                                    }}
                                    key={index}
                                >
                                    <td style={{
                                        backgroundColor:
                                            asset["Status"] === 'Active' || asset["Status"] === 'Idling'
                                                ? '#CFFD69'
                                                : asset["Status"] === 'Disconnected'
                                                    ? '#ED5151'
                                                    : asset["Status"] === 'Parked'
                                                        ? '#BEB6B6'
                                                        : 'white',
                                    }}></td>
                                    <td>{asset['Asset ID'] ?? "-"}</td>
                                    <td>{asset["Category"] ?? "-"}</td>
                                    <td>{asset['Trips Today'] ?? "-"}</td>
                                    <td>{asset['KM Today'] ?? "-"}</td>


                                    <td><SignalBars Signal={asset?.notFound ? 0 : asset?.Signal} /></td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>



            </div>
            <div className='live-trip-table-footer d-flex align-items-center' >
                <div style={{ marginLeft: "auto", font: "normal normal normal 0.75rem/1.125rem Open Sans", color: " #2B8CFF" }} onClick={(e) => {
                    e.preventDefault();
                    onSelectRow(null)
                }}>
                    Clear Selection
                </div>
            </div>
        </div>
    );
};

export default AssetsModal;
