export const DropdownData = {
    "categoryType": {
        options: [
            { name: 'Excavator', value: 'Excavator' },
            { name: 'Truck', value: 'Truck' },
        ],
        defaultValues: ["Excavator", "Truck"]
    },
    "activityType": {
        options: [
            { name: "Trip", value: 'Trip' },
            { name: 'Park', value: 'Park' },
        ],
        defaultValues: ["Trip", "Park"]
    },
    "eventType": {
        options: [
            { name: 'Speeding', value: 'Speeding' },
            { name: 'Idle Time', value: 'Idle Time' },
        ],
        defaultValues: ["Speeding", "Idle Time"]
    },
    "statusType": {
        options: [
            { name: 'Active', value: 'Active' },
            { name: 'Disconnected', value: 'Disconnected' },
            { name: 'Parked', value: 'Parked' },
        ],
        defaultValues: ["Active", "Disconnected", "Parked"]
    },
}


export const TripHistoryDropdowns = [
    {
        title: 'Asset Categories',
        type: 'categoryType'
    },
    {
        title: 'Activities',
        type: 'activityType'
    },
    {
        title: 'Alerts',
        type: 'eventType'
    },
    {
        title: 'Status',
        type: 'statusType'
    },
]