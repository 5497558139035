import React, { useEffect, useState } from "react";
import Details from "./Details";
import EventList from "./Events";
import Timeline from "./Timeline";
import "./drawer.css";


const DropdownItem = ({ selectedAsset, selectedIndex, setSelectedIndex, index, title, content, events, assetInfo, showDropdown, setShowDropdown, onSelect = () => { }, selectedEvents, setSelectedEvents }) => (

  <div style={{ borderRadius: "0.64rem", background: "#FAFAFC", }}>
    <a
      className="nav-link"
      data-toggle="collapse"
      data-target={content ? `#${content}` : ""}
      aria-expanded="false"
      aria-controls={content}
      style={{ borderRadius: "0.64rem", padding: "0rem ", }}
      onClick={() => {
        setSelectedIndex(showDropdown ? -1 : index)
        setShowDropdown(!showDropdown)

      }}
    >
      <div className="d-flex justify-content-between align-items-center"
        style={{
          borderRadius: "0.25rem ",
          background: selectedIndex === index ? "#FFFFFF" : "#FAFAFA",
          padding: "10px 0px 10px 0px"
        }}
      >
        <strong style={{ font: "normal normal 600 1.075rem/1.063rem Open Sans", color: "#6F7070", marginLeft: "1.5rem", }}>
          <span style={{ color : selectedIndex === index ? "#000" : "" }}>{title} {title === "Alerts" ? <span> ({events?.speeding?.length + events?.idle?.length})</span> : ""}</span>
        </strong>
        <span className={`arrow${content}`} style={{ marginRight: "1.5rem", }}>
          {!(showDropdown && selectedIndex == index) ? <img src={"./assets/images/down-arrow.svg"} className="up-down-arrow-button" /> : <img src={"./assets/images/up-arrow.png"} className="up-down-arrow-button" />}
        </span>
      </div>
    </a>
    {
      content && (
        <div
          id={content}
          className={`collapse px-4  overflow-auto`}
          aria-labelledby={`heading${content}`}
          style={{background : "#FFFFFF"}}
        >
          {content === "collapseOne" ? (
            <Details data={assetInfo}/>
          )
            : content === "collapseTwo" ? (
              <Timeline data={assetInfo?.timeline} onSelectCheckPoint={onSelect} />
            )
              : content === "collapseThree" ? (
                <EventList selectedAsset={selectedAsset} data={events} onSelectEvent={onSelect} selectedEvents={selectedEvents} setSelectedEvents={setSelectedEvents} />
              ) : null}
        </div>
      )
    }
  </div>
);


const AssestInfoDrawer = ({ selectedAsset, assets, onSelect = () => { }, events, onToggle = () => { }, selectedEvents, setSelectedEvents }) => {


  const [assetsEvents, setAssetsEvents] = useState({ speeding: [], idle: [] })
  const [assetInfo, setAssetInfo] = useState({});
  const [showDropdown, setShowDropdown] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(-1);

  const [totalTripsAndParking, setTotalTripsAndParking] = useState({ trips: 0, parking: 0, activitylength: 0 });
  const calculateTotalTripsAndParking = () => {
    const totalTripsAndParkingData = assets?.activity?.reduce(
      (result, assetActivity, index) => {
        assetActivity?.activity_type === "Trip"
          ? result.trips++
          : result.parking++;

        result.activitylength = result.trips + result.parking;
        return result;
      },
      { trips: 0, parking: 0, activitylength: 0 }
    );
    console.log(totalTripsAndParkingData)
    setTotalTripsAndParking(totalTripsAndParkingData);
  };


  const handleLeft = () => {
    setAssetsEvents({ speeding: [], idle: [] })
    onToggle(assets?.activeIndex + 1 < totalTripsAndParking?.activitylength ? assets?.activeIndex + 1 : assets?.activeIndex)
  };

  const handleRight = () => {

    setAssetsEvents({ speeding: [], idle: [] })
    onToggle(assets?.activeIndex + 1 > 1 ? assets?.activeIndex - 1 : assets?.activeIndex)

  };

  useEffect(() => {
    setAssetInfo(assets?.activity[assets?.activeIndex]);
    calculateTotalTripsAndParking()
  }, [assets]);

  useEffect(() => {
    setAssetsEvents(events)
  }, [events]);


  return (
    <nav className="navbar navbar-expand-lg navbar-light custom-scroller">
      <div className="map-drawer-container">
        <ul>
          <li style={{ borderBottomRadius: "0.64rem" }}>
            <div
            >
              <div className="w-100 d-flex justify-content-between map-drawer-heading" >
                <img src={"./assets/images/left-arrow-circle.svg"} className="arrow-button" style={{ marginLeft: "1rem", }} onClick={handleLeft} />
                <strong style={{ font: "normal normal 600 1.075rem/1.188rem Open Sans" }}>
                  {assets?.activity[assets?.activeIndex]?.activity_type === "Trip" ? (
                    <>
                      {assets?.activity[assets?.activeIndex]?.activity_id}   {" "}
                      <span style={{ color: '#6F7070' }}>/{totalTripsAndParking?.trips}</span>
                    </>
                  ) : <div> PARKED</div>}
                </strong>
                <img src={"./assets/images/right-arrow-circle.svg"} className="arrow-button" style={{ marginRight: "0.5rem", }} onClick={handleRight} />
              </div>

            </div>
            <div>
              <form className="accordion map-dropdowns" id="accordionExample" style={{ background: "#FFFFFF 0% 0 % no - repeat padding- box", }}>
                {[
                  { title: `Details`, content: "collapseOne" },
                  (assetsEvents?.speeding?.length || assetsEvents?.idle?.length) && { title: "Alerts", content: "collapseThree" },
                ].map((item, index) => (
                  <>
                    {item?.content && <DropdownItem
                      index={index}
                      events={assetsEvents}
                      key={item.content}
                      title={item.title}
                      content={item.content}
                      assetInfo={assetInfo}
                      onSelect={onSelect}
                      selectedIndex={selectedIndex}
                      setSelectedIndex={setSelectedIndex}
                      showDropdown={showDropdown}
                      setShowDropdown={setShowDropdown}
                      selectedEvents={selectedEvents}
                      setSelectedEvents={setSelectedEvents}
                      selectedAsset={selectedAsset}

                    />}
                  </>

                ))}
              </form>
            </div>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default AssestInfoDrawer;
