import React from 'react'
import { PieChart } from 'react-minimal-pie-chart';

export default function PieChartComponent({ data, time, selectedIndex }) {
    return (
        <div style={{ width: "150px", position: "relative", zIndex: 1 }}>
            <PieChart
                center={[35, 35]}
                data={data}
                lengthAngle={360}
                paddingAngle={0}
                radius={35}
                animationDuration={1000}
                animate={true}
                style={{ height: '120px' }}
                viewBoxSize={[70, 70]}
                startAngle={0}
                segmentsShift={(x) => x == selectedIndex}
            />
            <div
                style={{
                    fontSize: '0.9rem',
                    height: "80px",
                    width: "80px",
                    borderRadius: "50%",
                    position: "absolute",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    top: "21px",
                    left: "35px",
                    color: "black",
                    fontWeight: "700",
                    // fontSize: "0.9rem",
                    boxShadow: "0px 0px 5px black",
                    backgroundColor: "white",
                    zIndex: 10,
                }}
            >
                <div
                    style={
                        {
                            fontSize: '0.8rem',
                            height: "65px",
                            width: "65px",
                            borderRadius: "50%",
                            position: "relative",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",

                            color: "black",
                            fontWeight: "700",
                            // fontSize: "0.9rem",

                            backgroundColor: "white",
                            zIndex: 15,
                        }
                    }
                >
                    {time ? time
                        : "00:00 h"}
                </div>
            </div>
        </div>
    );
};